import React, { createContext } from 'react'
import app from 'firebase/app'
import PropTypes from 'prop-types'

const FirebaseContext = createContext(null)
const FirebaseProvider = ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    })
  }

  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
}

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FirebaseProvider

export { FirebaseContext }
