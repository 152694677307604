import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, SwipeableDrawer, useMediaQuery } from '@material-ui/core'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import { makeStyles } from '@material-ui/core/styles'
import { isNull } from 'util'
import TrainerMapDisplay from '../../../TrainerProfile/components/TrainerMapDisplay/TrainerMapDisplay'
import AvatarMarker from '../../../AvatarMarker/AvatarMarker'
import useAuth from '../../../../services/AuthProvider/useAuth'
import Loading from '../../../Loading/Loading'

const useStyles = makeStyles({
  drawerPaper: {
    borderTop: '0 solid transparent',
    backgroundColor: 'transparent',
  },
})
let googleMap = null
const gmapLibraries = ['places']
const SearchResults = props => {
  const classes = useStyles()
  const { latitude, longitude, trainers } = props
  const auth = useAuth()
  const [loadedTrainer, setLoadedTrainer] = useState(null)
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ lat: parseFloat(latitude), lng: parseFloat(longitude) })
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: gmapLibraries,
  })
  const setNewCenter = () => {
    if (!isNull(googleMap)) {
      const mapCenter = googleMap.getCenter()

      setPosition({ lat: mapCenter.lat(), lng: mapCenter.lng() })
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!isNull(loadedTrainer)) {
      setOpen(true)
    }
  }, [loadedTrainer])

  return (
    <Box width={1} flexGrow={1}>
      {isLoaded ? (
        <>
          <GoogleMap
            onLoad={map => {
              const bounds = new window.google.maps.LatLngBounds()

              trainers.forEach(item => {
                bounds.extend({ lat: item.latitude, lng: item.longitude })
              })
              bounds.extend({ lat: latitude, lng: longitude })

              map.fitBounds(bounds)
              googleMap = map
            }}
            id="gmap-search-results"
            mapContainerStyle={{
              width: '100%',
              height: '100%',
            }}
            clickableIcons={false}
            zoom={12}
            options={{
              disableDefaultUI: !isDesktop,
              styles: [
                { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.school', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
                { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
              ],
              center: position,
            }}
            onDragEnd={() => {
              setNewCenter()
            }}
            onZoomChanged={() => {
              setNewCenter()
            }}
            onResize={() => {
              setNewCenter()
            }}
          >
            <AvatarMarker
              user={auth.userData}
              position={{ latitude: parseFloat(latitude), longitude: parseFloat(longitude) }}
              color="primary"
            />
            {trainers.map(trainer => (
              <AvatarMarker
                key={trainer.id}
                user={trainer}
                color="secondary"
                index={trainer.id}
                onClick={() => {
                  setLoadedTrainer(trainer)
                }}
              />
            ))}
          </GoogleMap>
          <SwipeableDrawer
            elevation={0}
            PaperProps={{ classes: { root: classes.drawerPaper } }}
            anchor="bottom"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            {!isNull(loadedTrainer) && (
              <TrainerMapDisplay
                trainer={loadedTrainer}
                searchLat={parseFloat(latitude)}
                searchLng={parseFloat(longitude)}
              />
            )}
          </SwipeableDrawer>
        </>
      ) : (
        <Loading height={1} bgColor="background.paper" />
      )}
    </Box>
  )
}

SearchResults.propTypes = {
  trainers: PropTypes.array.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
}

export default SearchResults
