import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Container, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { Alert } from '@material-ui/lab'
import { useLoadScript } from '@react-google-maps/api'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import PersonalForm from '../SignUp/components/PersonalForm/PersonalForm'
import geocodeAddress from '../../services/geocodeAddress/geocodeAddress'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))
const MyDetails = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [message, setMessage] = useState(false)
  const [initialValues, setInitialValues] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    stateShort: '',
    postcode: '',
    country: '',
    latitude: 0,
    longitude: 0,
  })
  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^[a-zA-Z\s'-]+$/, 'First Name is invalid')
      .required('First Name is required'),
    lastname: Yup.string()
      .matches(/^[a-zA-Z\s'-]+$/, 'Last Name is invalid')
      .required('Last Name is required'),
    phone: Yup.string().required('Mobile Phone is required'),
    address1: Yup.string().required('Address 1 is required'),
    suburb: Yup.string().required('Suburb is required'),
    state: Yup.string().required('State is required'),
    postcode: Yup.string().required('Postcode is required'),
    country: Yup.string().required('Country is required'),
  })
  const mapsScript = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  useEffect(() => {
    if (auth.userData.firstname !== '') {
      setInitialValues(_.merge(initialValues, auth.userData))
    }
  }, [initialValues, auth.userData])

  return (
    <>
      <Header title="My Details" showBack />
      <Box bgcolor="primary.dark" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Update your details as required.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box pt={1} pb={4}>
          {initialValues.firstname !== '' && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async data => {
                let coords = {}

                if (mapsScript.isLoaded) {
                  coords = await geocodeAddress(
                    `${data.address1}+${data.address2}+${data.suburb}+${data.stateShort}+${data.country}`,
                  )
                }

                auth
                  .updateUser({ ...data, ...coords })
                  .then(() => {
                    setMessage({ type: 'success', message: 'Successfully updated your details.' })
                    window.scrollTo(0, 0)
                  })
                  .catch(error => {
                    setMessage({ type: 'error', message: error.message })
                    window.scrollTo(0, 0)
                  })
              }}
            >
              {formik => (
                <Form>
                  {message.type && (
                    <Alert severity={message.type} className={classes.alert}>
                      {message.message}
                    </Alert>
                  )}
                  <PersonalForm {...formik} />
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </>
  )
}

export default MyDetails
