import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Container, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import Cards from 'react-credit-cards'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import PaymentForm from '../SignUp/components/PaymentForm/PaymentForm'
import creditCardSchema from '../../services/creditCardValidation/creditCardValidation'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))
const MyPaymentMethod = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [message, setMessage] = useState(false)
  const [card, setCard] = useState(false)
  const initialValues = {
    cardId: '',
    issuer: '',
    creditCardNumber: '',
    creditCardName: '',
    creditCardExpiry: '',
    creditCardCCV: '',
  }

  useEffect(() => {
    auth.getUserCreditCard().then(result => {
      setCard(result)
      if (result.id) {
        initialValues.cardId = result.id
      }
    })
  }, [auth, initialValues])

  return (
    <>
      <Header title="Payment Method" showBack />
      <Box bgcolor="primary.dark" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Update your payment information.{' '}
            {auth.userData && auth.userData.accountType === 'user'
              ? 'This credit card will be charged whenever you make a booking.'
              : 'This credit card will be used for the billing of your subscription.'}
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box pt={1} py={4}>
          {card && (
            <>
              <Typography variant="h3" color="secondary" gutterBottom>
                Current Card
              </Typography>
              <Box py={4}>
                <Cards expiry={card.expiry} name={card.name} number={card.pan} cvc={0} />
              </Box>
            </>
          )}
          <Typography variant="h3" color="secondary" gutterBottom>
            Add New Card
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Entering details here will override your saved Credit Card.
          </Typography>
        </Box>
        <Box py={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={creditCardSchema}
            onSubmit={data => {
              auth
                .addCreditCard(data)
                .then(result => {
                  setCard(result)
                  setMessage({ type: 'success', message: 'Successfully updated your Payment information.' })
                })
                .catch(error => {
                  setMessage({ type: 'error', message: error.message })
                })
            }}
          >
            {formik => (
              <Form>
                {message.type && (
                  <Alert severity={message.type} className={classes.alert}>
                    {message.message}
                  </Alert>
                )}
                <PaymentForm {...formik} />
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  )
}

export default MyPaymentMethod
