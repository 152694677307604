import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials, formatFullName } from '../../services/formatters/formatters'
import { formatCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import useAuth from '../../services/AuthProvider/useAuth'
import bookingStatusColor from '../../services/bookingStatusColor/bookingStatusColor'
import * as ROUTES from '../../constants/routes'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(4),
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  statusChip: {
    textTransform: 'capitalize',
  },
}))
const SessionAttendees = ({ attendees }) => {
  const classes = useStyles()
  const auth = useAuth()

  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom paragraph>
        Session Attendees
      </Typography>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key="attendee" align="left">
                  Attendee
                </TableCell>
                <TableCell key="status" align="center">
                  Status
                </TableCell>
                <TableCell key="cost" align="center">
                  Cost
                </TableCell>
                <TableCell key="booked" align="center">
                  Booked When
                </TableCell>
                <TableCell key="actions" align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendees.map(item => (
                <TableRow key={item.bookingId} hover>
                  <TableCell key="attendee" align="left">
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                      <Avatar src={item.user.profileImg} className={classes.avatar}>
                        {formatInitials(item.user)}
                      </Avatar>
                      {formatFullName(item.user)}
                    </Box>
                  </TableCell>
                  <TableCell key="status" align="center">
                    <Chip
                      label={item.status}
                      size="small"
                      color={bookingStatusColor(item.status)}
                      className={classes.statusChip}
                    />
                  </TableCell>
                  <TableCell key="cost" align="center">
                    {formatCurrency(item.cost, auth.business.country, true)}
                  </TableCell>
                  <TableCell key="booked" align="center">
                    {format(item.booked.toDate(), 'dd/MM/yyyy @ hh:mm a')}
                  </TableCell>
                  <TableCell key="actions" align="center">
                    <IconButton href={`${ROUTES.BOOKING_VIEW}/${item.bookingId}`}>
                      <MaterialIcon icon="pencil" size={3} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {attendees.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    No bookings yet.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

SessionAttendees.propTypes = {
  attendees: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SessionAttendees
