import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Container } from '@material-ui/core'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import TrainerTagline from '../TrainerProfile/components/TrainerTagline/TrainerTagline'
import { formatInitials, formatFullName } from '../../services/formatters/formatters'
import randomImage from '../../services/randomImage/randomImage'
import Header from '../Header/Header'
import useAuth from '../../services/AuthProvider/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0)',
    marginTop: -theme.spacing(8),
    border: `6px solid ${theme.palette.background.default}`,
  },
}))
const ProfileHeader = ({ user, type }) => {
  const classes = useStyles()
  const { profileImg } = user
  const initials = formatInitials(user)
  const fullname = formatFullName(user)
  const headerImage = user.headerImage || randomImage()
  const profileLabel = `${type === 'trainer' ? 'Trainer' : 'User'} Profile`
  const auth = useAuth()

  return (
    <>
      <Header title={profileLabel} showBack />
      <div className={classes.root}>
        <ResponsiveImage src={headerImage} height={200} />
        <Avatar alt={fullname} src={profileImg} className={classes.avatar}>
          {initials}
        </Avatar>
        <Container maxWidth="md">
          {type === 'trainer' && (
            <TrainerTagline trainer={user} searchLat={auth.userData.latitude} searchLng={auth.userData.longitude} />
          )}
        </Container>
      </div>
    </>
  )
}

ProfileHeader.propTypes = {
  user: PropTypes.shape({
    profileImg: PropTypes.string,
    headerImage: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
}

ProfileHeader.defaultProps = {
  type: 'trainer',
}

export default ProfileHeader
