import React, { useState, useEffect } from 'react'
import {
  Container,
  Box,
  Typography,
  Button,
  Checkbox,
  Paper,
  Divider,
  Grid,
  Avatar,
  Link,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { Formik, Form, FastField } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import MiniCard from '../MiniCard/MiniCard'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials, formatAddress } from '../../services/formatters/formatters'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import BookingReceipt from '../BookingReceipt/BookingReceipt'
import PaymentForm from '../SignUp/components/PaymentForm/PaymentForm'
import { formatCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import * as ROUTES from '../../constants/routes'
import creditCardSchema from '../../services/creditCardValidation/creditCardValidation'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3),
  },
  paperNote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  row: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
  },
  rowTop: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'flex-start',
  },
  avatar: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
  },
  address: {
    whiteSpace: 'pre',
  },
  termsCheckbox: {
    display: 'inline',
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginRight: 0,
  },
  cost: {
    fontSize: '1.5rem',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}))
const BookingConfirmation = () => {
  const classes = useStyles()
  const { sessionId } = useParams()
  const auth = useAuth()
  const db = useDatabase()
  const [session, setSession] = useState(false)
  const [trainer, setTrainer] = useState(false)
  const [booking, setBooking] = useState(false)
  const [sessionError, setSessionError] = useState(false)
  const [card, setCard] = useState(false)
  const initialValues = {
    terms: false,
    useExistingCreditCard: true,
    issuer: '',
    creditCardNumber: '',
    creditCardName: '',
    creditCardExpiry: '',
    creditCardCCV: '',
  }
  const initialValidation = Yup.object().shape({
    useExistingCreditCard: Yup.bool().required(),
    terms: Yup.bool()
      .oneOf([true], 'You are required to agree to the Terms and Conditions to make a Booking')
      .required(),
  })
  const [validationSchema, setValidationSchema] = useState(initialValidation)

  useEffect(() => {
    db.getSession(sessionId).then(result => {
      setSession(result)
      db.getTrainer(result.trainerId).then(data => {
        setTrainer(data)
      })
      if (result.spaces <= result.bookings) {
        setSessionError('This session has been booked out.')
      }

      if (result.finish.toDate() < new Date()) {
        setSessionError('This session is in the past and cannot be booked.')
      }
    })
    auth.getUserCreditCard().then(result => {
      setCard(result)
      if (result.issuer) {
        setCard(result)
      }
    })
    db.getUserSessionBooking(auth.user.uid, sessionId).then(bookingData => {
      if (bookingData) {
        setBooking(bookingData)
      }
    })
  }, [auth, db, sessionId])

  return (
    session && (
      <>
        <Header title="Confirm Booking" showBack />
        <Box bgcolor="background.paper" py={3}>
          <Container maxWidth="sm">
            <Typography variant="body1" color="textPrimary">
              {booking
                ? 'This is your receipt for the booking. A copy has been emailed to you.'
                : 'Please confirm all details are correct.'}
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="sm">
          {!sessionError ? (
            <>
              {booking && trainer ? (
                <Box py={2}>
                  <BookingReceipt start={session.start} booking={booking} trainer={trainer} />
                </Box>
              ) : (
                <Box py={2}>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Session Details
                  </Typography>

                  <Paper className={classes.paper}>
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Session
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {session.title}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Exercise Type
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                          <MaterialIcon
                            icon={getExerciseType(session.exerciseType, 'icon')}
                            color="secondary"
                            size={3}
                          />
                          <Typography variant="body2" display="inline">
                            &nbsp;{getExerciseType(session.exerciseType, 'label')}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Start Time
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {format(session.start.toDate(), 'd LLLL yyyy @ hh:mm a')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Finish Time
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {format(session.finish.toDate(), 'd LLLL yyyy @ hh:mm a')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Address
                        </Typography>
                      </Grid>
                      <Grid item xs className={classes.alignRight}>
                        <Typography variant="body2" align="right" className={classes.address}>
                          {formatAddress(trainer, false)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Trainer
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                          <Avatar src={trainer.profileImg} className={classes.avatar}>
                            {formatInitials(trainer)}
                          </Avatar>
                          <Typography variant="body2" display="inline">
                            {trainer.firstname} {trainer.lastname}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          Trainer&apos; Business Name
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {trainer.tradingName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className={classes.row}>
                      <Grid item xs>
                        <Typography variant="body1" color="primary">
                          {trainer.country === 'Austraila' ? 'ABN/ACN' : 'Company Number'}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" align="right">
                          {trainer.companyNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Typography variant="h3" color="secondary" gutterBottom>
                    Payment Information
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={data => {
                      db.addBooking({
                        sessionId,
                        userId: auth.user.uid,
                        trainerId: trainer.id,
                        cardToken: card.token,
                        issuer: card.issuer,
                        panDigits: card.pan.substr(-4, 4),
                        cost: session.cost,
                        status: 'pending',
                        booked: new Date(),
                        updatedWhen: new Date(),
                      }).then(bookingData => {
                        setBooking(bookingData)
                      })
                    }}
                  >
                    {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => (
                      <Form>
                        <Paper className={classes.paper}>
                          <Grid container className={classes.row}>
                            <Grid item xs>
                              <Typography variant="body1" color="primary">
                                Cost (inc Tax)
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Typography variant="body1" align="right" color="primary" className={classes.cost}>
                                <strong>{formatCurrency(session.cost, trainer.country, true)}</strong>
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                          <Grid container className={classes.rowTop}>
                            <Grid item xs>
                              <Typography variant="body1" color="primary" gutterBottom>
                                Payment Method
                              </Typography>
                            </Grid>
                            <Grid item xs>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                flexDirection="column"
                              >
                                {card && (
                                  <>
                                    {values.useExistingCreditCard && (
                                      <Box display="flex" justifyContent="flex-end" flexDirection="row">
                                        <MiniCard issuer={card.issuer} />
                                        <Typography variant="body2">
                                          &nbsp;&nbsp;(ending in {card.pan.substr(-4, 4)})
                                        </Typography>
                                      </Box>
                                    )}
                                    <FormControl>
                                      <FormControlLabel
                                        id="useExistingCreditCard"
                                        name="useExistingCreditCard"
                                        control={
                                          <Checkbox
                                            color="secondary"
                                            onChange={event => {
                                              handleChange(event)
                                              if (event.target.value === '') {
                                                setValidationSchema(initialValidation.concat(creditCardSchema))
                                              } else {
                                                setValidationSchema(initialValidation)
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            checked={values.useExistingCreditCard}
                                          />
                                        }
                                        label="Use existing Credit Card"
                                        labelPlacement="end"
                                        className={classes.formControlLabel}
                                      />
                                    </FormControl>
                                  </>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                          {(!values.useExistingCreditCard || !card) && (
                            <Grid container className={classes.rowTop}>
                              <Grid item xs>
                                <Typography variant="body2">
                                  Please note: This will replace your saved credit card.
                                </Typography>
                                <PaymentForm
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  setFieldValue={setFieldValue}
                                  hideButton
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Paper>
                        <Grid container spacing={2}>
                          <Grid item xs={2} sm={1}>
                            <FastField
                              id="terms"
                              name="terms"
                              color="secondary"
                              as={Checkbox}
                              error={touched.terms && errors.terms}
                            />
                          </Grid>
                          <Grid item xs sm>
                            <Typography variant="body2" display="inline" gutterBottom>
                              I accept the Trainer&apos;s{' '}
                              <Link href={trainer.terms} target="_blank" underline="always">
                                Terms and Conditions
                              </Link>
                              , the Trainer&apos;s{' '}
                              <Link href={trainer.insurance} target="_blank" underline="always">
                                Insurancy Policy
                              </Link>{' '}
                              and the {process.env.REACT_APP_APP_NAME}{' '}
                              <Link href="/terms-conditions" target="_blank" underline="always">
                                Terms and Conditions
                              </Link>
                              .
                            </Typography>
                            {touched.terms && errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
                          </Grid>
                        </Grid>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          size="large"
                          fullWidth
                          className={classes.submit}
                        >
                          Confirm Booking
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Box>
              )}
            </>
          ) : (
            <Alert severity="error" className={classes.paperNote}>
              {sessionError}
            </Alert>
          )}
          <Button variant="contained" href={ROUTES.HOME} size="large" fullWidth className={classes.button}>
            Back to Dashboard
          </Button>
        </Container>
      </>
    )
  )
}

export default BookingConfirmation
