import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, TextField, InputAdornment, IconButton, Toolbar, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { isNull } from 'util'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { add, sub, format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import ExerciseTypeBar from '../ExerciseTypeBar/ExerciseTypeBar'
import ProfileHeader from '../ProfileHeader/ProfileHeader'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import UserClassTile from '../ClassTile/UserClassTile'
import Loading from '../Loading/Loading'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  dateField: {
    marginBottom: theme.spacing(3),
  },
  textFieldContent: {
    textAlign: 'center',
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  tile: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  datepickerTitle: {
    marginBottom: theme.spacing(2),
  },
  year: {
    fontFamily: robotoFontStack,
  },
  datepickerHeading: {
    fontFamily: robotoFontStack,
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  iconButton: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  },
  iconButtonCal: {
    marginRight: theme.spacing(1),
  },
  textField: {
    borderRadius: theme.shape.borderRadius,
  },
}))
const TrainerProfile = () => {
  const classes = useStyles()
  const { id } = useParams('id')
  const db = useDatabase()
  const [trainer, setTrainer] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [sessions, setSessions] = useState(false)

  useEffect(() => {
    db.getTrainer(id).then(profile => {
      setTrainer(profile)

      db.getSessionsByDate(selectedDate, id).then(docs => {
        setSessions(
          docs.map(doc => {
            return {
              sessionId: doc.id,
              ...doc.data(),
            }
          }),
        )
      })
    })
  }, [selectedDate, id, db])

  return (
    <>
      {!isNull(trainer) ? (
        <>
          <ProfileHeader user={trainer} type="trainer" />
          <Container maxWidth="md">
            <Box py={1}>
              <Typography variant="h3" color="textSecondary" gutterBottom>
                Bio
              </Typography>
              <Typography variant="body1" color="textPrimary" paragraph>
                {trainer.bio}
              </Typography>
            </Box>
          </Container>
          <Box py={3} bgcolor="background.paper">
            <Container maxWidth="md">
              <ExerciseTypeBar types={trainer.exerciseTypes} />
            </Container>
          </Box>
          <Container maxWidth="md">
            <Box py={2}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Sessions Available
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  id="selectedDate"
                  value={selectedDate}
                  onChange={date => {
                    setSelectedDate(date)
                  }}
                  format="E, d LLL yyyy"
                  autoOk
                  disablePast
                  className={classes.dateField}
                  TextFieldComponent={props => {
                    // eslint-disable-next-line react/prop-types
                    const { onClick } = props

                    return (
                      <TextField
                        {...props}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        color="primary"
                        onClick={e => {
                          if (e.target.id === 'selectedDate') {
                            onClick()
                          }
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          className: classes.textField,
                          startAdornment: (
                            <InputAdornment position="start" style={{ marginTop: 0 }}>
                              <IconButton
                                onClick={() => {
                                  const newDate = sub(selectedDate, { days: 1 })

                                  if (newDate < new Date()) {
                                    setSelectedDate(new Date())
                                  } else {
                                    setSelectedDate(newDate)
                                  }
                                }}
                                className={classes.iconButton}
                              >
                                <MaterialIcon icon="arrow-left" size={3} color="primary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  onClick()
                                }}
                                className={classes.iconButtonCal}
                              >
                                <MaterialIcon icon="calendar" size={3} color="primary" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSelectedDate(add(selectedDate, { days: 1 }))
                                }}
                                className={classes.iconButton}
                              >
                                <MaterialIcon icon="arrow-right" size={3} color="primary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          className: classes.textFieldContent,
                        }}
                      />
                    )
                  }}
                  ToolbarComponent={data => {
                    const { date } = data

                    return (
                      <>
                        <Toolbar className={classes.toolbar}>
                          <Typography color="secondary" variant="body2" className={classes.datepickerTitle}>
                            Show Sessions for Date
                          </Typography>
                          <Typography color="textPrimary" variant="h4" className={classes.year}>
                            {format(date, 'yyyy')}
                          </Typography>
                          <Typography variant="h3" className={classes.datepickerHeading}>
                            {format(date, 'iii, do LLL')}
                          </Typography>
                        </Toolbar>
                      </>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
              {sessions.length > 0 ? (
                sessions.map(session => (
                  <UserClassTile key={session.id} {...session} trainer={trainer} className={classes.tile} showChips />
                ))
              ) : (
                <Paper className={classes.paper}>There are no sessions scheduled for this date</Paper>
              )}
            </Box>
          </Container>
        </>
      ) : (
        <Loading height={225} />
      )}
    </>
  )
}

export default TrainerProfile
