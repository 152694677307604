import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardContent, Typography, CardMedia, Link, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatDate } from '../../services/formatters/formatters'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import * as ROUTES from '../../constants/routes'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  name: {
    verticalAlign: 'top',
    display: 'inline',
    lineHeight: `${theme.spacing(3)}px`,
  },
  date: {
    marginTop: theme.spacing(0.5),
  },
  categoryIcon: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
  },
  address: {
    whiteSpace: 'pre',
  },
  media: {
    height: 140,
  },
  avatar: {
    position: 'absolute',
    top: '100%',
    right: theme.spacing(1.5),
    transform: 'translateY(-50%)',
    zIndex: 2,
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `4px solid ${theme.palette.background.paper}`,
  },
  gridItem: {
    textAlign: 'center',
  },
  number: {
    fontSize: '1.5rem',
    fontFamily: robotoFontStack,
    fontWeight: 600,
  },
  numberLabel: {
    fontSize: '0.8rem',
  },
}))
const TrainerClassTile = props => {
  const classes = useStyles()
  const { sessionId, title, start, spaces, exerciseType, className, headerImage, bookings } = props
  const formattedDate = formatDate(start.toDate())
  const icon = getExerciseType(exerciseType, 'icon')

  return (
    <Link component={RouterLink} to={`${ROUTES.SESSION}/${sessionId}`} underline="none">
      <Card className={className}>
        <CardActionArea>
          <div style={{ position: 'relative' }}>
            <CardMedia title={exerciseType} image={headerImage} className={classes.media} />
          </div>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs>
                <MaterialIcon icon={icon} color="secondary" size={3} className={classes.categoryIcon} />
                <Typography variant="h6" color="primary" className={classes.name} gutterBottom>
                  {title}
                </Typography>
                <Typography variant="body2" color="secondary" className={classes.date}>
                  {formattedDate}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem}>
                <Typography variant="h4" color="secondary" className={classes.number}>
                  {bookings}/{spaces}
                </Typography>
                <Typography variant="subtitle1" component="span" color="textPrimary" className={classes.numberLabel}>
                  Bookings
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

TrainerClassTile.propTypes = {
  sessionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.any.isRequired,
  spaces: PropTypes.number.isRequired,
  exerciseType: PropTypes.string.isRequired,
  headerImage: PropTypes.string.isRequired,
  className: PropTypes.any,
  bookings: PropTypes.number.isRequired,
}
TrainerClassTile.defaultProps = {
  className: '',
}

export default TrainerClassTile
