import 'firebase/firebase-firestore'
import 'firebase/firebase-storage'
import { differenceInMinutes, startOfDay, endOfDay, add, format } from 'date-fns'
import useFirebase from '../useFirebase/useFirebase'
import calculateDistance from '../calculateDistance/calculateDistance'
import useAuth from '../AuthProvider/useAuth'

const useDbProvider = () => {
  const firebase = useFirebase()
  const db = firebase.firestore()
  const auth = useAuth()
  const getTrainer = async id => {
    if (!id) {
      return false
    }

    let trainer = {}

    await db
      .collection('users')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.data()

        trainer = {
          ...trainer,
          id: doc.id,
          firstname: data.firstname,
          lastname: data.lastname,
          profileImg: data.profileImg,
        }
      })
    await db
      .collection('trainers')
      .doc(id)
      .get()
      .then(doc => {
        trainer = {
          ...trainer,
          ...doc.data(),
        }
      })

    return trainer
  }

  const searchTrainers = filters => {
    return db
      .collection('sessions')
      .where('exerciseType', '==', filters.exerciseType)
      .where('start', '>', new Date())
      .get()
      .then(snap => {
        const trainerIds = snap.docs
          .filter(item => {
            const session = item.data()
            let hasSessionType = true
            let hasSessionLength = true
            let hasAccessible = true
            const safeSessionType = Array.isArray(filters.sessionType) ? filters.sessionType : [filters.sessionType]
            const safeSessionLength = Array.isArray(filters.sessionLength)
              ? filters.sessionLength
              : [filters.sessionLength]

            if (filters.sessionType && safeSessionType.indexOf(session.sessionType) === -1) {
              hasSessionType = false
            }

            if (filters.sessionLength && safeSessionLength.indexOf(session.sessionLength) === -1) {
              hasSessionLength = false
            }

            if (filters.accessible && !session.accessible) {
              hasAccessible = false
            }

            return hasSessionType && hasSessionLength && hasAccessible
          })
          .map(item => {
            const session = item.data()

            return session.trainerId
          })
        const safeTrainerIds = Array.isArray(trainerIds) ? trainerIds : [trainerIds]

        if (trainerIds.length > 0) {
          return db
            .collection('trainers')
            .get()
            .then(results => {
              return results.docs
                .filter(item => {
                  const trainer = item.data()
                  const distance = calculateDistance(
                    filters.latitude,
                    filters.longitude,
                    trainer.latitude,
                    trainer.longitude,
                    auth.userData.settings.distanceUnits,
                  )

                  return distance < filters.distance && safeTrainerIds.indexOf(item.id) > -1
                })
                .map(doc => {
                  const trainer = doc.data()

                  return {
                    id: doc.id,
                    firstname: trainer.firstname,
                    lastname: trainer.lastname,
                    profileImg: trainer.profileImg,
                    suburb: trainer.suburb,
                    stateShort: trainer.stateShort,
                    latitude: trainer.latitude,
                    longitude: trainer.longitude,
                    exerciseTypes: trainer.exerciseTypes,
                  }
                })
            })
        }

        return false
      })
  }

  const addSession = (trainerId, data) => {
    const trainerRef = db.collection('trainers').doc(trainerId)
    let sessionType = '1on1'

    if (data.spaces > 1 && data.spaces <= 5) {
      sessionType = 'semiprivate'
    } else if (data.spaces > 5) {
      sessionType = 'group'
    }

    const sessions = []
    const formattedSession = {
      trainerId,
      title: data.title,
      start: data.start,
      finish: data.finish,
      exerciseType: data.exerciseType,
      sessionType,
      sessionLength: differenceInMinutes(data.finish, data.start),
      headerImage: data.headerImage,
      description: data.description,
      spaces: data.spaces,
      cost: data.cost,
      bookings: 0,
      accessible: data.accessible,
    }

    if (data.recurring) {
      const sessionLength = differenceInMinutes(data.finish, data.start)
      let { start, finish } = data
      const offset = data.recurringFrequency === 'weekly' ? 7 : 1

      while (finish < endOfDay(data.recurringUntil)) {
        let flag = false

        if (data.recurringFrequency === 'weekly') {
          flag = true
        } else if (data.recurringDays.indexOf(format(start, 'E')) > -1) {
          flag = true
        }

        if (flag) {
          sessions.push({
            ...formattedSession,
            start,
            finish,
          })
        }

        start = add(start, { days: offset })
        finish = add(start, { minutes: sessionLength })
      }
    } else {
      sessions.push(formattedSession)
    }

    return db
      .collection('sessions')
      .where('trainerId', '==', trainerId)
      .get()
      .then(snap => {
        const batch = db.batch()
        let sessionDates = []
        let runningRef

        if (snap.docs.length > 0) {
          sessionDates = snap.docs.map(item => ({
            start: item.data().start.toDate(),
            finish: item.data().finish.toDate(),
          }))
        }

        sessions.forEach(session => {
          let flag = true

          sessionDates.forEach(item => {
            if (session.start < item.finish && session.finish > item.start) {
              flag = false
            }
          })

          if (flag) {
            runningRef = db.collection('sessions').doc()
            batch.set(runningRef, session)
          }
        })

        return batch.commit().then(() => {
          db.runTransaction(transaction => {
            return transaction.get(trainerRef).then(trainer => {
              const newExerciseTypes = trainer.data().exerciseTypes || []

              if (!newExerciseTypes.includes(data.exerciseType)) {
                newExerciseTypes.push(data.exerciseType)
              }

              transaction.update(trainerRef, {
                exerciseTypes: newExerciseTypes,
              })
            })
          })
          if (sessions.length === 1) {
            return runningRef.id
          }

          return true
        })
      })
  }

  const getSessionsByDate = (date, trainerId = auth.user.uid) =>
    db
      .collection('sessions')
      .where('trainerId', '==', trainerId)
      .where('start', '>=', startOfDay(date))
      .where('start', '<=', endOfDay(date))
      .orderBy('start', 'asc')
      .get()
      .then(snap => {
        return snap.docs
      })
  const getSession = id =>
    db
      .collection('sessions')
      .doc(id)
      .get()
      .then(doc => doc.data())
  const deleteSession = id =>
    db
      .collection('sessions')
      .doc(id)
      .delete()
      .then(() => {
        return true
      })
  const getTrainersByIds = trainerIds =>
    db
      .collection('trainers')
      .where(firebase.firestore.FieldPath.documentId(), 'in', trainerIds)
      .get()
      .then(snap => {
        return snap.docs.map(doc => {
          const { firstname, lastname, profileImg, suburb, stateShort } = doc.data()

          return {
            id: doc.id,
            firstname,
            lastname,
            profileImg,
            suburb,
            stateShort,
          }
        })
      })
  const getUserBookings = () =>
    db
      .collection('bookings')
      .where('userId', '==', auth.user.uid)
      .get()
      .then(snap => {
        const sessionIds = snap.docs.map(doc => {
          return doc.data().sessionId
        })

        if (sessionIds.length > 0) {
          return db
            .collection('sessions')
            .where(firebase.firestore.FieldPath.documentId(), 'in', sessionIds)
            .get()
            .then(results => {
              return results.docs.map(result => {
                return {
                  sessionId: result.id,
                  ...result.data(),
                }
              })
            })
        }

        return false
      })
  const addBooking = data => {
    const bookingRef = db.collection('bookings').doc()
    const sessionRef = db.collection('sessions').doc(data.sessionId)

    return bookingRef.set(data).then(() => {
      db.runTransaction(transaction => {
        return transaction.get(sessionRef).then(sessionDoc => {
          if (!sessionDoc.exists) {
            throw new Error('Session does not exist')
          }

          const newBookings = sessionDoc.data().bookings + 1

          transaction.update(sessionRef, { bookings: newBookings })
        })
      })

      return {
        bookingId: bookingRef.id,
        ...data,
        updatedWhen: firebase.firestore.Timestamp(new Date()),
      }
    })
  }

  const getUserSessionBooking = (uid, sessionId) =>
    db
      .collection('bookings')
      .where('userId', '==', uid)
      .where('sessionId', '==', sessionId)
      .get()
      .then(snap => {
        if (snap.docs.length === 0) {
          return false
        }

        return {
          bookingId: snap.docs[0].id,
          ...snap.docs[0].data(),
        }
      })
  const getBooking = bookingId =>
    db
      .collection('bookings')
      .doc(bookingId)
      .get()
      .then(doc => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })
  const getSessionBookings = sessionId =>
    db
      .collection('bookings')
      .where('sessionId', '==', sessionId)
      .get()
      .then(snap => {
        return snap.docs.map(doc => {
          return {
            bookingId: doc.id,
            ...doc.data(),
          }
        })
      })
  const getUsersByIds = userIds =>
    db
      .collection('users')
      .where(firebase.firestore.FieldPath.documentId(), 'in', userIds)
      .get()
      .then(snap => {
        return snap.docs.map(doc => {
          return {
            userId: doc.id,
            ...doc.data(),
          }
        })
      })
  const cancelBooking = (bookingId, sessionId) => {
    // run refund
    const bookingRef = db.collection('bookings').doc(bookingId)
    const sessionRef = db.collection('sessions').doc(sessionId)
    const now = new Date()

    return bookingRef
      .update({
        status: 'cancelled',
        updatedWhen: now,
      })
      .then(() => {
        db.runTransaction(transaction => {
          return transaction.get(sessionRef).then(doc => {
            const newBookings = doc.data().bookings - 1

            transaction.update(sessionRef, { bookings: newBookings })
          })
        })

        return {
          type: 'success',
          message: 'The booking has been cancelled and money refunded.',
          bookingUpdates: {
            status: 'cancelled',
            updatedWhen: firebase.firestore.Timestamp.fromDate(now),
          },
        }
      })
  }

  const reactivateBooking = (bookingId, sessionId) => {
    // run charge
    const bookingRef = db.collection('bookings').doc(bookingId)
    const sessionRef = db.collection('sessions').doc(sessionId)
    const now = new Date()

    return db.runTransaction(transaction => {
      return transaction.get(sessionRef).then(doc => {
        const sessionData = doc.data()

        if (sessionData.spaces - sessionData.bookings > 0) {
          const newBookings = sessionData.bookings + 1

          transaction.update(sessionRef, { bookings: newBookings })

          return bookingRef
            .update({
              status: 'paid',
              updatedWhen: now,
            })
            .then(() => {
              return {
                type: 'success',
                message: "The booking has been reactivated. The attendee's credit card has been charged.",
                bookingUpdates: {
                  status: 'paid',
                  updatedWhen: firebase.firestore.Timestamp.fromDate(now),
                },
              }
            })
        }

        return {
          type: 'error',
          message: 'There are no available spaces left for this session.',
        }
      })
    })
  }

  const cancelSession = sessionId =>
    db
      .collection('sessions')
      .doc(sessionId)
      .update({ status: 'cancelled' })
      .then(() => {
        return true
      })
  const getAllTrainerSessions = trainerId =>
    db
      .collection('sessions')
      .where('trainerId', '==', trainerId)
      .where('start', '>=', new Date())
      .get()
      .then(snap => {
        return snap.docs.map(doc => doc.data())
      })

  return {
    getTrainer,
    searchTrainers,
    addSession,
    getSession,
    deleteSession,
    getSessionsByDate,
    getAllTrainerSessions,
    getTrainersByIds,
    getUserBookings,
    addBooking,
    getUserSessionBooking,
    getSessionBookings,
    getUsersByIds,
    cancelBooking,
    cancelSession,
    getBooking,
    reactivateBooking,
  }
}

export default useDbProvider
