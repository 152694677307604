import PropTypes from 'prop-types'
import { format, isToday, isValid, isTomorrow, endOfToday, add } from 'date-fns'

const formatDate = (date, dateFormat = 'dynamic') => {
  if (!isValid(date)) {
    console.log(`Supplied Timestamp (${date}) is not valid`)

    return ''
  }

  let formattedDate

  if (dateFormat === 'standard') {
    formattedDate = format(date, 'cccc do MMM yyyy @ h:mma')
  } else if (dateFormat === 'dynamic') {
    formattedDate = format(date, 'cccc do MMM yyyy @ h:mma')
    if (isToday(date)) {
      formattedDate = `Today @ ${format(date, 'h:mma')}`
    }

    if (isTomorrow(date)) {
      formattedDate = `Tomorrow @ ${format(date, 'h:mma')}`
    }

    if (date > add(endOfToday(), { days: 1 }) && date < add(endOfToday(), { days: 7 })) {
      formattedDate = format(date, 'cccc @ h:mm a')
    }
  } else {
    formattedDate = format(date, dateFormat)
  }

  return formattedDate
}

formatDate.propTypes = {
  timestamp: PropTypes.number.isRequired,
  dateFormat: PropTypes.string,
}

export default formatDate
