import PropTypes from 'prop-types'

const formatAddress = (address, singleLine) => {
  const { address1, address2, suburb, state, postcode } = address

  if (singleLine) {
    return [[address1, address2, suburb, state].filter(Boolean).join(', '), postcode].filter(Boolean).join(' ')
  }

  return [address1, address2, `${suburb}, ${[state, postcode].filter(Boolean).join(' ')}`].filter(Boolean).join('\n')
}

formatAddress.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    suburb: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postcode: PropTypes.number.isRequired,
  }),
  singleLine: PropTypes.bool,
}
formatAddress.defaultProps = {
  singleLine: false,
}

export default formatAddress
