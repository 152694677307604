import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  Radio,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  MenuItem,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, FastField, Form } from 'formik'
import getSymbolFromCurrency from 'currency-symbol-map'
import PropTypes from 'prop-types'
import DateTimePickerField from '../DateTimePickerField/DateTimePickerField'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import ToggleTileItem from '../ToggleTileItem/ToggleTileItem'
import { EXERCISE_TYPES } from '../../constants/settings'
import { getCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import useAuth from '../../services/AuthProvider/useAuth'
import ImageSelectorField from '../ImageSelectorField/ImageSelectorField'

const useStyles = makeStyles(theme => ({
  radioRoot: {
    width: '100%',
    display: 'block',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  typeWrapper: {
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(2),
  },
  controlLabel: {
    position: 'relative',
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    '&:before': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      pointerEvents: 'none',
    },
    '&:after': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      transform: 'scaleX(0)',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      pointerEvents: 'none',
    },
    '&:hover:after': {
      transform: 'scaleX(1)',
    },
  },
  accessibilityLabel: {
    flexGrow: 1,
    display: 'inline-flex',
    alignItems: 'center',
  },
}))
const SessionForm = props => {
  const classes = useStyles()
  const { initialValues, validationSchema, onSubmit, submitButtonLabel, cancelled, lockedFields } = props
  const auth = useAuth()
  const [currency, setCurrency] = useState({
    name: '',
    symbol: '',
  })
  const [subset, setSubset] = useState(initialValues.exerciseType || '')
  const isLocked = fieldName => {
    if (typeof lockedFields[fieldName] === 'undefined') {
      return false
    }

    return lockedFields[fieldName]
  }

  useEffect(() => {
    if (auth.business) {
      const name = getCurrency(auth.business.country)
      const symbol = getSymbolFromCurrency(name)

      setCurrency({
        name,
        symbol,
      })
    }
  }, [auth.business])

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => (
        <Form>
          <FastField
            id="title"
            name="title"
            label="Session Title"
            color="primary"
            as={TextField}
            error={Boolean(errors.title && touched.title)}
            helperText={touched.title && errors.title}
            fullWidth
            margin="normal"
            disabled={isLocked('title')}
          />
          <DateTimePickerField
            id="start"
            name="start"
            label="Start Time"
            color="secondary"
            error={Boolean(errors.start)}
            helperText={touched.start && errors.start}
            fullWidth
            margin="normal"
            format="d LLLL yyyy @ h:mm a"
            minutesStep={5}
            disablePast
            onChange={value => {
              setFieldValue('start', value, true)
              setFieldValue('finish', value, true)
            }}
            disabled={isLocked('start')}
          />
          <DateTimePickerField
            id="finish"
            name="finish"
            label="Finish Time"
            color="secondary"
            error={Boolean(errors.finish)}
            helperText={touched.finish && errors.finish}
            fullWidth
            margin="normal"
            format="d LLLL yyyy @ h:mm a"
            minutesStep={5}
            disablePast
            openTo="hours"
            onChange={value => {
              setFieldValue('finish', value, true)
            }}
            disabled={isLocked('finish')}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="search-recurring-label" shrink disabled={isLocked('recurring')}>
              Recurring Dates
            </InputLabel>
            <FormControlLabel
              id="recurring"
              name="recurring"
              disabled={isLocked('recurring')}
              control={
                <Switch color="secondary" onChange={handleChange} onBlur={handleBlur} checked={values.recurring} />
              }
              label={
                <>
                  <InputAdornment position="start">
                    <MaterialIcon icon="repeat" color={isLocked('recurring') ? 'inherit' : 'secondary'} size={3} />
                  </InputAdornment>
                  <Typography variant="body1">Configure Recurring Session Dates</Typography>
                </>
              }
              labelPlacement="start"
              classes={{ labelPlacementStart: classes.controlLabel, label: classes.accessibilityLabel }}
            />
          </FormControl>
          {values.recurring && !isLocked('recurring') && (
            <>
              <FastField
                id="recurringFrequency"
                name="recurringFrequency"
                label="Recurring Frequency"
                color="primary"
                as={TextField}
                error={errors.recurringFrequency && touched.recurringFrequency}
                helperText={touched.recurringFrequency && errors.recurringFrequency}
                select
                margin="normal"
                fullWidth
              >
                <MenuItem key="daily" value="daily">
                  Daily
                </MenuItem>
                <MenuItem key="weekly" value="weekly">
                  Weekly
                </MenuItem>
              </FastField>
              {values.recurringFrequency === 'daily' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel shrink error={!!(touched.recurringDays && errors.recurringDays)}>
                    Recurring Days
                  </InputLabel>
                  <FastField id="recurringDays" as={FormGroup} className={classes.typeWrapper}>
                    <Grid container spacing={1}>
                      {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                        <Grid item xs key={day}>
                          <Checkbox
                            id={day}
                            name="recurringDays"
                            value={day}
                            icon={<ToggleTileItem variant="light" title={day} />}
                            checkedIcon={<ToggleTileItem variant="light" title={day} checked />}
                            classes={{ root: classes.radioRoot }}
                            disableRipple
                            checked={values.recurringDays.indexOf(day) > -1}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FastField>
                  {touched.recurringDays && errors.recurringDays && (
                    <FormHelperText error>{errors.recurringDays}</FormHelperText>
                  )}
                </FormControl>
              )}
              <DateTimePickerField
                id="recurringUntil"
                name="recurringUntil"
                label="Recurring Until"
                color="secondary"
                error={Boolean(errors.recurringUntil)}
                helperText={touched.recurringUntil && errors.recurringUntil}
                fullWidth
                margin="normal"
                format="d LLLL yyyy"
                disablePast
                dateOnly
                onChange={value => {
                  setFieldValue('recurringUntil', value, true)
                }}
              />
            </>
          )}
          <FastField
            id="description"
            name="description"
            label="Description"
            color="secondary"
            as={TextField}
            error={errors.description && touched.description}
            helperText={touched.description && errors.description}
            margin="normal"
            fullWidth
            multiline
            rows={4}
            disabled={isLocked('description')}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel
              shrink
              error={!!(touched.exerciseType && errors.exerciseType)}
              disabled={isLocked('exerciseType')}
            >
              Exercise Type
            </InputLabel>
            <FastField id="exerciseType" as={FormGroup} className={classes.typeWrapper}>
              <Grid container spacing={1}>
                {EXERCISE_TYPES.map(type => (
                  <Grid item xs={3} key={type.value}>
                    <Radio
                      value={type.value}
                      name="exerciseType"
                      icon={
                        <ToggleTileItem
                          variant={isLocked('exerciseType') ? 'disabled' : 'light'}
                          icon={type.icon}
                          title={type.label}
                          disabled={isLocked('exerciseType')}
                        />
                      }
                      checkedIcon={
                        <ToggleTileItem
                          variant={isLocked('exerciseType') ? 'disabled' : 'light'}
                          icon={type.icon}
                          title={type.label}
                          checked
                        />
                      }
                      classes={{ root: classes.radioRoot }}
                      disableRipple
                      checked={values.exerciseType === type.value}
                      onChange={event => {
                        setFieldValue('exerciseType', event.target.value)
                        setSubset(event.target.value)
                      }}
                      disabled={isLocked('exerciseType')}
                    />
                  </Grid>
                ))}
              </Grid>
            </FastField>
            {touched.exerciseType && errors.exerciseType && (
              <FormHelperText error>{errors.exerciseType}</FormHelperText>
            )}
          </FormControl>
          <ImageSelectorField
            id="headerImage"
            name="headerImage"
            label="Session Banner Image"
            error={errors.headerImage}
            touched={touched.headerImage}
            setFieldValue={setFieldValue}
            value={values.headerImage}
            subset={subset}
            disabled={isLocked('headerImage')}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FastField
                id="spaces"
                name="spaces"
                label="Spaces Available"
                color="primary"
                type="number"
                as={TextField}
                error={Boolean(errors.spaces && touched.spaces)}
                helperText={touched.spaces && errors.spaces}
                fullWidth
                margin="normal"
                disabled={isLocked('spaces')}
                inputProps={{
                  min: '1',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        color="primary"
                        disabled={isLocked('spaces')}
                        onClick={() => {
                          if (values.spaces > 1) {
                            setFieldValue('spaces', values.spaces - 1)
                          }
                        }}
                      >
                        <MaterialIcon icon="minus" color="inherit" size={3} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        disabled={isLocked('spaces')}
                        onClick={() => {
                          setFieldValue('spaces', values.spaces + 1)
                        }}
                      >
                        <MaterialIcon icon="plus" color="inherit" size={3} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {currency.name !== '' && (
                <FastField
                  id="cost"
                  name="cost"
                  label="Cost"
                  color="primary"
                  type="number"
                  as={TextField}
                  disabled={isLocked('cost')}
                  error={Boolean(errors.cost && touched.cost)}
                  helperText={touched.cost && errors.cost}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{currency.symbol}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">{currency.name}</InputAdornment>,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <FormControl fullWidth margin="normal">
            <InputLabel id="search-accessible-label" shrink disabled={isLocked('accessible')}>
              Accessibility
            </InputLabel>
            <FormControlLabel
              id="accessible"
              name="accessible"
              disabled={isLocked('accessible')}
              control={
                <Switch color="secondary" onChange={handleChange} onBlur={handleBlur} checked={values.accessible} />
              }
              label={
                <>
                  <InputAdornment position="start">
                    <MaterialIcon icon="wheelchair" color={isLocked('accessible') ? 'inherit' : 'secondary'} size={3} />
                  </InputAdornment>
                  <Typography variant="body1">Session is Accessible</Typography>
                </>
              }
              labelPlacement="start"
              classes={{ labelPlacementStart: classes.controlLabel, label: classes.accessibilityLabel }}
            />
          </FormControl>
          {!cancelled && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              fullWidth
              className={classes.submit}
            >
              {submitButtonLabel}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

SessionForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  cancelled: PropTypes.bool,
  lockedFields: PropTypes.object,
}

SessionForm.defaultProps = {
  cancelled: false,
  lockedFields: {},
}

export default SessionForm
