import React from 'react'
import { TextField, Grid, FormControl, FormHelperText, InputLabel, Button, Input } from '@material-ui/core'
import PropTypes from 'prop-types'
import { FastField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import creditCardType from 'credit-card-type'
import MaskedTextField from '../../../MaskedTextField/MaskedTextField'
import MiniCard from '../../../MiniCard/MiniCard'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
}))
const PaymentForm = props => {
  const classes = useStyles()
  const { errors, touched, values, setFieldValue, hideButton } = props

  return (
    <>
      <FastField
        id="creditCardName"
        name="creditCardName"
        label="Name on Card"
        color="secondary"
        as={TextField}
        error={errors.creditCardName && touched.creditCardName}
        helperText={touched.creditCardName && errors.creditCardName}
        margin="normal"
        fullWidth
      />
      <FormControl fullWidth margin="normal">
        <InputLabel error={!!(touched.creditCardNumber && errors.creditCardNumber)}>Card Number</InputLabel>
        <FastField
          id="creditCardNumber"
          name="creditCardNumber"
          color="secondary"
          as={Input}
          inputComponent={MaskedTextField}
          inputProps={{
            mask: '9999 9999 9999 9999',
            maskPlaceholder: '-',
          }}
          endAdornment={<MiniCard issuer={values.issuer} />}
          onChange={event => {
            setFieldValue('creditCardNumber', event.target.value)
            const cardType = creditCardType(event.target.value)

            if (cardType.length === 1) {
              let finalCardType = cardType[0].type

              if (cardType[0].type === 'american-express') {
                finalCardType = 'amex'
              } else if (cardType[0].type === 'diners-club') {
                finalCardType = 'dinersclub'
              }

              setFieldValue('issuer', finalCardType)
            }
          }}
          error={errors.creditCardNumber && touched.creditCardNumber}
        />
        {touched.creditCardNumber && errors.creditCardNumber && (
          <FormHelperText error>{errors.creditCardNumber}</FormHelperText>
        )}
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <InputLabel error={!!(touched.creditCardExpiry && errors.creditCardExpiry)}>Card Expiry</InputLabel>
            <FastField
              id="creditCardExpiry"
              name="creditCardExpiry"
              color="secondary"
              as={Input}
              inputComponent={MaskedTextField}
              inputProps={{
                mask: '99/99',
                maskPlaceholder: 'MM/YY',
              }}
              error={errors.creditCardExpiry && touched.creditCardExpiry}
            />
            {touched.creditCardExpiry && errors.creditCardExpiry && (
              <FormHelperText error>{errors.creditCardExpiry}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FastField
            id="creditCardCCV"
            name="creditCardCCV"
            label="CCV"
            color="secondary"
            as={TextField}
            error={errors.creditCardCCV && touched.creditCardCCV}
            helperText={touched.creditCardCCV && errors.creditCardCCV}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      {!hideButton && (
        <Button type="submit" variant="contained" color="secondary" size="large" fullWidth className={classes.button}>
          Save
        </Button>
      )}
    </>
  )
}

PaymentForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
}

PaymentForm.defaultProps = {
  hideButton: false,
}

export default PaymentForm
