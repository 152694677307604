import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, FastField } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  TextField,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, Autocomplete } from '@material-ui/lab'
import _ from 'lodash'
import { useLoadScript } from '@react-google-maps/api'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import FileField from '../FileField/FileField'
import regionData from '../../services/ultimateCountryData/data.json'
import geocodeAddress from '../../services/geocodeAddress/geocodeAddress'
import ImageSelectorField from '../ImageSelectorField/ImageSelectorField'

const useStyles = makeStyles(theme => ({
  topMargin: {
    marginTop: theme.spacing(2),
  },
  flag: {
    marginRight: theme.spacing(1),
  },
  radioRoot: {
    width: '100%',
    display: 'block',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  typeWrapper: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))
const BusinessSettings = () => {
  const classes = useStyles()
  const auth = useAuth()
  const preselectedCountry =
    auth.business && 'country' in auth.business
      ? regionData.filter(item => item.countryName === auth.business.country)[0]
      : null
  const [country, setCountry] = useState(preselectedCountry)
  const [message, setMessage] = useState(false)
  const [initialValues, setInitialValues] = useState({
    firstname: '',
    lastname: '',
    bio: '',
    headerImage: '',
    tradingName: '',
    companyNumber: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    stateShort: '',
    postcode: '',
    country: '',
    latitude: 0,
    longitude: 0,
    insurance: false,
    terms: false,
  })
  const validationSchema = Yup.object().shape({
    bio: Yup.string().required('Bio is required'),
    headerImage: Yup.string().required('Profile Banner Image is required'),
    tradingName: Yup.string().required('Business/Trading Name is required'),
    companyNumber: Yup.string().when('country', {
      is: 'Australia',
      then: Yup.string().required('An ABN is required in Australia'),
    }),
    suburb: Yup.string().required('Suburb is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
  })
  const mapsScript = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  useEffect(() => {
    if (auth.business && auth.business.firstname !== '') {
      setInitialValues(_.merge(initialValues, auth.business))
    }
  }, [initialValues, auth.business])

  return (
    <>
      <Header title="Business Settings" showBack />
      <Box bgcolor="primary.dark" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            These settings will be used to help run your business on {process.env.REACT_APP_APP_NAME}.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box pt={1} pb={4}>
          {initialValues.firstname !== '' && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async data => {
                let coords = {
                  latitude: 0,
                  longitude: 0,
                }

                if (mapsScript.isLoaded) {
                  coords = await geocodeAddress(
                    `${data.address1}+${data.address2}+${data.suburb}+${data.stateShort}+${data.country}`,
                  )
                }

                auth
                  .updateBusinessSettings({
                    ...data,
                    ...coords,
                  })
                  .then(() => {
                    setMessage({ type: 'success', message: 'Business Information updated successfully.' })
                    window.scrollTo(0, 0)
                  })
                  .catch(error => {
                    setMessage({ type: 'error', message: error.message })
                    window.scrollTo(0, 0)
                  })
              }}
            >
              {({ touched, errors, values, setFieldValue, setFieldTouched }) => (
                <Form>
                  {message.type && (
                    <Alert severity={message.type} className={classes.topMargin}>
                      {message.message}
                    </Alert>
                  )}
                  <Typography variant="h3" color="secondary" gutterBottom className={classes.topMargin}>
                    Profile Info
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    This information will display on your profile that users view.
                  </Typography>
                  <FastField
                    id="bio"
                    name="bio"
                    label="Bio"
                    color="secondary"
                    as={TextField}
                    error={errors.bio && touched.bio}
                    helperText={touched.bio && errors.bio}
                    margin="normal"
                    fullWidth
                    multiline
                    rows={4}
                  />
                  <ImageSelectorField
                    id="headerImage"
                    name="headerImage"
                    label="Profile Banner Image"
                    error={errors.headerImage}
                    touched={touched.headerImage}
                    setFieldValue={setFieldValue}
                    value={values.headerImage}
                    mode="dark"
                  />
                  <Typography variant="h3" color="secondary" gutterBottom className={classes.topMargin}>
                    Business Info
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Some basic information about your business. This will appear on receipts sent to users and can also
                    be used for tax purposes.
                  </Typography>
                  <FastField
                    id="tradingName"
                    name="tradingName"
                    label="Business/Trading Name"
                    color="secondary"
                    as={TextField}
                    error={errors.tradingName && touched.tradingName}
                    helperText={touched.tradingName && errors.tradingName}
                    margin="normal"
                    fullWidth
                  />
                  <FastField
                    id="companyNumber"
                    name="companyNumber"
                    label={auth.userData.country === 'Australia' ? 'ABN/ACN' : 'Company Identifier/Number'}
                    color="secondary"
                    as={TextField}
                    error={errors.companyNumber && touched.companyNumber}
                    helperText={touched.companyNumber && errors.companyNumber}
                    margin="normal"
                    fullWidth
                  />
                  <Typography variant="h3" color="secondary" gutterBottom className={classes.topMargin}>
                    Business Address
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    This address should be the location where you operate as a trainer (eg. Gym, Park, Building
                    address). It will be listed on your profile.
                  </Typography>
                  <FastField
                    id="address1"
                    name="address1"
                    label="Address 1"
                    color="secondary"
                    as={TextField}
                    error={errors.address1 && touched.address1}
                    helperText={touched.address1 && errors.address1}
                    margin="normal"
                    fullWidth
                  />
                  <FastField
                    id="address2"
                    name="address2"
                    label="Address 2"
                    color="secondary"
                    as={TextField}
                    error={errors.address2 && touched.address2}
                    helperText={touched.address2 && errors.address2}
                    margin="normal"
                    fullWidth
                  />
                  <FastField
                    id="suburb"
                    name="suburb"
                    label="Suburb"
                    color="secondary"
                    as={TextField}
                    error={errors.suburb && touched.suburb}
                    helperText={touched.suburb && errors.suburb}
                    margin="normal"
                    fullWidth
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id="register-state-select-label">State</InputLabel>
                        <Field
                          id="state"
                          name="state"
                          labelId="register-state-select-label"
                          color="secondary"
                          as={Select}
                          error={!!(touched.state && errors.state)}
                          onChange={event => {
                            const selectedState = country.regions.filter(item => item.name === event.target.value)

                            setFieldValue('state', event.target.value)
                            setFieldValue('stateShort', selectedState[0].shortCode)
                          }}
                        >
                          <MenuItem value="">Please select your Country first</MenuItem>
                          {country &&
                            country.regions.map(region => (
                              <MenuItem key={region.shortCode} value={region.name}>
                                {region.name} ({region.shortCode})
                              </MenuItem>
                            ))}
                        </Field>
                        {touched.state && errors.state && <FormHelperText error>{errors.state}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FastField
                        id="postcode"
                        name="postcode"
                        label="Postcode"
                        color="secondary"
                        as={TextField}
                        error={errors.postcode && touched.postcode}
                        helperText={touched.postcode && errors.postcode}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Field id="stateShort" name="stateShort" type="hidden" />
                  <Autocomplete
                    id="country"
                    name="country"
                    options={regionData}
                    getOptionLabel={option => option.countryName}
                    onChange={(e, value) => {
                      setCountry(value)
                      setFieldTouched('phone', true)
                      setFieldValue('country', value.countryName)
                      setFieldValue('state', '')
                      setFieldValue('stateShort', '')
                    }}
                    inputValue={values.country}
                    getOptionSelected={(option, value) => option.countryName === value.countryName}
                    renderOption={option => (
                      <>
                        <span className={classes.flag}>{option.emoji}</span> {option.countryName}
                      </>
                    )}
                    renderInput={params => (
                      <Field
                        as={TextField}
                        {...params}
                        name="country"
                        label="Country"
                        margin="normal"
                        fullWidth
                        error={errors.country && touched.country}
                        helperText={touched.country && errors.country}
                      />
                    )}
                  />
                  <Typography variant="h3" color="secondary" gutterBottom className={classes.topMargin}>
                    Insurance Details
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Please upload your insurance policies and terms and conditions. We accept PDF documents.
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="insurance-label" shrink error={errors.insurance && touched.insurance}>
                      Upload Insurance Document
                    </InputLabel>
                    <FastField
                      id="insurance"
                      name="insurance"
                      label="Upload Document"
                      as={FileField}
                      accept=".pdf"
                      multiple
                      value={values.insurance}
                      setFieldValue={setFieldValue}
                    />
                    {touched.insurance && errors.insurance && <FormHelperText error>{errors.insurance}</FormHelperText>}
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="terms-label" shrink error={errors.terms && touched.terms}>
                      Upload Trainer Terms and Conditions Document
                    </InputLabel>
                    <FastField
                      id="terms"
                      name="terms"
                      label="Upload Document"
                      as={FileField}
                      accept=".pdf"
                      multiple
                      setFieldValue={setFieldValue}
                    />
                    {touched.terms && errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
                  </FormControl>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    type="submit"
                    fullWidth
                    className={classes.button}
                  >
                    Save Settings
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Container>
    </>
  )
}

export default BusinessSettings
