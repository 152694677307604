import { isNull } from 'util'

export function formatInitials(user) {
  if (isNull(user)) {
    return ''
  }

  const firstname = user.firstname ? user.firstname.substr(0, 1) : ''
  const lastname = user.lastname ? user.lastname.substr(0, 1) : ''

  return `${firstname}${lastname}`
}

export function formatFullName(user) {
  if (isNull(user)) {
    return ''
  }

  const firstname = user.firstname ? user.firstname : ''
  const lastname = user.lastname ? user.lastname : ''

  return `${firstname} ${lastname}`
}
