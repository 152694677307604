import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Avatar, AppBar, Toolbar, Typography, IconButton, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials } from '../../services/formatters/formatters'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'

const useStyles = makeStyles(theme => ({
  appbar: {
    boxShadow: '0 0 0 transparent',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  avatar: {
    fontSize: theme.spacing(2),
  },
}))
const Header = props => {
  const classes = useStyles()
  const { title, position, showBack } = props
  const auth = useAuth()
  const user = auth.userData
  const history = useHistory()
  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    document.title = `${title} - ${process.env.REACT_APP_APP_NAME}`
  })

  return (
    <>
      <AppBar position={position} color="primary" className={classes.appbar}>
        <Toolbar>
          {showBack && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Back"
              className={classes.backButton}
              onClick={handleBack}
            >
              <MaterialIcon icon="arrow-left" color="inherit" size={3} />
            </IconButton>
          )}
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Link component={RouterLink} to={ROUTES.ACCOUNT} className={classes.link}>
            <Avatar src={user && user.profileImg ? user.profileImg : null} className={classes.avatar}>
              {formatInitials(user)}
            </Avatar>
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  showBack: PropTypes.bool,
}

Header.defaultProps = {
  position: 'fixed',
  showBack: false,
}

export default Header
