import React, { useState } from 'react'
import { Formik, Form, FastField } from 'formik'
import * as Yup from 'yup'
import {
  Container,
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import _ from 'lodash'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import { DEFAULT_UNITS } from '../../constants/settings'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))
const MySettings = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [message, setMessage] = useState(false)
  let initialValues = {
    distanceUnits: DEFAULT_UNITS,
  }
  const validationSchema = Yup.object().shape({
    distanceUnits: Yup.string().oneOf(['km', 'mi']).required('Distance Units is required'),
  })

  if (auth.userData && 'settings' in auth.userData) {
    initialValues = _.merge(initialValues, auth.userData.settings)
  }

  return (
    <>
      <Header title="Settings" showBack />
      <Box bgcolor="primary.dark" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Settings to improve your {process.env.REACT_APP_APP_NAME} experience.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box py={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={data => {
              auth
                .updateUserSettings(data)
                .then(() => {
                  setMessage({ type: 'success', message: 'Settings updated successfully.' })
                  window.scrollTo(0, 0)
                })
                .catch(error => {
                  setMessage({ type: 'error', message: error.message })
                  window.scrollTo(0, 0)
                })
            }}
          >
            {({ touched, errors }) => (
              <Form>
                {message.type && (
                  <Alert severity={message.type} className={classes.alert}>
                    {message.message}
                  </Alert>
                )}
                <FormControl fullWidth margin="normal">
                  <InputLabel id="register-distanceUnits-select-label">Distance Units</InputLabel>
                  <FastField
                    id="distanceUnits"
                    name="distanceUnits"
                    labelId="register-distanceUnits-select-label"
                    color="secondary"
                    as={Select}
                    error={!!(touched.distanceUnits && errors.distanceUnits)}
                  >
                    <MenuItem value="km">Kilometres (km)</MenuItem>
                    <MenuItem value="mi">Miles (mi)</MenuItem>
                  </FastField>
                  {touched.distanceUnits && errors.distanceUnits && (
                    <FormHelperText error>{errors.distanceUnits}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                  fullWidth
                  className={classes.button}
                >
                  Save Settings
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  )
}

export default MySettings
