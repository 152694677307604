import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import { useParams, useHistory } from 'react-router-dom'
import Header from '../Header/Header'
import useAuth from '../../services/AuthProvider/useAuth'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import SessionForm from '../SessionForm/SessionForm'
import * as ROUTES from '../../constants/routes'
import Loading from '../Loading/Loading'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontWeight: 600,
  },
}))
const SessionEdit = () => {
  const classes = useStyles()
  const auth = useAuth()
  const db = useDatabase()
  const { id } = useParams()
  const history = useHistory()
  const [error, setError] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [lockedFields, setLockedFields] = useState(false)
  const [bookings, setBookings] = useState(false)
  const [initialValues, setInitialValues] = useState(false)
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Session Title is required'),
    start: Yup.date().min(new Date(), 'Start Time must be in the future').required('Start Time is required'),
    finish: Yup.date()
      .when('start', st => Yup.date().min(st, 'Finish Time must occur after Start Time'))
      .required('Finish Time is required'),
    exerciseType: Yup.string().required('Exercise Type is required'),
    headerImage: Yup.string().required('Session Banner Image is required'),
    spaces: Yup.number().min(1, 'At least one space is required').required('Spaces Available is required'),
  })
  const handleSubmit = data => {
    console.log(data)
  }

  const handleDelete = () => {
    db.deleteSession(id).then(() => {
      history.push(ROUTES.HOME)
    })
  }

  const handleCancel = () => {
    db.cancelSession(id).then(() => {
      setCancelled(true)
    })
  }

  useEffect(() => {
    db.getSession(id).then(result => {
      if (result.trainerId === auth.user.uid) {
        if (result.status === 'cancelled') {
          setLockedFields({
            title: true,
            start: true,
            finish: true,
            exerciseType: true,
            headerImage: true,
            spaces: true,
            cost: true,
            recurring: true,
            accessible: true,
          })
        }

        setInitialValues({
          ...result,
          start: result.start.toDate(),
          finish: result.finish.toDate(),
        })
        db.getSessionBookings(id).then(data => {
          setBookings(data)

          if (data && data.filter(item => item.status !== 'cancelled').length > 0) {
            setLockedFields({
              title: false,
              start: true,
              finish: true,
              exerciseType: true,
              headerImage: false,
              spaces: false,
              cost: true,
              recurring: true,
              accessible: false,
            })
          }
        })
      } else {
        setError(true)
      }
    })
  }, [id, auth.user.uid, db])

  return (
    <>
      <Header title="Edit Session" showBack />
      <Box bgcolor="background.paper" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Editing training session.
          </Typography>
          <br />
          <Typography variant="caption" color="textPrimary">
            <strong>Please note:</strong> You will only be able to edit this session in full while there are no
            bookings. Once a booking has been placed certain fields will be locked.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box pt={1} pb={4}>
          {initialValues && lockedFields ? (
            <>
              {!error ? (
                <>
                  {initialValues.status === 'cancelled' && (
                    <Alert severity="warning">This session has been cancelled</Alert>
                  )}
                  <SessionForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    submitButtonLabel="Update Session"
                    cancelled={cancelled}
                    lockedFields={lockedFields}
                  />
                  {initialValues.status !== 'cancelled' && (
                    <>
                      {bookings && bookings.filter(item => item.status !== 'cancelled').length > 0 ? (
                        <>
                          <Alert severity="warning" className={classes.button}>
                            You cannot cancel this session as there are still active bookings. Please cancel the
                            bookings first before cancelling the session.
                          </Alert>
                          <Button
                            variant="contained"
                            href={`${ROUTES.SESSION}/${id}`}
                            fullWidth
                            className={classes.button}
                          >
                            Back to Session
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          className={classes.button}
                          onClick={() => {
                            handleCancel()
                          }}
                        >
                          Cancel Session
                        </Button>
                      )}
                      {(bookings.length === 0 || bookings === false) && (
                        <Button
                          variant="contained"
                          color="default"
                          size="large"
                          fullWidth
                          onClick={() => {
                            handleDelete()
                          }}
                          className={classes.button}
                        >
                          Delete Session
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Alert severity="error">You do not have permission to edit this session.</Alert>
              )}
            </>
          ) : (
            <Loading width={1} height={300} />
          )}
        </Box>
      </Container>
    </>
  )
}

export default SessionEdit
