import PropTypes from 'prop-types'
import { isNull } from 'util'
import images from './images.json'

const randomImage = group => {
  let subset = images.types
  const min = 0

  if (!isNull(group) && typeof images[group] !== 'undefined') {
    subset = images[group]
  }

  const max = subset.length - 1
  const random = Math.floor(Math.random() * (max - min + 1)) + min

  return subset[random]
}

randomImage.propTypes = {
  group: PropTypes.string,
}

const imageSubset = group => {
  if (!isNull(group) && typeof images[group] !== 'undefined') {
    return images[group]
  }

  return images.types
}

export default randomImage
export { imageSubset }
