import IconButton from '@material-ui/core/IconButton'
import { Star, StarOutline } from 'mdi-material-ui'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import isTrainerFavourite from '../../services/isTrainerFavourite/isTrainerFavourite'
import useAuth from '../../../../services/AuthProvider/useAuth'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
}))
const FavouriteButton = ({ trainerId }) => {
  const classes = useStyles()
  const auth = useAuth()
  const favStatus =
    auth.userData && 'favouriteTrainers' in auth.userData
      ? isTrainerFavourite(trainerId, auth.userData.favouriteTrainers)
      : false
  const [favourite, setFavourite] = useState(favStatus)
  const handleFavourite = () => {
    if (favStatus) {
      auth.updateFavouriteTrainers(trainerId, 'remove')
    } else {
      auth.updateFavouriteTrainers(trainerId, 'add')
    }

    setFavourite(!favourite)
  }

  return (
    <IconButton aria-label="favourite" color="secondary" onClick={handleFavourite} className={classes.root}>
      {favourite ? <Star fontSize="large" /> : <StarOutline fontSize="large" />}
    </IconButton>
  )
}

FavouriteButton.propTypes = {
  trainerId: PropTypes.string.isRequired,
}

export default FavouriteButton
