import PropTypes from 'prop-types'
import React from 'react'
import {
  Run,
  Dumbbell,
  Timer,
  Bike,
  Yoga,
  BoxingGlove,
  WeightLifter,
  ShoePrint,
  Eye,
  EyeOff,
  Account,
  Whistle,
  ArrowLeft,
  ArrowRight,
  MapMarker,
  MapMarkerDistance,
  ClockStart,
  WheelchairAccessibility,
  ContentSave,
  Tune,
  FilterVariant,
  Magnify,
  CrosshairsGps,
  Camera,
  CreditCard,
  KeyVariant,
  Cog,
  Widgets,
  Logout,
  Receipt,
  Domain,
  CloudUpload,
  FilePdf,
  Calendar,
  Plus,
  Minus,
  Clock,
  ClipboardList,
  CashMultiple,
  Pencil,
  CheckboxMarkedCircle,
  Directions,
  TrashCan,
  Repeat,
  Loading,
  Facebook,
  Instagram,
  Twitter,
  GooglePlay,
  AppleIos,
  MapMarkerRadius,
  GestureTap,
  Menu,
  ViewDashboard,
  TableEye,
  Star,
} from 'mdi-material-ui'
import { useTheme } from '@material-ui/core/styles'

const MaterialIcon = ({ icon, color, size, ...other }) => {
  const theme = useTheme()
  const mapping = {
    run: Run,
    dumbbell: Dumbbell,
    timer: Timer,
    bike: Bike,
    yoga: Yoga,
    'boxing-glove': BoxingGlove,
    'weight-lifter': WeightLifter,
    'shoe-print': ShoePrint,
    eye: Eye,
    'eye-off': EyeOff,
    account: Account,
    whistle: Whistle,
    'arrow-left': ArrowLeft,
    'arrow-right': ArrowRight,
    'map-marker': MapMarker,
    distance: MapMarkerDistance,
    'clock-start': ClockStart,
    save: ContentSave,
    tune: Tune,
    wheelchair: WheelchairAccessibility,
    filter: FilterVariant,
    search: Magnify,
    gps: CrosshairsGps,
    camera: Camera,
    'credit-card': CreditCard,
    key: KeyVariant,
    cog: Cog,
    widgets: Widgets,
    logout: Logout,
    receipt: Receipt,
    business: Domain,
    upload: CloudUpload,
    pdf: FilePdf,
    calendar: Calendar,
    plus: Plus,
    minus: Minus,
    clock: Clock,
    'clipboard-list': ClipboardList,
    cash: CashMultiple,
    pencil: Pencil,
    'checkbox-marked-circle': CheckboxMarkedCircle,
    directions: Directions,
    'trash-can': TrashCan,
    repeat: Repeat,
    loading: Loading,
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    'play-store': GooglePlay,
    'app-store': AppleIos,
    'map-marker-radius': MapMarkerRadius,
    'gesture-tap': GestureTap,
    menu: Menu,
    dashboard: ViewDashboard,
    'table-eye': TableEye,
    star: Star,
  }
  const MappedIcon = mapping[icon]

  return <MappedIcon color={color} style={{ fontSize: theme.spacing(size) }} {...other} />
}

MaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
}

MaterialIcon.defaultProps = {
  color: 'primary',
  size: 5,
}

export default MaterialIcon
