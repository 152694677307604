import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import 'react-credit-cards/es/styles-compiled.css'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  issuer: {
    display: 'inline-block',
    width: theme.spacing(6),
    height: theme.spacing(4),
    borderRadius: '4px',
    overflow: 'hidden',
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'static',
    display: 'block',
    transform: 'rotate(0)',
    padding: theme.spacing(0.5),
  },
  logo: {
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'static',
    backgroundSize: 'contain',
    backgroundPosition: '50%',
  },
}))
const MiniCard = ({ issuer }) => {
  const classes = useStyles()

  return (
    <span className={classNames(`rccs__card--${issuer}`, classes.issuer)}>
      <span className={classNames('rccs__card__background', classes.background)}>
        <span className={classNames('rccs__issuer', classes.logo)} />
      </span>
    </span>
  )
}

MiniCard.propTypes = {
  issuer: PropTypes.string.isRequired,
}

export default MiniCard
