import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import SignUp from '../SignUp/SignUp'
import LogIn from '../LogIn/LogIn'
import SearchPage from '../SearchPage/SearchPage'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import UserDashboard from '../UserDashboard/UserDashboard'
import TrainerDashboard from '../TrainerDashboard/TrainerDashboard'
import ChangePassword from '../ChangePassword/ChangePassword'
import MyAccount from '../MyAccount/MyAccount'
import TrainerProfile from '../TrainerProfile/TrainerProfile'
import MyDetails from '../MyDetails/MyDetails'
import MyPaymentMethod from '../MyPaymentMethod/MyPaymentMethod'
import MySettings from '../MySettings/MySettings'
import BusinessSettings from '../BusinessSettings/BusinessSettings'
import SessionAdd from '../SessionAdd/SessionAdd'
import SessionEdit from '../SessionEdit/SessionEdit'
import SessionPage from '../SessionPage/SessionPage'
import LandingPage from '../LandingPage/LandingPage'
import PrivacyPolicy from '../LandingPage/PrivacyPolicy'
import BookingConfirmation from '../BookingConfirmation/BookingConfirmation'
import BookingView from '../BookingView/BookingView'
import AuthAction from '../AuthAction/AuthAction'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'
import { findFitTheme, findFitThemeDark, findFitThemePublic } from '../../services/findFitTheme/findFitTheme'
import RouteWithTheme from '../RouteWithTheme/RouteWithTheme'

const App = () => {
  const auth = useAuth()

  return (
    <ThemeProvider theme={findFitTheme}>
      <CssBaseline />
      <Router>
        <RouteWithTheme exact path={ROUTES.LANDING} component={LandingPage} theme={findFitThemePublic} />
        <RouteWithTheme exact path={ROUTES.PRIVACY} component={PrivacyPolicy} theme={findFitThemePublic} />
        <RouteWithTheme exact path={ROUTES.AUTH_ACTION} component={AuthAction} theme={findFitThemePublic} />
        <RouteWithTheme path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} theme={findFitThemePublic} />
        <RouteWithTheme path={ROUTES.LOGIN} component={LogIn} theme={findFitThemePublic} />
        <RouteWithTheme path={ROUTES.SIGNUP} component={SignUp} theme={findFitThemePublic} />
        {auth.user && (
          <>
            <RouteWithTheme exact path={ROUTES.ACCOUNT} component={MyAccount} theme={findFitThemeDark} />
            <RouteWithTheme path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} theme={findFitThemeDark} />
            <RouteWithTheme path={ROUTES.MY_DETAILS} component={MyDetails} theme={findFitThemeDark} />
            <RouteWithTheme path={ROUTES.MY_PAYMENT_METHOD} component={MyPaymentMethod} theme={findFitThemeDark} />
            <RouteWithTheme path={ROUTES.MY_SETTINGS} component={MySettings} theme={findFitThemeDark} />
            <RouteWithTheme path={`${ROUTES.SESSION}/:id`} component={SessionPage} theme={findFitTheme} />
            {auth.userData && 'accountType' in auth.userData && auth.userData.accountType === 'user' && (
              <>
                <RouteWithTheme path={ROUTES.HOME} component={UserDashboard} theme={findFitTheme} />
                <RouteWithTheme path={ROUTES.SEARCH} component={SearchPage} theme={findFitTheme} />
                <RouteWithTheme path={`${ROUTES.TRAINER}/:id`} component={TrainerProfile} theme={findFitTheme} />
                <RouteWithTheme
                  path={`${ROUTES.BOOKING_CONFIRM}/:sessionId`}
                  component={BookingConfirmation}
                  theme={findFitTheme}
                />
              </>
            )}
            {auth.userData && 'accountType' in auth.userData && auth.userData.accountType === 'trainer' && (
              <>
                <RouteWithTheme path={ROUTES.HOME} component={TrainerDashboard} theme={findFitTheme} />
                <RouteWithTheme exact path={ROUTES.ADD_SESSION} component={SessionAdd} theme={findFitTheme} />
                <RouteWithTheme path={`${ROUTES.EDIT_SESSION}/:id`} component={SessionEdit} theme={findFitTheme} />
                <RouteWithTheme path={ROUTES.BUSINESS_SETTINGS} component={BusinessSettings} theme={findFitThemeDark} />
                <RouteWithTheme
                  path={`${ROUTES.BOOKING_VIEW}/:bookingId`}
                  component={BookingView}
                  theme={findFitTheme}
                />
              </>
            )}
          </>
        )}
      </Router>
    </ThemeProvider>
  )
}

export default App
