/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react'
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  Link,
  IconButton,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
  Drawer,
  Paper,
  Divider,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'
import * as ROUTES from '../../constants/routes'
import features from '../../constants/features'

const useStyles = makeStyles(theme => ({
  row: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  sectionTitle: {
    marginBottom: theme.spacing(6),
  },
  whyWrapper: {
    backgroundColor: '#F2F2F2',
    color: theme.palette.text.secondary,
  },
  whyIcon: {
    marginBottom: theme.spacing(4),
  },
  whyItemTitle: {
    fontFamily: robotoFontStack,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 500,
  },
  trial: {
    fontFamily: robotoFontStack,
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  trialButton: {
    marginLeft: theme.spacing(4),
    marginTop: 0,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  logo: {
    marginBottom: theme.spacing(4),
  },
  smallLogo: {
    width: 100,
    height: 'auto',
  },
  link: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  menuMobile: {
    minWidth: 250,
  },
  toolbar: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  menuButton: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(3),
  },
  headerBox: {
    backgroundImage: 'url(/images/header_bg.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  headerDeviceWrapper: {
    backgroundImage: 'url(/images/circle_dark.svg)',
    backgroundSize: '100%',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    marginBottom: -250,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 0,
    },
  },
  featuresDeviceWrapper: {
    backgroundImage: 'url(/images/circle_light.svg)',
    backgroundSize: '100%',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
  },
  pricingPaper: {
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    color: theme.palette.text.secondary,
  },
  pricingLabel: {
    padding: theme.spacing(3),
    backgroundColor: '#f2f2f2',
  },
  pricingApps: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  pricingCta: {
    marginTop: theme.spacing(4),
  },
  price: {
    fontSize: '5rem',
    lineHeight: 1,
    fontWeight: 600,
  },
  featuresAvatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  featuresLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1.25rem',
  },
}))
const LandingMenu = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <List component="nav" className={!isMobile ? classes.menu : classes.menuMobile}>
      <ListItem button component="a" href="/#about" className={classes.listItem} style={{ minWidth: 170 }}>
        <ListItemText
          primary={`What is ${process.env.REACT_APP_APP_NAME}?`}
          primaryTypographyProps={!isMobile ? { align: 'center' } : null}
        />
      </ListItem>
      <ListItem button component="a" href="/#features" className={classes.listItem}>
        <ListItemText primary="Features" primaryTypographyProps={!isMobile ? { align: 'center' } : null} />
      </ListItem>
      <ListItem button component="a" href="/#pricing" className={classes.listItem}>
        <ListItemText primary="Pricing" primaryTypographyProps={!isMobile ? { align: 'center' } : null} />
      </ListItem>
      <ListItem button component="a" href={ROUTES.SIGNUP} className={classes.listItem}>
        <ListItemText primary="Signup" primaryTypographyProps={!isMobile ? { align: 'center' } : null} />
      </ListItem>
      {!isMobile && (
        <ListItem>
          <Button variant="contained" size="large" color="secondary" href={ROUTES.LOGIN} fullWidth={isMobile}>
            Login
          </Button>
        </ListItem>
      )}
    </List>
  )
}

const PageHeader = ({ children, showLogo }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [mobileOpen, setMobileOpen] = useState(false)

  return (
    <Box bgcolor="primary.main" pt={2} pb={6} className={classes.headerBox}>
      <Container>
        <AppBar position="relative" color="transparent" elevation={0}>
          <Toolbar className={classes.toolbar} style={{ justifyContent: showLogo ? 'space-between' : 'flex-end' }}>
            {showLogo && (
              <Link href="/">
                <img
                  src="/images/logo/findfit_logo_coloured_dark_bg.svg"
                  alt={process.env.REACT_APP_APP_NAME}
                  title={process.env.REACT_APP_APP_NAME}
                  className={classes.smallLogo}
                />
              </Link>
            )}
            {isMobile ? (
              <>
                <Button variant="contained" size="large" color="secondary" href={ROUTES.LOGIN}>
                  Login
                </Button>
                <IconButton
                  edge="end"
                  color="default"
                  size="medium"
                  className={classes.menuButton}
                  onClick={() => setMobileOpen(true)}
                >
                  <MaterialIcon icon="menu" color="inherit" size={4} />
                </IconButton>
                <Drawer anchor="right" open={mobileOpen} onClose={() => setMobileOpen(false)}>
                  <LandingMenu />
                </Drawer>
              </>
            ) : (
              <LandingMenu />
            )}
          </Toolbar>
        </AppBar>
        {children}
      </Container>
    </Box>
  )
}

PageHeader.propTypes = {
  children: PropTypes.any,
  showLogo: PropTypes.bool,
}
PageHeader.defaultProps = {
  children: false,
  showLogo: false,
}

const PageFooter = () => {
  const classes = useStyles()
  const now = new Date()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <Box bgcolor="background.paper" py={6}>
      <Container>
        <Grid container>
          <Grid item xs={12} md lg={9}>
            <Typography
              variant="body2"
              color="textSecondary"
              align={isMobile ? 'center' : 'left'}
              gutterBottom={isMobile}
              paragraph={isMobile}
            >
              &copy; Copyright {process.env.REACT_APP_APP_NAME} {now.getFullYear()}. All Rights Reserved. Please read
              our Terms &amp; Conditions &amp;{' '}
              <Link href={ROUTES.PRIVACY} color="textPrimary">
                Privacy Policy
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} md lg={3}>
            <Box justifyContent={isMobile ? 'center' : 'flex-end'} display="flex">
              <IconButton
                href="https://facebook.com/findfitapp"
                target="_blank"
                size="small"
                className={classes.iconButton}
              >
                <MaterialIcon icon="facebook" size={2.5} color="inherit" />
              </IconButton>
              <IconButton
                href="https://instagram.com/findfitapp"
                target="_blank"
                size="small"
                className={classes.iconButton}
              >
                <MaterialIcon icon="instagram" size={2.5} color="inherit" />
              </IconButton>
              <IconButton
                href="https://twitter.com/findfitapp"
                target="_blank"
                size="small"
                className={classes.iconButton}
              >
                <MaterialIcon icon="twitter" size={2.5} color="inherit" />
              </IconButton>
              <IconButton href="#" target="_blank" size="small" className={classes.iconButton}>
                <MaterialIcon icon="play-store" size={2.5} color="inherit" />
              </IconButton>
              <IconButton href="#" target="_blank" size="small" className={classes.iconButton}>
                <MaterialIcon icon="app-store" size={2.5} color="inherit" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const FeaturesItem = ({ icon, heading, text, imageSrc, handleClick }) => {
  const classes = useStyles()

  return (
    <ListItem
      onClick={() => {
        handleClick(imageSrc)
      }}
      style={{ cursor: 'pointer' }}
    >
      <ListItemAvatar>
        <Avatar className={classes.featuresAvatar}>
          <MaterialIcon icon={icon} size={3} color="inherit" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={heading} secondary={text} classes={{ primary: classes.featuresLabel }} />
    </ListItem>
  )
}

FeaturesItem.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

const LandingPage = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [featureImage, setFeatureImage] = useState('/images/screenshots/userdashboard.png')

  return (
    <>
      <PageHeader>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={5} style={{ display: 'flex', alignItems: 'center' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src="/images/logo/findfit_logo_coloured_dark_bg.svg"
                alt={process.env.REACT_APP_APP_NAME}
                width={200}
                height="auto"
                className={classes.logo}
              />
              <Typography variant="body1" className={classes.subtitle} align="center" gutterBottom paragraph>
                {process.env.REACT_APP_APP_NAME} is a location-based personal trainer directory and training session
                booking system.
              </Typography>
              <Box>
                <Link href="https://www.apple.com/au/ios/app-store/" target="_blank" className={classes.link}>
                  <img
                    src="/images/app-store.svg"
                    title="Apple App Store"
                    alt="Apple App Store"
                    width="auto"
                    height={40}
                  />
                </Link>
                <Link href="https://play.google.com/" target="_blank" className={classes.link}>
                  <img
                    src="/images/google-play-badge.svg"
                    title="Google Play Store"
                    alt="Google Play Store"
                    height={40}
                    width="auto"
                  />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box display="block" textAlign="center" className={classes.headerDeviceWrapper}>
              <img
                src="/images/screenshots/searchresults.png"
                width="auto"
                height={isMobile ? 550 : 720}
                alt="device"
                className={classes.device}
              />
            </Box>
          </Grid>
        </Grid>
      </PageHeader>
      <Box bgcolor="common.white" py={6} color="text.secondary">
        <a name="about" />
        <Container>
          <Grid container>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="body1" component="h3" color="primary" gutterBottom className={classes.bold}>
                Want a personal trainer or bootcamp style class but don&apos;t know where to look?
              </Typography>
              <Typography variant="body2" gutterBottom paragraph>
                Find Fit is the place for you. Search our directory of personal trainers near you by everything from
                exercise type to class size and book in a session to start your fitness journey today.
              </Typography>
              <br />
              <Typography variant="body1" component="h3" color="primary" gutterBottom className={classes.bold}>
                Are you a personal trainer that wants more clients?
              </Typography>
              <Typography variant="body2">
                Advertisting on platforms like Facebook and Instagram can be difficult to setup and expensive to run.
                Let Find Fit take the hassle out of the process and gain access to a wealth of users wanting to progress
                on their fitness journey.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={6} className={classes.whyWrapper}>
        <Container>
          <Typography
            variant="h2"
            color="primary"
            gutterBottom
            paragraph
            align="center"
            className={classes.sectionTitle}
          >
            Why Find Fit?
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MaterialIcon icon="map-marker-radius" size={15} color="secondary" className={classes.whyIcon} />
                <Typography variant="h3" component="h4" color="primary" gutterBottom className={classes.whyItemTitle}>
                  Results near you
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  Whether you are a personal trainer trying to attract new customers or a user looking for a new way of
                  getting fit, you can find it here.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MaterialIcon icon="weight-lifter" size={15} color="secondary" className={classes.whyIcon} />
                <Typography variant="h3" component="h4" color="primary" gutterBottom className={classes.whyItemTitle}>
                  Range of training styles
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  Looking for an eight week bootcamp to get in shape for summer or want to try boxing? We&apos;ve got
                  trainers for all exercise types.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <MaterialIcon icon="gesture-tap" size={15} color="secondary" className={classes.whyIcon} />
                <Typography variant="h3" component="h4" color="primary" gutterBottom className={classes.whyItemTitle}>
                  Easy to use
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  Find and book sessions quickly and easily using just a few taps whether it be with one of your
                  favourite trainers or someone you&apos; found via the search.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor="secondary.main" py={6}>
        <Container>
          <Typography variant="h4" color="textPrimary" align="center" className={classes.trial}>
            Start your 14 day free trial
            <Button
              href={ROUTES.SIGNUP}
              variant="contained"
              color="primary"
              size="large"
              className={classes.trialButton}
            >
              Sign Up
            </Button>
          </Typography>
        </Container>
      </Box>
      <Box bgcolor="common.white" py={6} color="text.secondary">
        <Container>
          <a name="features" />
          <Typography
            variant="h2"
            color="primary"
            gutterBottom
            paragraph
            align="center"
            className={classes.sectionTitle}
          >
            Features
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={7}>
              <Box display="block" textAlign="center" className={classes.featuresDeviceWrapper}>
                <img
                  src={featureImage}
                  width="auto"
                  height={isMobile ? 550 : 720}
                  alt="device"
                  className={classes.device}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm>
              <Typography variant="h3" color="secondary">
                For the Users
              </Typography>
              <List>
                {features.user.map(item => (
                  <FeaturesItem key={item.heading} {...item} handleClick={setFeatureImage} />
                ))}
              </List>
              <Typography variant="h3" color="secondary">
                For the Trainers
              </Typography>
              <List>
                {features.trainer.map(item => (
                  <FeaturesItem key={item.heading} {...item} handleClick={setFeatureImage} />
                ))}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor="primary.main" py={6}>
        <Container>
          <a name="pricing" />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            paragraph
            align="center"
            className={classes.sectionTitle}
          >
            Pricing
          </Typography>
          <Container maxWidth="md">
            <Grid container spacing={4}>
              <Grid item xs={12} sm>
                <Paper className={classes.pricingPaper} elevation={2}>
                  <Typography variant="h4" color="primary" align="center" className={classes.pricingLabel}>
                    Users
                  </Typography>
                  <Box py={3}>
                    <Typography variant="body1" color="secondary" align="center" className={classes.price}>
                      FREE
                    </Typography>
                    <Typography variant="body2" align="center">
                      just pay the trainer for any sessions you book
                    </Typography>
                  </Box>
                  <Box p={2} pt={0}>
                    <List>
                      <Divider />
                      <ListItem divider>
                        <ListItemText primary="Friendly User Dashboard" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Comprehensive Location Search" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Trainer Profiles and Favourites" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Easy Session Booking" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Automatic Credit Card Purchases" />
                      </ListItem>
                    </List>
                    <Button
                      variant="contained"
                      color="secondary"
                      href={ROUTES.SIGNUP}
                      target="_blank"
                      size="large"
                      fullWidth
                    >
                      Get Started
                    </Button>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm>
                <Paper className={classes.pricingPaper} elevation={2}>
                  <Typography variant="h4" color="primary" align="center" className={classes.pricingLabel}>
                    Trainers
                  </Typography>
                  <Box py={3} display="flex" flexDirection="column" alignItems="center">
                    <Box display="flex" flexDirection="row">
                      <Typography variant="body1" color="secondary" align="center">
                        $
                      </Typography>
                      <Typography variant="body1" color="secondary" align="center" className={classes.price}>
                        20
                      </Typography>
                      <Typography variant="body1" color="secondary" align="center" style={{ alignSelf: 'flex-end' }}>
                        /month
                      </Typography>
                    </Box>
                    <Typography variant="body2" align="center">
                      plus $1 per booking
                    </Typography>
                  </Box>
                  <Box p={2} pt={0}>
                    <List>
                      <Divider />
                      <ListItem divider>
                        <ListItemText primary="Listing on the Directory" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Simple Session Setup" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Recurring Session Capabilities" />
                      </ListItem>
                      <ListItem divider>
                        <ListItemText primary="Session Attendees List" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Automatic Credit Card Billing" />
                      </ListItem>
                    </List>
                    <Button
                      variant="contained"
                      color="secondary"
                      href={ROUTES.SIGNUP}
                      target="_blank"
                      size="large"
                      fullWidth
                    >
                      Sign Up
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <Typography variant="h4" color="textPrimary" align="center" className={classes.pricingCta}>
            Download Now from
            <Box className={classes.pricingApps}>
              <Link href="https://www.apple.com/au/ios/app-store/" target="_blank" className={classes.link}>
                <img
                  src="/images/app-store.svg"
                  title="Apple App Store"
                  alt="Apple App Store"
                  width="auto"
                  height={40}
                />
              </Link>
              <Link href="https://play.google.com/" target="_blank" className={classes.link}>
                <img
                  src="/images/google-play-badge.svg"
                  title="Google Play Store"
                  alt="Google Play Store"
                  height={40}
                  width="auto"
                />
              </Link>
            </Box>
          </Typography>
        </Container>
      </Box>
      <PageFooter />
    </>
  )
}

export default LandingPage
export { PageHeader, PageFooter }
