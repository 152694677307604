import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App/App'
import FirebaseProvider from './services/Firebase/Firebase'
import DatabaseProvider from './services/DatabaseProvider/DatabaseProvider'
import AuthProvider from './services/AuthProvider/AuthProvider'
import * as serviceWorker from './serviceWorker'
import 'typeface-roboto'

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider>
      <AuthProvider>
        <DatabaseProvider>
          <App />
        </DatabaseProvider>
      </AuthProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
