const bookingStatusColor = status => {
  if (status === 'paid') {
    return 'secondary'
  }

  if (status === 'cancelled') {
    return 'primary'
  }

  return 'default'
}

export default bookingStatusColor
