import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

const PasswordField = props => {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              <MaterialIcon icon={showPassword ? 'eye-off' : 'eye'} size={2.5} color="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordField
