import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, Paper, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import UserClassTile from '../ClassTile/UserClassTile'
import SlickSlider from '../SlickSlider/SlickSlider'
import useAuth from '../../services/AuthProvider/useAuth'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import FavouriteTrainersSlider from './components/FavouriteTrainersSlider/FavouriteTrainerSlider'
import Header from '../Header/Header'
import * as ROUTES from '../../constants/routes'
import Loading from '../Loading/Loading'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
  },
  slider: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
}))
const UserDashboard = () => {
  const classes = useStyles()
  const auth = useAuth()
  const db = useDatabase()
  const [favTrainers, setFavTrainers] = useState(false)
  const [upcomingSessions, setUpcomingSessions] = useState(false)
  const [pastSessions, setPastSessions] = useState(false)
  const [trainers, setTrainers] = useState([])
  const [loadedSessions, setLoadedSessions] = useState(false)
  const [loadedTrainers, setLoadedTrainers] = useState(false)

  useEffect(() => {
    db.getTrainersByIds(auth.userData.favouriteTrainers).then(results => {
      setFavTrainers(results)
      setLoadedTrainers(true)
    })
    db.getUserBookings().then(bookings => {
      const upcoming = []
      const past = []
      const tempTrainers = []

      if (bookings !== false) {
        bookings.forEach(item => {
          tempTrainers.push(item.trainerId)
          if (item.finish && item.finish.toDate() < new Date()) {
            past.push(item)
          } else {
            upcoming.push(item)
          }
        })
        setUpcomingSessions(upcoming)
        setPastSessions(past)

        db.getTrainersByIds(tempTrainers).then(results => {
          setTrainers(results)
        })
      }

      setLoadedSessions(true)
    })
  }, [auth.userData, db])

  return (
    <>
      <Header title="Dashboard" />
      <Container maxWidth="md">
        <Box mb={1} py={2}>
          <Box pb={4}>
            <Typography variant="h3" color="primary" gutterBottom paragraph>
              Upcoming Sessions
            </Typography>
            {loadedSessions ? (
              <>
                {upcomingSessions.length ? (
                  <SlickSlider mode="standardSingle" showNav className={classes.slider}>
                    {upcomingSessions.map(session => {
                      const trainer = trainers.filter(item => item.id === session.trainerId)[0]
                      const newSession = {
                        ...session,
                        trainer,
                      }

                      return (
                        trainer && (
                          <Box key={session.sessionId} p={1}>
                            <UserClassTile {...newSession} />
                          </Box>
                        )
                      )
                    })}
                  </SlickSlider>
                ) : (
                  <Paper className={classes.paper}>You don&apos;t have any upcoming Sessions.</Paper>
                )}
              </>
            ) : (
              <Loading height={200} />
            )}
          </Box>
          <Box pb={4}>
            <Typography variant="h3" color="primary" gutterBottom paragraph>
              Attended Sessions
            </Typography>
            {loadedSessions ? (
              <>
                {pastSessions ? (
                  <SlickSlider mode="standardSingle" showNav>
                    {pastSessions.map(session => {
                      const trainer = trainers.filter(item => item.id === session.trainerId)[0]
                      const newSession = {
                        ...session,
                        trainer,
                      }

                      return (
                        trainer && (
                          <Box key={session.sessionId} px={1}>
                            <UserClassTile {...newSession} />
                          </Box>
                        )
                      )
                    })}
                  </SlickSlider>
                ) : (
                  <Paper className={classes.paper}>You don&apos;t have any attended Sessions.</Paper>
                )}
              </>
            ) : (
              <Loading height={200} />
            )}
          </Box>
          <Typography variant="h3" color="primary" gutterBottom paragraph>
            Favourite Trainers
          </Typography>
          {loadedTrainers ? (
            <>
              {favTrainers ? (
                <FavouriteTrainersSlider trainers={favTrainers} />
              ) : (
                <Paper className={classes.paper}>You don&apos;t have any Favourite Trainers.</Paper>
              )}
            </>
          ) : (
            <Loading height={96} />
          )}
        </Box>
      </Container>
      <Fab color="secondary" aria-label="Search" href={ROUTES.SEARCH} className={classes.fab}>
        <MaterialIcon icon="search" color="inherit" size={3} />
      </Fab>
    </>
  )
}

export default UserDashboard
