import React from 'react'
import {
  Typography,
  Container,
  Box,
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Header from '../Header/Header'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials, formatFullName } from '../../services/formatters/formatters'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    fontSize: '3rem',
  },
  badge: {
    border: `3px solid ${theme.palette.background.default}`,
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    cursor: 'pointer',
  },
  name: {
    marginTop: theme.spacing(4),
    fontSize: '2rem',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  locationIcon: {
    marginRight: theme.spacing(0.5),
  },
  locationText: {
    verticalAlign: 'top',
  },
  menu: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  input: {
    display: 'none',
  },
  label: {
    cursor: 'pointer',
  },
}))
const MyAccount = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const user = auth.userData
  const business = auth.business || false

  return (
    <>
      <Header title="My Account" showBack />
      <Container maxWidth="sm">
        <Box w={1} py={8} textAlign="center">
          <input
            type="file"
            accept=".jpg,.png"
            id="profile-image-uploader"
            className={classes.input}
            onChange={event => {
              auth.uploadProfileImage(event.currentTarget.files[0])
            }}
          />
          <Badge
            overlap="circle"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <label htmlFor="profile-image-uploader" className={classes.label}>
                <MaterialIcon icon="camera" color="inherit" size={2.5} />
              </label>
            }
            color="primary"
            classes={{ badge: classes.badge }}
          >
            <Avatar
              src={user && 'profileImg' in user ? user.profileImg : null}
              color="secondary"
              alt={formatFullName(user)}
              className={classes.avatar}
            >
              {formatInitials(user)}
            </Avatar>
          </Badge>
          <Typography variant="h1" color="inherit" className={classes.name}>
            {formatFullName(user)}
          </Typography>
          <MaterialIcon icon="map-marker" color="secondary" size={3} className={classes.locationIcon} />
          <Typography variant="subtitle1" color="textPrimary" display="inline" className={classes.locationText}>
            {business.suburb ? `${business.suburb}, ${business.stateShort}` : `${user.suburb}, ${user.stateShort}`}
          </Typography>
        </Box>
        <List component="nav" className={classes.menu}>
          <ListItem button component={RouterLink} to={ROUTES.HOME}>
            <ListItemIcon>
              <MaterialIcon icon="widgets" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <Divider />
          <ListItem button component={RouterLink} to={ROUTES.MY_DETAILS}>
            <ListItemIcon>
              <MaterialIcon icon="account" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="My Details" />
          </ListItem>
          <Divider />
          <ListItem button component={RouterLink} to={ROUTES.MY_PAYMENT_METHOD}>
            <ListItemIcon>
              <MaterialIcon icon="credit-card" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="Payment Method" />
          </ListItem>
          <Divider />
          <ListItem button component={RouterLink} to={ROUTES.CHANGE_PASSWORD}>
            <ListItemIcon>
              <MaterialIcon icon="key" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="Change Password" />
          </ListItem>
          <Divider />
          {user.accountType === 'trainer' && (
            <>
              <ListItem button component={RouterLink} to={ROUTES.BUSINESS_SETTINGS}>
                <ListItemIcon>
                  <MaterialIcon icon="receipt" color="secondary" size={4} />
                </ListItemIcon>
                <ListItemText primary="My Subscription" />
              </ListItem>
              <Divider />
              <ListItem button component={RouterLink} to={ROUTES.BUSINESS_SETTINGS}>
                <ListItemIcon>
                  <MaterialIcon icon="business" color="secondary" size={4} />
                </ListItemIcon>
                <ListItemText primary="Business Settings" />
              </ListItem>
              <Divider />
            </>
          )}
          <ListItem button component={RouterLink} to={ROUTES.MY_SETTINGS}>
            <ListItemIcon>
              <MaterialIcon icon="cog" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <Divider />
          <ListItem
            button
            onClick={() => {
              auth.logOut().then(() => {
                history.push(ROUTES.LOGIN)
              })
            }}
          >
            <ListItemIcon>
              <MaterialIcon icon="logout" color="secondary" size={4} />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Container>
    </>
  )
}

export default MyAccount
