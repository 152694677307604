import React, { useState } from 'react'
import { Typography, Container, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, FastField } from 'formik'
import * as Yup from 'yup'
import { Alert } from '@material-ui/lab'
import Header from '../Header/Header'
import PasswordField from '../PasswordField/PasswordField'
import useAuth from '../../services/AuthProvider/useAuth'
import { PASSWORD_VALIDATION_MESSAGE } from '../../constants/settings'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
}))
const ChangePassword = () => {
  const classes = useStyles()
  const [message, setMessage] = useState(false)
  const auth = useAuth()
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .min(8, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[a-z]/, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[A-Z]/, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[0-9]/, PASSWORD_VALIDATION_MESSAGE)
      .notOneOf([Yup.ref('currentPassword'), null], 'New Password cannot be the same as your existing one')
      .required('New Password is required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
      .required('Password Confirmation is required'),
  })

  return (
    <>
      <Header title="Change Password" showBack />
      <Box bgcolor="primary.dark" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Please enter your new password below. {PASSWORD_VALIDATION_MESSAGE}
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box py={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={data => {
              auth
                .changePassword(data.currentPassword, data.newPassword)
                .then(() => {
                  setMessage({ type: 'success', message: 'Password changed successfully.' })
                  window.scrollTo(0, 0)
                })
                .catch(error => {
                  setMessage({ type: 'error', message: error.message })
                  window.scrollTo(0, 0)
                })
            }}
          >
            {({ errors, touched }) => (
              <Form>
                {message.type && <Alert severity={message.type}>{message.message}</Alert>}
                <FastField
                  id="currentPassword"
                  name="currentPassword"
                  label="Current Password"
                  color="secondary"
                  as={PasswordField}
                  error={errors.currentPassword && touched.currentPassword}
                  helperText={touched.currentPassword && errors.currentPassword}
                  margin="normal"
                  fullWidth
                />
                <FastField
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  color="secondary"
                  as={PasswordField}
                  error={errors.newPassword && touched.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                  margin="normal"
                  fullWidth
                />
                <FastField
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  color="secondary"
                  as={PasswordField}
                  error={errors.confirmNewPassword && touched.confirmNewPassword}
                  helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                  margin="normal"
                  fullWidth
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  className={classes.button}
                >
                  Change Password
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  )
}

export default ChangePassword
