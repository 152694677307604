import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Typography, Box, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import { formatInitials } from '../../../../services/formatters/formatters'
import SlickSlider from '../../../SlickSlider/SlickSlider'
import * as ROUTES from '../../../../constants/routes'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: '0 auto',
  },
}))
const FavouriteTrainersSlider = ({ trainers }) => {
  const classes = useStyles()

  return (
    <SlickSlider mode="standard">
      {trainers.map(trainer => {
        return (
          <Box key={trainer.id} textAlign="center">
            <Link component={RouterLink} to={`${ROUTES.TRAINER}/${trainer.id}`} underline="none">
              <Avatar src={trainer.profileImg} className={classes.avatar}>
                {formatInitials(trainer)}
              </Avatar>
              <Typography variant="h6" color="primary">
                {trainer.firstname}
              </Typography>
            </Link>
          </Box>
        )
      })}
    </SlickSlider>
  )
}

FavouriteTrainersSlider.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object),
}
FavouriteTrainersSlider.defaultProps = {
  trainers: [],
}

export default FavouriteTrainersSlider
