import React, { useState } from 'react'
import {
  TextField,
  Select,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { FastField, Field } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import regionData from '../../../../services/ultimateCountryData/data.json'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  flag: {
    marginRight: theme.spacing(1),
  },
}))
const PersonalForm = props => {
  const classes = useStyles()
  const { values, errors, touched, setFieldValue, setFieldTouched } = props
  const preselectedCountry = values.country ? regionData.filter(item => item.countryName === values.country)[0] : null
  const [country, setCountry] = useState(preselectedCountry)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FastField
            id="firstname"
            name="firstname"
            label="First Name"
            color="secondary"
            as={TextField}
            error={errors.firstname && touched.firstname}
            helperText={touched.firstname && errors.firstname}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FastField
            id="lastname"
            name="lastname"
            label="Last Name"
            color="secondary"
            as={TextField}
            error={errors.lastname && touched.lastname}
            helperText={touched.lastname && errors.lastname}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <FastField
        id="phone"
        name="phone"
        label="Mobile Phone"
        color="secondary"
        as={TextField}
        error={errors.phone && touched.phone}
        helperText={touched.phone && errors.phone}
        margin="normal"
        InputProps={{
          startAdornment: country ? <InputAdornment position="start">{`+${country.phone}`}</InputAdornment> : '',
        }}
        fullWidth
      />
      <FastField
        id="address1"
        name="address1"
        label="Address 1"
        color="secondary"
        as={TextField}
        error={errors.address1 && touched.address1}
        helperText={touched.address1 && errors.address1}
        margin="normal"
        fullWidth
      />
      <FastField
        id="address2"
        name="address2"
        label="Address 2"
        color="secondary"
        as={TextField}
        error={errors.address2 && touched.address2}
        helperText={touched.address2 && errors.address2}
        margin="normal"
        fullWidth
      />
      <FastField
        id="suburb"
        name="suburb"
        label="Suburb"
        color="secondary"
        as={TextField}
        error={errors.suburb && touched.suburb}
        helperText={touched.suburb && errors.suburb}
        margin="normal"
        fullWidth
      />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="register-state-select-label">State</InputLabel>
            <Field
              id="state"
              name="state"
              labelId="register-state-select-label"
              color="secondary"
              as={Select}
              error={!!(touched.state && errors.state)}
              onChange={event => {
                const selectedState = country.regions.filter(item => item.name === event.target.value)

                setFieldValue('state', event.target.value)
                setFieldValue('stateShort', selectedState[0].shortCode)
              }}
            >
              <MenuItem value="">Please select your Country first</MenuItem>
              {country &&
                country.regions.map(region => (
                  <MenuItem key={region.shortCode} value={region.name}>
                    {region.name} ({region.shortCode})
                  </MenuItem>
                ))}
            </Field>
            {touched.state && errors.state && <FormHelperText error>{errors.state}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FastField
            id="postcode"
            name="postcode"
            label="Postcode"
            color="secondary"
            as={TextField}
            error={errors.postcode && touched.postcode}
            helperText={touched.postcode && errors.postcode}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
      <Field id="stateShort" name="stateShort" type="hidden" />
      <Autocomplete
        id="country"
        name="country"
        options={regionData}
        getOptionLabel={option => option.countryName}
        onChange={(e, value) => {
          setCountry(value)
          setFieldTouched('phone', true)
          setFieldValue('country', value.countryName)
          setFieldValue('state', '')
          setFieldValue('stateShort', '')
        }}
        inputValue={values.country}
        getOptionSelected={(option, value) => option.countryName === value.countryName}
        renderOption={option => (
          <>
            <span className={classes.flag}>{option.emoji}</span> {option.countryName}
          </>
        )}
        renderInput={params => (
          <Field
            as={TextField}
            {...params}
            name="country"
            label="Country"
            margin="normal"
            fullWidth
            error={errors.country && touched.country}
            helperText={touched.country && errors.country}
          />
        )}
      />
      <Button type="submit" variant="contained" color="secondary" size="large" fullWidth className={classes.button}>
        Save Details
      </Button>
    </>
  )
}

PersonalForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
}

export default PersonalForm
