import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import SlickSlider from '../SlickSlider/SlickSlider'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import ExerciseDialog from '../ExerciseDialog/ExerciseDialog'

const useStyles = makeStyles({
  box: {
    cursor: 'pointer',
  },
})
const ExerciseTypeBar = ({ types }) => {
  const classes = useStyles()
  const [dialog, setDialog] = useState(false)
  const handleClose = () => {
    setDialog(false)
  }

  return (
    <>
      <SlickSlider mode="standard" showNav>
        {types.map(type => {
          const result = getExerciseType(type)

          return (
            <Box
              key={result.icon}
              textAlign="center"
              onClick={() => {
                setDialog(result)
              }}
              className={classes.box}
            >
              <MaterialIcon icon={result.icon} color="secondary" size={5} />
              <Typography color="primary" variant="subtitle2">
                {result.label}
              </Typography>
            </Box>
          )
        })}
      </SlickSlider>
      {dialog && <ExerciseDialog open={dialog !== false} {...dialog} handleClose={handleClose} />}
    </>
  )
}

ExerciseTypeBar.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ExerciseTypeBar
