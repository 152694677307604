import React from 'react'
import { TextField, Button, RadioGroup, Grid, InputLabel, FormControl, Radio, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { FastField } from 'formik'
import PasswordField from '../../../PasswordField/PasswordField'
import ToggleTileItem from '../../../ToggleTileItem/ToggleTileItem'

const useStyles = makeStyles(theme => ({
  labelWrapper: {
    display: 'block',
    margin: 0,
  },
  radioRoot: {
    width: '100%',
    display: 'block',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))
const AccountForm = props => {
  const classes = useStyles()
  const { errors, touched } = props

  return (
    <>
      <FormControl fullWidth margin="normal">
        <InputLabel id="register-account-type-selector" shrink>
          Account Type
        </InputLabel>
        <FastField id="accountType" name="accountType" as={RadioGroup}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Radio
                value="user"
                icon={<ToggleTileItem variant="dark" icon="account" title="User" />}
                checkedIcon={<ToggleTileItem variant="dark" icon="account" title="User" checked />}
                classes={{ root: classes.radioRoot }}
                disableRipple
              />
            </Grid>
            <Grid item xs={6}>
              <Radio
                value="trainer"
                icon={<ToggleTileItem variant="dark" icon="whistle" title="Trainer" />}
                checkedIcon={<ToggleTileItem variant="dark" icon="whistle" title="Trainer" checked />}
                classes={{ root: classes.radioRoot }}
                disableRipple
              />
            </Grid>
          </Grid>
        </FastField>
        {touched.accountType && errors.accountType && <FormHelperText error>{errors.accountType}</FormHelperText>}
      </FormControl>
      <FastField
        id="firstname"
        name="firstname"
        label="First Name"
        color="secondary"
        as={TextField}
        error={errors.firstname && touched.firstname}
        helperText={touched.firstname && errors.firstname}
        margin="normal"
        fullWidth
      />
      <FastField
        id="email"
        name="email"
        label="Email address"
        color="secondary"
        as={TextField}
        error={errors.email && touched.email}
        helperText={touched.email && errors.email}
        margin="normal"
        fullWidth
      />
      <FastField
        id="password"
        name="password"
        label="Password"
        color="secondary"
        as={PasswordField}
        error={errors.password && touched.password}
        helperText={touched.password && errors.password}
        margin="normal"
        fullWidth
      />
      <FastField
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password"
        color="secondary"
        as={PasswordField}
        error={errors.confirmPassword && touched.confirmPassword}
        helperText={touched.confirmPassword && errors.confirmPassword}
        margin="normal"
        fullWidth
      />
      <Button type="submit" variant="contained" color="secondary" size="large" fullWidth className={classes.button}>
        Create Account
      </Button>
    </>
  )
}

AccountForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
}

export default AccountForm
