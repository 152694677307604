import React, { useState, useEffect } from 'react'
import { Paper, Divider, Grid, Typography, Button, Chip, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Alert } from '@material-ui/lab'
import useAuth from '../../services/AuthProvider/useAuth'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import { formatCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import MiniCard from '../MiniCard/MiniCard'
import bookingStatusColor from '../../services/bookingStatusColor/bookingStatusColor'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  rowPadding: {
    padding: theme.spacing(2),
  },
  submitButton: {
    padding: theme.spacing(2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    margin: 0,
  },
  statusChip: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(1),
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}))
const BookingReceipt = ({ start, trainer, booking, status }) => {
  const classes = useStyles()
  const [message, setMessage] = useState(false)
  const [bookingData, setBookingData] = useState(booking)
  const auth = useAuth()
  const db = useDatabase()
  const safeBooked =
    typeof bookingData.booked.getMonth === 'function' ? bookingData.booked : bookingData.booked.toDate()
  const safeStart = typeof start.getMonth === 'function' ? start : start.toDate()

  useEffect(() => {
    if (status === 'cancelled') {
      setMessage({ type: 'warning', message: 'This booking has been cancelled.' })
      window.scrollTo(0, 0)
    }
  }, [status])

  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom>
        Booking Receipt
      </Typography>
      {message.type && (
        <Alert severity={message.type} className={classes.alert}>
          {message.message}
        </Alert>
      )}
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Booking Reference
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {bookingData.bookingId}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Booked When
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {format(safeBooked, 'dd/MM/yyyy @ hh:mm a')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Trainer&apos;s Business Name
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {trainer.tradingName}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              {trainer.country === 'Australia' ? 'ABN/ACN' : 'Company Number'}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {trainer.companyNumber}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Spaces Booked
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              1
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Cost
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {formatCurrency(bookingData.cost, trainer.country, true)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Status
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" justifyContent="flex-end">
              <Chip
                label={bookingData.status}
                size="small"
                className={classes.statusChip}
                color={bookingStatusColor(bookingData.status)}
              />
              {bookingData.updatedWhen && `(${format(bookingData.updatedWhen.toDate(), 'dd/MM/yyyy @ hh:mm a')})`}
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Payment Method
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" justifyContent="flex-end">
              <MiniCard issuer={bookingData.issuer} />
              <Typography variant="body2">&nbsp;&nbsp;(ending in {bookingData.panDigits})</Typography>
            </Box>
          </Grid>
        </Grid>
        {safeStart > new Date() && auth.user.uid === bookingData.userId && bookingData.status !== 'cancelled' && (
          <>
            <Divider />
            <Button
              variant="contained"
              size="large"
              fullWidth
              className={classes.submitButton}
              onClick={() => {
                db.cancelBooking(bookingData.bookingId, bookingData.sessionId).then(result => {
                  setMessage({
                    type: result.type,
                    message: result.message,
                  })
                  setBookingData({
                    ...booking,
                    ...result.bookingUpdates,
                  })
                })
              }}
            >
              Cancel Booking
            </Button>
          </>
        )}
      </Paper>
    </>
  )
}

BookingReceipt.propTypes = {
  start: PropTypes.any.isRequired,
  status: PropTypes.any,
  trainer: PropTypes.shape({
    tradingName: PropTypes.string.isRequired,
    companyNumber: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }).isRequired,
  booking: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    bookingId: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    booked: PropTypes.any.isRequired,
    status: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    issuer: PropTypes.string.isRequired,
    panDigits: PropTypes.string.isRequired,
    updatedWhen: PropTypes.any,
  }).isRequired,
}
BookingReceipt.defaultProps = {
  status: 'active',
}

export default BookingReceipt
