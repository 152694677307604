import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import randomImage from '../../services/randomImage/randomImage'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  title: {
    paddingBottom: 0,
  },
  label: {
    fontFamily: robotoFontStack,
    fontWeight: 500,
    verticalAlign: 'top',
    fontSize: '2rem',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))
const ExerciseDialog = props => {
  const classes = useStyles()
  const { label, icon, description, value, open, handleClose } = props
  const image = randomImage(value)

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.root }}>
      <ResponsiveImage src={image} width={1} height={250} />
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <MaterialIcon icon={icon} size={5} color="secondary" className={classes.icon} />
        <Typography variant="h2" color="primary" className={classes.label} display="inline">
          {label}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

ExerciseDialog.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
}

ExerciseDialog.defaultProps = {
  label: '',
  icon: '',
  value: '',
  description: '',
  open: false,
  handleClose: () => {},
}

export default ExerciseDialog
