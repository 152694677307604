import React from 'react'
import { Fab } from '@material-ui/core'
import { ChevronRight, ChevronLeft } from 'mdi-material-ui'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  arrows: {
    backgroundColor: theme.palette.background.default,
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    zIndex: 1,
  },
  prevArrow: {
    left: -theme.spacing(2),
  },
  nextArrow: {
    right: -theme.spacing(2),
  },
}))
const NextArrow = ({ className, onClick }) => {
  const classes = useStyles()

  return (
    <Fab size="small" className={classNames(classes.arrows, classes.nextArrow, className)} onClick={onClick}>
      <ChevronRight />
    </Fab>
  )
}

NextArrow.propTypes = {
  className: PropTypes.any,
  onClick: PropTypes.func,
}
NextArrow.defaultProps = {
  className: null,
  onClick: null,
}

const PrevArrow = ({ className, onClick }) => {
  const classes = useStyles()

  return (
    <Fab size="small" className={classNames(classes.arrows, classes.prevArrow, className)} onClick={onClick}>
      <ChevronLeft className={classes.arrowIcons} />
    </Fab>
  )
}

PrevArrow.propTypes = {
  className: PropTypes.any,
  onClick: PropTypes.func,
}
PrevArrow.defaultProps = {
  className: null,
  onClick: null,
}

export { NextArrow, PrevArrow }
