import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
const Loading = props => {
  const { width, height, iconColor, iconSize, bgColor } = props
  const classes = useStyles()

  return (
    <Box width={width} height={height} className={classes.root} bgcolor={bgColor}>
      <CircularProgress color={iconColor} size={iconSize} />
    </Box>
  )
}

Loading.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  iconColor: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  iconSize: PropTypes.any,
  bgColor: PropTypes.string,
}

Loading.defaultProps = {
  width: '100%',
  height: 50,
  iconColor: 'primary',
  iconSize: 40,
  bgColor: '',
}

export default Loading
