import { Paper, Box, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import ExerciseTypeBar from '../../../ExerciseTypeBar/ExerciseTypeBar'
import TrainerTagline from '../TrainerTagline/TrainerTagline'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.spacing(3),
    borderTopRightRadius: theme.spacing(3),
  },
}))
const TrainerMapDisplay = props => {
  const classes = useStyles()
  const { trainer, searchLat, searchLng } = props

  return (
    <Container maxWidth="sm" disableGutters>
      <Paper className={classes.root} square elevation={2}>
        <Box px={2} py={1}>
          <TrainerTagline trainer={trainer} searchLat={searchLat} searchLng={searchLng} showImage />
        </Box>
        <Box px={2} py={1} bgcolor="background.paper">
          <ExerciseTypeBar types={trainer.exerciseTypes} />
        </Box>
      </Paper>
    </Container>
  )
}

TrainerMapDisplay.propTypes = {
  trainer: PropTypes.object.isRequired,
  searchLat: PropTypes.number.isRequired,
  searchLng: PropTypes.number.isRequired,
}

export default TrainerMapDisplay
