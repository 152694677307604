import React from 'react'
import { Typography, Grid, InputAdornment, IconButton, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from '@material-ui/pickers'
import { format, add, sub } from 'date-fns'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import DateTimePickerTabs from '@material-ui/pickers/DateTimePicker/DateTimePickerTabs'
import DateFnsUtils from '@date-io/date-fns'
import { FastField } from 'formik'
import PropTypes from 'prop-types'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tabs: {
    backgroundColor: theme.palette.primary.dark,
    color: '#FFFFFF',
  },
  datepickerTitle: {
    marginBottom: theme.spacing(2),
  },
  highlight: {
    color: theme.palette.grey[500],
  },
  year: {
    fontFamily: robotoFontStack,
  },
  datepickerHeading: {
    fontFamily: robotoFontStack,
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  datepickerTimeHeading: {
    fontFamily: robotoFontStack,
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  timeButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))
const DateTimePickerField = props => {
  const classes = useStyles()
  const { name, label, disabled, dateOnly, ...other } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FastField
        name={name}
        label={label}
        disabled={disabled}
        {...other}
        as={dateOnly ? DatePicker : DateTimePicker}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary" disabled={disabled}>
                <MaterialIcon icon="calendar" size={3} color="inherit" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        ToolbarComponent={data => {
          const { date, openView, setOpenView, onChange } = data

          return (
            <>
              <Toolbar className={classes.toolbar}>
                <Grid container>
                  <Grid item xs>
                    <Typography color="secondary" variant="body2" className={classes.datepickerTitle}>
                      {label}
                    </Typography>
                    <Typography color="textPrimary" variant="h4" className={classes.year}>
                      {format(date, 'yyyy')}
                    </Typography>
                    <Typography variant="h3" className={classes.datepickerHeading}>
                      {format(date, 'iii, do LLL')}
                    </Typography>
                    {!dateOnly && (
                      <Typography variant="h4" color="textPrimary" className={classes.datepickerTimeHeading}>
                        {format(date, 'hh:mm a')}
                      </Typography>
                    )}
                  </Grid>
                  {!dateOnly && (
                    <Grid item xs={2} className={classes.timeButtons}>
                      <ToolbarButton
                        label="AM"
                        selected={format(date, 'a') === 'AM'}
                        onClick={() => {
                          if (format(date, 'a') === 'PM') {
                            onChange(sub(date, { hours: 12 }))
                          }
                        }}
                      />
                      <ToolbarButton
                        label="PM"
                        selected={format(date, 'a') === 'PM'}
                        onClick={() => {
                          if (format(date, 'a') === 'AM') {
                            onChange(add(date, { hours: 12 }))
                          }
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Toolbar>
              {!dateOnly && <DateTimePickerTabs view={openView} onChange={setOpenView} />}
            </>
          )
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

DateTimePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  dateOnly: PropTypes.bool,
}
DateTimePickerField.defaultProps = {
  dateOnly: false,
}

export default DateTimePickerField
