import { isNull } from 'util'
import { EXERCISE_TYPES } from '../../constants/settings'

const getExerciseType = (type, field = null) => {
  const result = EXERCISE_TYPES.filter(item => item.value === type)[0]

  if (!isNull(field)) {
    return result[field]
  }

  return result
}

export default getExerciseType
