import React from 'react'
import { TextField, Button, Link, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { FastField, Form, Formik } from 'formik'
import * as Yup from 'yup'
import PasswordField from '../PasswordField/PasswordField'
import * as ROUTES from '../../constants/routes'

import useAuth from '../../services/AuthProvider/useAuth'

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: `${theme.spacing(2)}px 0`,
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: theme.spacing(5),
  },
}))
const LogIn = () => {
  const classes = useStyles()
  const initialValues = {
    username: '',
    password: '',
  }
  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Value must be a valid Email address').required('Email address is required'),
    password: Yup.string().required('Password is required'),
  })
  const history = useHistory()
  const auth = useAuth()

  if (auth.user && 'uid' in auth.user) {
    history.push(ROUTES.HOME)
  }

  return (
    <Box height="100vh">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width={1} height={1}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={1}
          px={2}
          flexGrow={1}
        >
          <Box>
            <Link component={RouterLink} to={ROUTES.LANDING}>
              <img src="images/logo/findfit_logo_coloured_dark_bg.svg" alt="Find Fit" className={classes.logo} />
            </Link>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, formikBag) => {
              auth
                .logIn(data.username, data.password)
                .then(() => {
                  history.push(ROUTES.HOME)
                })
                .catch(error => {
                  switch (error.code) {
                    case 'auth/user-not-found':
                      formikBag.setFieldError(
                        'username',
                        'The username you have entered is incorrect or does not exist.',
                      )
                      break
                    default:
                    case 'auth/wrong-password':
                      formikBag.setFieldError('password', 'The password you have entered is incorrect.')
                      break
                  }
                })
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <FastField
                  id="username"
                  name="username"
                  label="Email address"
                  color="secondary"
                  as={TextField}
                  error={errors.username && touched.username}
                  helperText={touched.username && errors.username}
                  margin="normal"
                  fullWidth
                />
                <FastField
                  id="password"
                  name="password"
                  label="Password"
                  color="secondary"
                  as={PasswordField}
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                  margin="normal"
                  fullWidth
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  margin="normal"
                  className={classes.button}
                >
                  Log In
                </Button>
              </Form>
            )}
          </Formik>
          <Link component={RouterLink} to={ROUTES.FORGOT_PASSWORD} color="textSecondary" variant="body1">
            Forgot Password?
          </Link>
        </Box>
        <Box bgcolor="primary.dark" p={3} alignSelf="flex-end" width={1} justifyContent="center" display="flex">
          <Link component={RouterLink} to={ROUTES.SIGNUP} variant="body1" color="secondary">
            Don&apos;t have an account? Sign up Now
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default LogIn
