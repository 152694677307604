import React from 'react'
import { Box, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { OverlayView } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials } from '../../services/formatters/formatters'

const useStyles = makeStyles(theme => ({
  marker: {
    cursor: 'pointer',
  },
  markerBackground: {},
  markerAvatar: {
    position: 'absolute',
    top: theme.spacing(1 / 2),
    left: theme.spacing(1 / 2),
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    fontSize: '1rem',
  },
  icon: {
    width: '38px',
    height: '54px',
  },
}))
const setPixelPositionOffset = (width, height) => {
  return { x: -(width / 2), y: -height }
}

const AvatarMarker = props => {
  const classes = useStyles()
  const { user, color, onClick, position } = props

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={setPixelPositionOffset}
      position={{
        lat: position.latitude ? position.latitude : user.latitude,
        lng: position.longitude ? position.longitude : user.longitude,
      }}
    >
      <Box className={classes.marker} onClick={onClick}>
        <MaterialIcon icon="map-marker" color={color} size={8} className={classes.icon} viewBox="5 2 14 20" />
        {user && (
          <Avatar src={user && user.profileImg ? user.profileImg : null} className={classes.markerAvatar}>
            {formatInitials(user)}
          </Avatar>
        )}
      </Box>
    </OverlayView>
  )
}

AvatarMarker.propTypes = {
  color: PropTypes.string,
  user: PropTypes.any,
  onClick: PropTypes.func,
  position: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
}
AvatarMarker.defaultProps = {
  user: false,
  color: 'primary',
  onClick: () => {},
  position: {
    latitude: 0,
    longitude: 0,
  },
}

export default AvatarMarker
