import React, { useState } from 'react'
import { TextField, Box, Container, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, FastField, Form } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'
import PasswordField from '../PasswordField/PasswordField'
import { PASSWORD_VALIDATION_MESSAGE } from '../../constants/settings'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))
const ForgotPassword = props => {
  const classes = useStyles()
  const { code } = props
  const history = useHistory()
  const auth = useAuth()
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState()
  const [codeVerifiedEmail, setCodeVerifiedEmail] = useState('')
  const initialValues = {
    email: '',
    newPassword: '',
  }
  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
  })
  const passwordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[a-z]/, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[A-Z]/, PASSWORD_VALIDATION_MESSAGE)
      .matches(/[0-9]/, PASSWORD_VALIDATION_MESSAGE)
      .required('Password is required'),
  })
  const handleSubmit = (data, formikBag) => {
    if (code !== '') {
      auth
        .confirmPasswordReset(code, data.newPassword)
        .then(() => {
          auth.logIn(codeVerifiedEmail, data.newPassword).then(() => {
            history.push(ROUTES.HOME)
          })
        })
        .catch(result => {
          setError(result.message)
        })
    } else {
      auth
        .resetPassword(data.email)
        .then(() => {
          setSubmitted(true)
        })
        .catch(result => {
          formikBag.setFieldError('email', result.message)
        })
    }
  }

  if (code !== '') {
    auth
      .verifyResetCode(code)
      .then(email => {
        setCodeVerifiedEmail(email)
      })
      .catch(result => {
        setError(result.message)
        setCodeVerifiedEmail('')
        setSubmitted(false)
      })
  }

  return (
    <>
      <Box bgcolor="primary.dark" p={3}>
        <Container maxWidth="sm">
          <Typography variant="h3" color="secondary" gutterBottom>
            Forgot Password
          </Typography>
          <Typography variant="body2" color="textPrimary" gutterBottom>
            {codeVerifiedEmail === '' &&
              "Please enter your email address below and we'll send you a link to reset your password."}
            {codeVerifiedEmail !== '' &&
              `Resetting the password for the account with email address: ${codeVerifiedEmail}.`}
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box px={1}>
          <Formik
            initialValues={initialValues}
            validationSchema={!submitted && code === '' ? emailValidationSchema : passwordValidationSchema}
            onSubmit={(data, formikBag) => handleSubmit(data, formikBag)}
          >
            {({ errors, touched }) => (
              <Form>
                {!submitted && code === '' && (
                  <>
                    {error && (
                      <Alert severity="error" className={classes.alert}>
                        {error}
                      </Alert>
                    )}
                    <FastField
                      id="email"
                      name="email"
                      label="Email address"
                      color="secondary"
                      as={TextField}
                      error={errors.email && touched.email}
                      helperText={touched.email && errors.email}
                      margin="normal"
                      fullWidth
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      className={classes.button}
                    >
                      Send Email
                    </Button>
                  </>
                )}
                {submitted && (
                  <Alert severity="success" className={classes.alert}>
                    Please check your email and click the link to reset your password.
                  </Alert>
                )}
                {code !== '' && (
                  <>
                    <FastField
                      id="newPassword"
                      name="newPassword"
                      label="New Password"
                      color="secondary"
                      as={PasswordField}
                      error={errors.newPassword && touched.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                      margin="normal"
                      fullWidth
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      className={classes.button}
                    >
                      Reset Password
                    </Button>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  )
}

ForgotPassword.propTypes = {
  code: PropTypes.string,
}

ForgotPassword.defaultProps = {
  code: '',
}

export default ForgotPassword
