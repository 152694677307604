import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import _ from 'lodash'
import { PrevArrow, NextArrow } from './components/SlickArrows/SlickArrows'
import 'slick-carousel/slick/slick.css'
import './SlickSlider.css'

const useStyles = makeStyles(theme => ({
  standard: {
    position: 'relative',
    width: '100%',
  },
  standardSingle: {
    width: '100%',
  },
  peekRight: {
    width: '100%',
  },
  darkArrows: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))
const SlickSlider = ({ mode, children, showNav, initialSlide, darkMode }) => {
  const classes = useStyles()
  const theme = useTheme()
  const baseSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    nextArrow: <NextArrow className={darkMode ? classes.darkArrows : ''} />,
    prevArrow: <PrevArrow className={darkMode ? classes.darkArrows : ''} />,
    initialSlide,
  }
  const variantSettings = {
    standard: {
      slidesToShow: 4,
    },
    standardSingle: {
      slidesToShow: 1,
    },
    standardResponsive: {
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: theme.breakpoints.values.md,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
    peekRight: {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: theme.spacing(5),
      responsive: [
        {
          breakpoint: theme.breakpoints.values.sm,
          settings: {
            centerPadding: theme.spacing(3),
          },
        },
      ],
    },
  }
  const settings = _.merge(baseSettings, variantSettings[mode])

  if (showNav) {
    settings.arrows = true
  }

  return (
    <Slider {...settings} className={classes[mode]}>
      {children}
    </Slider>
  )
}

SlickSlider.propTypes = {
  mode: PropTypes.oneOf(['standard', 'standardSingle', 'standardResponsive', 'peekRight']),
  children: PropTypes.node.isRequired,
  showNav: PropTypes.bool,
  initialSlide: PropTypes.number,
  darkMode: PropTypes.bool,
}
SlickSlider.defaultProps = {
  mode: 'standard',
  showNav: false,
  initialSlide: 0,
  darkMode: false,
}

export default SlickSlider
