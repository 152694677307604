import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, Button, Paper, Divider, Grid, Avatar, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import MiniCard from '../MiniCard/MiniCard'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatInitials, formatFullName } from '../../services/formatters/formatters'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import { formatCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import * as ROUTES from '../../constants/routes'
import bookingStatusColor from '../../services/bookingStatusColor/bookingStatusColor'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3),
  },
  paperNote: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  row: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
  },
  rowTop: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'flex-start',
  },
  avatar: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
  },
  address: {
    whiteSpace: 'pre',
  },
  termsCheckbox: {
    display: 'inline',
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    marginRight: 0,
  },
  cost: {
    fontSize: '1.5rem',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  statusChip: {
    textTransform: 'capitalize',
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
}))
const BookingConfirmation = () => {
  const classes = useStyles()
  const { bookingId } = useParams()
  const auth = useAuth()
  const db = useDatabase()
  const [session, setSession] = useState(false)
  const [booking, setBooking] = useState(false)
  const [user, setUser] = useState(false)
  const [message, setMessage] = useState(false)

  useEffect(() => {
    db.getBooking(bookingId).then(result => {
      setBooking(result)
      db.getSession(result.sessionId).then(data => {
        setSession(data)
      })
      db.getUsersByIds([result.userId]).then(data => {
        setUser(data[0])
      })
    })
  }, [auth, db, bookingId])

  return (
    session && (
      <>
        <Header title="View Booking" showBack />
        <Box bgcolor="background.paper" py={3}>
          <Container maxWidth="sm">
            <Typography variant="body1" color="textPrimary">
              Information about the users booking.
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="sm">
          {message.type && (
            <Alert severity={message.type} className={classes.topMargin}>
              {message.message}
            </Alert>
          )}
          {session && (
            <Box py={2}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Session Details
              </Typography>

              <Paper className={classes.paper}>
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Session
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {session.title}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Exercise Type
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                      <MaterialIcon icon={getExerciseType(session.exerciseType, 'icon')} color="secondary" size={3} />
                      <Typography variant="body2" display="inline">
                        &nbsp;{getExerciseType(session.exerciseType, 'label')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Start Time
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {format(session.start.toDate(), 'd LLLL yyyy @ hh:mm a')}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Finish Time
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {format(session.finish.toDate(), 'd LLLL yyyy @ hh:mm a')}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}
          {booking && (
            <Box pb={2}>
              <Typography variant="h3" color="secondary" gutterBottom>
                Booking Details
              </Typography>
              <Paper className={classes.paper}>
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Booking Reference
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {booking.id}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Attendee
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Avatar src={user.profileImg} className={classes.avatar}>
                          {formatInitials(user)}
                        </Avatar>
                        {formatFullName(user)}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Booked When
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {format(booking.booked.toDate(), 'd LLLL yyyy @ hh:mm a')}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Cost
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {formatCurrency(booking.cost, auth.business.country, true)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Payment Method
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      <Box display="flex" justifyContent="flex-end">
                        <MiniCard issuer={booking.issuer} />
                        <Typography variant="body2">&nbsp;&nbsp;(ending in {booking.panDigits})</Typography>
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Status
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      <Chip
                        label={booking.status}
                        size="small"
                        color={bookingStatusColor(booking.status)}
                        className={classes.statusChip}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      Last Updated
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {typeof booking.updatedWhen !== 'undefined' &&
                        format(booking.updatedWhen.toDate(), 'd LLLL yyyy @ hh:mm a')}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          )}

          {booking && booking.status === 'cancelled' ? (
            <Button
              variant="contained"
              size="large"
              fullWidth
              className={classes.button}
              color="primary"
              onClick={() => {
                db.reactivateBooking(booking.id, booking.sessionId).then(result => {
                  setMessage(result)
                })
              }}
            >
              Reactivate Booking
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              fullWidth
              className={classes.button}
              color="secondary"
              onClick={() => {
                db.cancelBooking(booking.id, booking.sessionId).then(result => {
                  setMessage(result)
                })
              }}
            >
              Cancel Booking
            </Button>
          )}
          <Button
            variant="contained"
            href={`${ROUTES.SESSION}/${booking.sessionId}`}
            size="large"
            fullWidth
            className={classes.button}
          >
            Back to Session
          </Button>
        </Container>
      </>
    )
  )
}

export default BookingConfirmation
