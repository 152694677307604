const features = {
  user: [
    {
      icon: 'dashboard',
      heading: 'Dashboard',
      text: 'Quickly access your upcoming sessions and favourite trainers from the dashboard.',
      imageSrc: '/images/screenshots/userdashboard.png',
    },
    {
      icon: 'star',
      heading: 'Favourite Trainers',
      text: 'Add a trainer to your favourites for easy access to their sessions and availability.',
      imageSrc: '/images/screenshots/favouritetrainers.png',
    },
    {
      icon: 'distance',
      heading: 'Search via Distance',
      text: 'Search for trainers within a certain distance of your location or specified address.',
      imageSrc: '/images/screenshots/search.png',
    },
    {
      icon: 'credit-card',
      heading: 'Automated Credit Card Payments',
      text: 'All payments are charged to your credit card at the start of a session.',
      imageSrc: '/images/screenshots/creditcard.png',
    },
    {
      icon: 'calendar',
      heading: 'Calendar Session Layout',
      text: 'When looking at a Trainers profile, easily find sessions for a specific date using the calendar selector.',
      imageSrc: '/images/screenshots/calendardisplay.png',
    },
  ],
  trainer: [
    {
      icon: 'repeat',
      heading: 'Recurring Sessions',
      text: 'Configure recurring sessions that occur on a regular basis. For example, every Wednesday at 6am.',
      imageSrc: '/images/screenshots/recurringsessions.png',
    },
    {
      icon: 'table-eye',
      heading: 'Attendees List',
      text: 'Easily seee how many people have booked into your session along with their names and faces.',
      imageSrc: '/images/screenshots/attendeelist.png',
    },
  ],
}

export default features
