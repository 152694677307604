import React from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import * as ROUTES from '../../constants/routes'
import { findFitThemePublic } from '../../services/findFitTheme/findFitTheme'

const AuthAction = ({ location }) => {
  const history = useHistory()
  const params = queryString.parse(location.search)

  switch (params.mode) {
    default:
      history.push(ROUTES.LANDING)

      return false
    case 'resetPassword':
      return (
        <ThemeProvider theme={findFitThemePublic}>
          <CssBaseline />
          <ForgotPassword code={params.oobCode} />
        </ThemeProvider>
      )
    case 'verifyEmail':
      return <>Verfiy Email Component</>
  }
}

AuthAction.propTypes = {
  location: PropTypes.object.isRequired,
}

export default AuthAction
