import React from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteWithTheme = props => {
  const { theme, component, ...other } = props
  const Component = component

  return (
    <Route
      {...other}
      // eslint-disable-next-line no-shadow
      render={props => (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Component {...props} />
        </ThemeProvider>
      )}
    />
  )
}

RouteWithTheme.propTypes = {
  theme: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
}

export default RouteWithTheme
