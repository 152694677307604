import React, { useState, useEffect } from 'react'
import {
  TextField,
  Typography,
  Container,
  Fab,
  Box,
  IconButton,
  InputAdornment,
  Toolbar,
  Paper,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { add, sub, isToday, isTomorrow, isYesterday, isPast, format, startOfDay } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import useAuth from '../../services/AuthProvider/useAuth'
import Header from '../Header/Header'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import * as ROUTES from '../../constants/routes'
import TrainerClassTile from '../ClassTile/TrainerClassTile'
import Loading from '../Loading/Loading'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  dateField: {
    marginBottom: theme.spacing(3),
  },
  textFieldContent: {
    textAlign: 'center',
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  tile: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  datepickerTitle: {
    marginBottom: theme.spacing(2),
  },
  year: {
    fontFamily: robotoFontStack,
  },
  datepickerHeading: {
    fontFamily: robotoFontStack,
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#FFFFFF',
  },
  iconButton: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  },
  iconButtonCal: {
    marginRight: theme.spacing(1),
  },
  textField: {
    borderRadius: theme.shape.borderRadius,
  },
}))
const TrainerDashboard = () => {
  const classes = useStyles()
  const auth = useAuth()
  const db = useDatabase()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [dateLabel, setDateLabel] = useState('Today')
  const [sessions, setSessions] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isToday(selectedDate)) {
      setDateLabel('Todays Sessions')
    } else if (isTomorrow(selectedDate)) {
      setDateLabel('Tomorrows Sessions')
    } else if (isYesterday(selectedDate)) {
      setDateLabel('Yesterdays Sessions')
    } else if (isPast(selectedDate) && !isYesterday(selectedDate)) {
      setDateLabel('Past Sessions')
    } else {
      setDateLabel('Upcoming Sessions')
    }

    db.getSessionsByDate(selectedDate).then(docs => {
      setSessions(
        docs.map(doc => {
          return {
            sessionId: doc.id,
            ...doc.data(),
          }
        }),
      )
      setLoading(false)
    })
  }, [selectedDate, auth.user.uid, db])

  return (
    <>
      <Header title="Dashboard" />
      <Container maxWidth="sm">
        <Box py={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="selectedDate"
              value={selectedDate}
              onChange={date => {
                setSelectedDate(date)
              }}
              format="E, d LLL yyyy"
              autoOk
              className={classes.dateField}
              TextFieldComponent={props => {
                // eslint-disable-next-line react/prop-types
                const { onClick } = props

                return (
                  <TextField
                    {...props}
                    variant="filled"
                    margin="normal"
                    fullWidth
                    color="primary"
                    onClick={e => {
                      if (e.target.id === 'selectedDate') {
                        onClick()
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                      className: classes.textField,
                      startAdornment: (
                        <InputAdornment position="start" style={{ marginTop: 0 }}>
                          <IconButton
                            onClick={() => {
                              setSelectedDate(sub(selectedDate, { days: 1 }))
                            }}
                            className={classes.iconButton}
                          >
                            <MaterialIcon icon="arrow-left" size={3} color="primary" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              onClick()
                            }}
                            className={classes.iconButtonCal}
                          >
                            <MaterialIcon icon="calendar" size={3} color="primary" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setSelectedDate(add(selectedDate, { days: 1 }))
                            }}
                            className={classes.iconButton}
                          >
                            <MaterialIcon icon="arrow-right" size={3} color="primary" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      className: classes.textFieldContent,
                    }}
                  />
                )
              }}
              ToolbarComponent={data => {
                const { date } = data

                return (
                  <>
                    <Toolbar className={classes.toolbar}>
                      <Typography color="secondary" variant="body2" className={classes.datepickerTitle}>
                        Show Sessions for Date
                      </Typography>
                      <Typography color="textPrimary" variant="h4" className={classes.year}>
                        {format(date, 'yyyy')}
                      </Typography>
                      <Typography variant="h3" className={classes.datepickerHeading}>
                        {format(date, 'iii, do LLL')}
                      </Typography>
                    </Toolbar>
                  </>
                )
              }}
            />
          </MuiPickersUtilsProvider>
          <Typography variant="h3" color="secondary" paragraph gutterBottom>
            {dateLabel}
          </Typography>
          {!loading ? (
            <>
              {sessions.length > 0 ? (
                sessions.map(session => <TrainerClassTile key={session.id} {...session} className={classes.tile} />)
              ) : (
                <Paper className={classes.paper}>
                  There are no sessions scheduled for this date
                  {selectedDate >= startOfDay(new Date()) && (
                    <>
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        color="secondary"
                        href={`${ROUTES.ADD_SESSION}?date=${format(selectedDate, 'yyyy-LL-dd')}`}
                        size="large"
                        startIcon={<MaterialIcon icon="plus" color="inherit" size={3} />}
                      >
                        Add a Session
                      </Button>
                    </>
                  )}
                </Paper>
              )}
            </>
          ) : (
            <Loading height={223} />
          )}
        </Box>
      </Container>
      <Fab color="secondary" aria-label="Add new Session" href={ROUTES.ADD_SESSION} className={classes.fab}>
        <MaterialIcon icon="plus" color="inherit" size={3} />
      </Fab>
    </>
  )
}

export default TrainerDashboard
