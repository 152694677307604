import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  button: {
    fontFamily: robotoFontStack,
    textTransform: 'capitalize',
    fontSize: theme.spacing(2),
    color: theme.palette.text.secondary,
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '&:focus': {
      borderColor: theme.palette.secondary.main,
    },
  },
  buttonAdded: {
    fontFamily: robotoFontStack,
    textTransform: 'none',
    color: '#FFFFFF',
    fontSize: theme.spacing(2),
  },
  existingDoc: {
    fontFamily: robotoFontStack,
    textTransform: 'none',
    fontSize: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))
const FileField = props => {
  const classes = useStyles()
  const { id, name, accept, label, multiple, value, setFieldValue } = props
  const [buttonLabel, setButtonLabel] = useState(label)
  const [filesAdded, setFilesAdded] = useState(false)
  const [existing, setExisting] = useState(value)

  return (
    <div className={classes.root}>
      <input
        accept={accept}
        className={classes.input}
        id={id}
        name={name}
        multiple={multiple}
        type="file"
        onChange={event => {
          setExisting(false)
          setButtonLabel(
            Array.from(event.currentTarget.files)
              .map(file => file.name)
              .join(', '),
          )
          setFieldValue(name, Array.from(event.currentTarget.files))
          setFilesAdded(true)
        }}
      />
      {existing && (
        <Button
          variant="contained"
          color="primary"
          href={value}
          fullWidth
          startIcon={<MaterialIcon icon="pdf" color="inherit" size={2} />}
          target="_blank"
          className={classes.existingDoc}
        >
          View Current Insurance Document
        </Button>
      )}
      <label htmlFor={id}>
        <Button
          variant="outlined"
          color="primary"
          component="span"
          fullWidth
          classes={{ root: filesAdded ? classes.buttonAdded : classes.button }}
          startIcon={<MaterialIcon icon="upload" color="inherit" size={2} />}
        >
          {buttonLabel}
        </Button>
      </label>
    </div>
  )
}

FileField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  multiple: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
}

FileField.defaultProps = {
  multiple: false,
}

export default FileField
