import React from 'react'
import { Typography, Paper, Button, Divider, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import * as ROUTES from '../../constants/routes'
import { formatCurrency, getCurrency } from '../../services/ultimateCountryData/ultimateCountryData'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  rowPadding: {
    padding: theme.spacing(2),
  },
  submitButton: {
    padding: theme.spacing(2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    margin: 0,
  },
  spacesInput: {
    textAlign: 'center',
  },
  spacesLabel: {
    paddingTop: theme.spacing(0.5),
  },
}))
const BookingCart = ({ start, finish, suburb, state, cost, country, sessionId }) => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom>
        Book this Session
      </Typography>
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Date
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {format(start, 'd LLLL yyyy')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Time
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {format(start, 'hh:mm a')} - {format(finish, 'hh:mm a')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Location
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" align="right">
              {suburb}, {state}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} className={classes.rowPadding}>
          <Grid item xs>
            <Typography variant="body1" color="primary">
              Cost
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" align="right" color="primary">
              <strong>{`${formatCurrency(cost, country)} ${getCurrency(country)}`}</strong>
            </Typography>
          </Grid>
        </Grid>
        {start > new Date() && (
          <Button
            href={`${ROUTES.BOOKING_CONFIRM}/${sessionId}`}
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            className={classes.submitButton}
          >
            Book this Session
          </Button>
        )}
      </Paper>
    </>
  )
}

BookingCart.propTypes = {
  start: PropTypes.object.isRequired,
  finish: PropTypes.object.isRequired,
  suburb: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  sessionId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

export default BookingCart
