import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import useAuthProvider from '../useAuthProvider/useAuthProvider'

const AuthContext = createContext(null)
const AuthProvider = props => {
  const { children } = props
  const auth = useAuthProvider()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
AuthProvider.defaultProps = {
  children: {},
}

export default AuthProvider

export { AuthContext }
