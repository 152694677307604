export const LANDING = '/'
export const PRIVACY = '/privacy-policy'
export const AUTH_ACTION = '/auth/action'
export const SIGNUP = '/signup'
export const FORGOT_PASSWORD = '/forgot/password'
export const LOGIN = '/login'
export const HOME = '/dashboard'
export const ACCOUNT = '/account'
export const CHANGE_PASSWORD = '/account/password'
export const MY_DETAILS = '/account/details'
export const MY_PAYMENT_METHOD = '/account/payment'
export const MY_SETTINGS = '/account/settings'
export const SEARCH = '/search'
export const TRAINER = '/trainer'

export const BUSINESS_SETTINGS = '/account/business'

export const ADD_SESSION = '/session/add'
export const SESSION = '/session/view'
export const EDIT_SESSION = '/session/edit'

export const BOOKING_CONFIRM = '/booking/confirm'
export const BOOKING_EDIT = '/booking/edit'
export const BOOKING_VIEW = '/booking/view'
