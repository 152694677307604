import { Avatar, Typography, Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MapMarker } from 'mdi-material-ui'
import PropTypes from 'prop-types'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'
import FavouriteButton from '../FavouriteButton/FavouriteButton'
import { formatDistance, formatInitials } from '../../../../services/formatters/formatters'
import calculateDistance from '../../../../services/calculateDistance/calculateDistance'
import useAuth from '../../../../services/AuthProvider/useAuth'
import * as ROUTES from '../../../../constants/routes'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  iconText: {
    verticalAlign: 'top',
    fontWeight: 400,
  },
  favColumn: {
    textAlign: 'right',
  },
  name: {
    fontSize: '1.75rem',
  },
}))
const TrainerTagline = ({ trainer, searchLat, searchLng, showImage }) => {
  const classes = useStyles()
  const { id, firstname, profileImg, suburb, stateShort, latitude, longitude } = trainer
  const initials = formatInitials(trainer)
  const auth = useAuth()
  const { distanceUnits } = auth.userData.settings
  let distance = calculateDistance(latitude, longitude, searchLat, searchLng, distanceUnits)
  const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'))

  distance = formatDistance(distance, distanceUnits)

  return (
    <Grid container spacing={2} alignItems="center" justify="center">
      {showImage && (
        <Grid item>
          <Link component={RouterLink} to={`${ROUTES.TRAINER}/${id}`}>
            <Avatar alt={initials} className={classes.avatar} src={profileImg}>
              {initials}
            </Avatar>
          </Link>
        </Grid>
      )}
      <Grid item xs>
        <Link component={RouterLink} to={`${ROUTES.TRAINER}/${id}`} underline="none">
          <Typography variant="h5" color="primary" className={classes.name}>
            {firstname}
          </Typography>
        </Link>
        <MapMarker color="secondary" className={classes.icon} />
        <Typography variant="subtitle1" color="textPrimary" display="inline" className={classes.iconText}>
          {suburb}, {stateShort}
          {!smUp && showImage && (
            <>
              <br />
              <Typography variant="caption" component="span" color="textPrimary" className={classes.iconText}>
                {distance}
              </Typography>
            </>
          )}
        </Typography>
      </Grid>
      <Grid item className={classes.favColumn}>
        <FavouriteButton trainerId={id} />
        {(smUp || (!smUp && !showImage)) && (
          <Typography variant="caption" color="textPrimary" display="block" align="right" className={classes.iconText}>
            {distance}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

TrainerTagline.propTypes = {
  trainer: PropTypes.object.isRequired,
  searchLat: PropTypes.number,
  searchLng: PropTypes.number,
  showImage: PropTypes.bool,
}
TrainerTagline.defaultProps = {
  showImage: false,
  searchLat: 0,
  searchLng: 0,
}

export default TrainerTagline
