import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import Header from '../Header/Header'
import SearchForm from './components/SearchForm/SearchForm'
import SearchResults from './components/SearchResults/SearchResults'
import useDatabase from '../../services/DatabaseProvider/useDatabase'

const SearchPage = () => {
  const db = useDatabase()
  const history = useHistory()
  const [trainers, setTrainers] = useState(false)
  const [parsedUrl, setParsedUrl] = useState(
    queryString.parse(history.location.search, {
      arrayFormat: 'comma',
      parseNumbers: true,
      parseBooleans: true,
    }),
  )

  useEffect(() => {
    history.listen(location => {
      const newParsedUrl = queryString.parse(location.search, {
        arrayFormat: 'comma',
        parseNumbers: true,
        parseBooleans: true,
      })

      setParsedUrl(newParsedUrl)
    })

    if (Object.keys(parsedUrl).length > 0) {
      db.searchTrainers(parsedUrl).then(results => {
        setTrainers(results)
      })
    }
  }, [parsedUrl, db, history])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header title="Search" showBack />

      {trainers ? (
        <SearchResults trainers={trainers} latitude={parsedUrl.latitude} longitude={parsedUrl.longitude} />
      ) : (
        <SearchForm noResults={trainers.length === 0 || trainers === false} />
      )}
    </div>
  )
}

export default SearchPage
