export const DEFAULT_UNITS = 'km'
export const PASSWORD_VALIDATION_MESSAGE =
  'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter and one number'
export const EXERCISE_TYPES = [
  {
    value: 'cardio',
    label: 'Cardio',
    icon: 'run',
    description:
      'Cardio exercise is any exercise that raises your heart rate. Face it our bodies were made to move. And we all know that to keep our muscles in shape we need move them. This movement makes them stronger and stronger muscles make for a more efficient and healthy body. Your heart is a muscle. Therefore working it makes it stronger. A stronger cardio-vascular system means more capillaries delivering more oxygen to cells in your muscles. This enables your cells to burn more fat during both exercise and inactivity.',
  },
  {
    value: 'strength',
    label: 'Strength',
    icon: 'dumbbell',
    description:
      'Strength training can be defined as an exercise type that is designed to increase lean muscle tissue, improve structural strength, decrease excess body fat, increase endurance, and provide several additional physical and psychological benefits.',
  },
  {
    value: 'hiit',
    label: 'HIIT',
    icon: 'timer',
    description:
      'High-intensity interval training (HIIT) is a form of interval training, a cardiovascular exercise strategy alternating short periods of intense anaerobic exercise with less intense recovery periods, until too exhausted to continue. Though there is no universal HIIT session duration, these intense workouts typically last under 30 minutes, with times varying based on a participant&apos;s current fitness level.',
  },
  {
    value: 'spin',
    label: 'Spin',
    icon: 'bike',
    description:
      'Indoor cycling, often also called spinning, as an organized sport , is a form of exercise with classes focusing on endurance, strength, intervals, high intensity (race days) and recovery, and involves using a special stationary exercise bicycle with a weighted flywheel in a classroom setting.',
  },
  {
    value: 'power',
    label: 'Power',
    icon: 'weight-lifter',
    description:
      'Power training typically involves exercises which apply the maximum amount of force as fast as possible; on the basis that strength + speed = power. Jumping with weights or throwing weights are two examples of power training exercises.',
  },
  {
    value: 'yoga',
    label: 'Yoga',
    icon: 'yoga',
    description:
      'On the physical level, yoga postures, called asanas, are designed to tone, strengthen, and align the body. These postures are performed to make the spine supple and healthy and to promote blood flow to all the organs, glands, and tissues, keeping all the bodily systems healthy.',
  },
  {
    value: 'boxing',
    label: 'Boxing',
    icon: 'boxing-glove',
    description:
      'Boxing is an exercise class based on the training concepts boxers use to keep fit. Classes can take a variety of formats but a typical one may involve shadow-boxing, skipping, hitting pads, kicking punchbags, press-ups, shuttle-runs and sit-ups.',
  },
  {
    value: 'bootcamp',
    label: 'Bootcamp',
    icon: 'shoe-print',
    description:
      'Boot camp workouts can vary. They generally include a fairly intense mix of aerobic, strength training and speed elements within each class session. ... A boot camp workout is essentially a type of interval training — bursts of intense activity alternated with intervals of lighter activity.',
  },
]
export const DISTANCE_VALUES = [5, 10, 15, 20, 30, 40, 50]
export const SESSION_TYPE_VALUES = [
  { value: '1on1', label: '1 on 1' },
  { value: 'semiprivate', label: 'Semi Private' },
  { value: 'group', label: 'Group' },
]
export const SESSION_LENGTH_VALUES = [
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '60 mins' },
]
