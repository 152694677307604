import { DEFAULT_UNITS } from '../../constants/settings'
/**
 *
 * @param {number} lat1 - Latitude of the first set of co-ordinates.
 * @param {number} long1 - Longitude of the first set of co-ordinates.
 * @param {number} lat2 - Latitude of the second set of co-ordindates.
 * @param {number} long2 - Longitude of the second set of co-ordinates.
 * @param {string} units - Kilometres (km) or Miles (mi)
 *
 * @return {number} - The distance in the relative format.
 */
const calculateDistance = (lat1, long1, lat2, long2, units = DEFAULT_UNITS) => {
  if (lat1 === lat2 && long1 === long2) {
    return 0
  }

  const earthRad = 6371
  const x1 = lat2 - lat1
  const dLat = (x1 * Math.PI) / 180
  const x2 = long2 - long1
  const dLong = (x2 * Math.PI) / 180
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = earthRad * c

  if (units === 'mi') {
    return d / 1.60934
  }

  return d
}

export default calculateDistance
