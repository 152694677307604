import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    width: '100%',
    textAlign: 'center',
    display: 'block',
    margin: 0,
    marginBottom: theme.spacing(0.75),
    border: '1px solid rgba(0,0,0,0.42)',
    boxShadow: theme.shadows[4],
  },
  icon: {
    display: 'block',
    margin: `0 auto ${theme.spacing(1)}px auto`,
  },
  label: {
    display: 'block',
    margin: '0 auto',
  },
  wrapperLight: {
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.background.default,
  },
  wrapperLightSelected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  wrapperDark: {
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.main,
  },
  wrapperDarkSelected: {
    borderColor: theme.palette.text.primary,
    backgroundColor: theme.palette.text.primary,
  },
  labelLight: {
    color: theme.palette.text.primary,
  },
  labelLightSelected: {
    color: theme.palette.background.default,
  },
  labelDark: {
    color: theme.palette.text.secondary,
  },
  labelDarkSelected: {
    color: theme.palette.primary.main,
  },
  wrapperDisabled: {
    borderColor: '#CCCCCC',
    backgroundColor: theme.palette.background.default,
  },
  labelDisabled: {
    color: '#CCCCCC',
  },
}))
const ToggleTileItem = props => {
  const classes = useStyles()
  const { checked, variant, title, subtitle, icon } = props
  let wrapperVariant
  let labelVariant

  switch (variant) {
    case 'dark':
      wrapperVariant = checked ? classes.wrapperDarkSelected : classes.wrapperDark
      labelVariant = checked ? classes.labelDarkSelected : classes.labelDark
      break
    case 'disabled':
      wrapperVariant = classes.wrapperDisabled
      labelVariant = classes.labelDisabled
      break
    default:
    case 'light':
      wrapperVariant = checked ? classes.wrapperLightSelected : classes.wrapperLight
      labelVariant = checked ? classes.labelLightSelected : classes.labelLight
      break
  }

  return (
    <Box className={classNames(classes.wrapper, wrapperVariant)}>
      {icon !== '' && <MaterialIcon icon={icon} color={variant === 'disabled' ? 'inherit' : 'secondary'} />}
      <Typography variant="body2" component="span" className={classNames(classes.label, labelVariant)}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="caption" component="span" color="textSecondary" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

ToggleTileItem.propTypes = {
  checked: PropTypes.bool,
  variant: PropTypes.oneOf(['dark', 'light', 'disabled']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
}

ToggleTileItem.defaultProps = {
  checked: false,
  variant: 'light',
  subtitle: '',
  icon: '',
}

export default ToggleTileItem
