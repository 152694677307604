import PropTypes from 'prop-types'
import { DEFAULT_UNITS } from '../../../constants/settings'

const formatDistance = (distance, units = DEFAULT_UNITS) => `${distance.toFixed(1)} ${units} from you`

formatDistance.propTypes = {
  distance: PropTypes.number.isRequired,
  units: PropTypes.string,
}

export default formatDistance
