import { useState, useEffect, useCallback } from 'react'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/firebase-storage'
import useFirebase from '../useFirebase/useFirebase'
import { DEFAULT_UNITS } from '../../constants/settings'

const useAuthProvider = () => {
  const firebase = useFirebase()
  const auth = firebase.auth()
  const db = firebase.firestore()
  const storage = firebase.storage().ref()
  const [user, setUser] = useState(auth.currentUser)
  const [userData, setUserData] = useState({
    accountType: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    stateShort: '',
    postcode: '',
    country: '',
    latitude: 0,
    longitude: 0,
    settings: {
      distanceUnits: DEFAULT_UNITS,
    },
    defaultSearch: {},
  })
  const [business, setBusiness] = useState()
  const getUserFromDatabase = useCallback(
    uid =>
      db
        .collection('users')
        .doc(uid)
        .get()
        .then(doc => {
          return doc.data()
        }),
    [db],
  )
  const logIn = (email, password) =>
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      auth.signInWithEmailAndPassword(email, password).then(response => {
        setUser(response.user)
        getUserFromDatabase(response.user.uid).then(data => setUserData(data))

        return response.user
      })
    })
  const logOut = () =>
    auth.signOut().then(() => {
      setUser(false)
      setUserData(null)
      setBusiness(null)
    })
  const sendVerificationEmail = userRef =>
    userRef.sendEmailVerification().then(() => {
      return true
    })
  const signUp = data =>
    auth.createUserWithEmailAndPassword(data.email, data.password).then(response => {
      setUser(response.user)

      return db
        .collection('users')
        .doc(response.user.uid)
        .set({
          email: data.email,
          firstname: data.firstname,
          accountType: data.accountType,
        })
        .then(() => {
          setUserData({
            firstname: data.firstname,
            email: data.email,
            accountType: data.accountType,
          })
          // sendVerificationEmail(response.user)

          return response.user
        })
    })
  const resetPassword = email =>
    auth.sendPasswordResetEmail(email).then(() => {
      return true
    })
  const verifyResetCode = code => auth.verifyPasswordResetCode(code)
  const confirmPasswordReset = (code, password) =>
    auth.confirmPasswordReset(code, password).then(() => {
      return true
    })
  const changePassword = (password, newPassword) =>
    auth.currentUser
      .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, password))
      .then(() => {
        auth.currentUser.updatePassword(newPassword).then(() => {
          return true
        })
      })
  const updateUser = data => {
    const batch = db.batch()
    const userRef = db.collection('users').doc(auth.currentUser.uid)

    batch.update(userRef, {
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      address1: data.address1,
      address2: data.address2,
      suburb: data.suburb,
      state: data.state,
      stateShort: data.stateShort,
      postcode: data.postcode,
      country: data.country,
      latitude: data.latitude,
      longitude: data.longitude,
    })

    if (userData.accountType === 'trainer') {
      const trainerRef = db.collection('trainers').doc(auth.currentUser.uid)

      batch.set(
        trainerRef,
        {
          firstname: data.firstname,
          lastname: data.lastname,
          address1: data.address1,
          address2: data.address2,
          suburb: data.suburb,
          state: data.state,
          stateShort: data.stateShort,
          postcode: data.postcode,
          country: data.country,
          latitude: data.latitude,
          longitude: data.longitude,
        },
        { merge: true },
      )
    }

    return batch.commit().then(() => {
      setUserData({
        ...userData,
        ...data,
      })

      return true
    })
  }

  const getUserCreditCard = id =>
    db
      .collection('cards')
      .where('uid', '==', id || auth.currentUser.uid)
      .limit(1)
      .get()
      .then(snap => {
        if (snap.docs.length === 0) {
          return false
        }

        return {
          id: snap.docs[0].id,
          ...snap.docs[0].data(),
        }
      })
  const updateUserSettings = settings =>
    db
      .collection('users')
      .doc(auth.currentUser.uid)
      .update({ settings })
      .then(() => {
        return true
      })
  const addCreditCard = data => {
    const { creditCardNumber, creditCardName, creditCardExpiry, creditCardCCV, issuer, cardId } = data
    const cardData = {
      name: creditCardName,
      pan: `${creditCardNumber.substr(0, 4)}********${creditCardNumber.substr(-4, 4)}`,
      expiry: creditCardExpiry,
      token: 'result from stripe',
      uid: auth.currentUser.uid,
      issuer,
    }
    let cardRef

    if (cardId === '') {
      cardRef = db.collection('cards').doc()
    } else {
      cardRef = db.collection('cards').doc(cardId)
    }

    // Some stripe stuff here.
    return cardRef.set(cardData, { merge: true }).then(() => {
      return cardData
    })
  }

  const saveDefaultSearch = data =>
    db
      .collection('users')
      .doc(auth.currentUser.uid)
      .set(
        {
          defaultSearch: data,
        },
        { merge: true },
      )
      .then(() => {
        return true
      })
  const uploadFile = (uid, path, fileData) => {
    const uploadTask = storage.child(`${uid}/${path}`).put(fileData)

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

          console.log(`Progress ${progress}%`)
          if (snapshot.state === firebase.storage.TaskState.RUNNING) {
            console.log('file uploading...')
          }
        },
        error => reject(error.message),
        async () => {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

          resolve(downloadURL)
        },
      )
    })
  }

  const uploadProfileImage = file => {
    let ext

    switch (file.type) {
      default:
      case 'image/jpeg':
        ext = 'jpg'
        break
      case 'image/png':
        ext = 'png'
        break
    }

    const profileRef = storage.child(
      `${auth.currentUser.uid}/images/${auth.currentUser.uid}_${file.lastModified}.${ext}`,
    )

    profileRef.put(file).then(() => {
      profileRef.getDownloadURL().then(downloadURL => {
        const batch = db.batch()
        const userRef = db.collection('users').doc(auth.currentUser.uid)

        batch.update(userRef, { profileImg: downloadURL })

        if (userData.accountType === 'trainer') {
          const trainerRef = db.collection('trainers').doc(auth.currentUser.uid)

          batch.update(trainerRef, { profileImg: downloadURL })
        }

        batch.commit().then(() => {
          setUserData({
            ...userData,
            profileImg: downloadURL,
          })
        })
      })
    })
  }

  const getBusinessSettings = useCallback(
    () =>
      db
        .collection('trainers')
        .doc(auth.currentUser.uid)
        .get()
        .then(doc => doc.data()),
    [auth.currentUser, db],
  )
  const updateBusinessSettings = async data => {
    let insuranceURL = data.insurance
    let termsURL = data.terms

    if (data.insurance !== false && Array.isArray(data.insurance) && typeof data.insurance[0].name === 'string') {
      insuranceURL = await uploadFile(
        auth.currentUser.uid,
        `docs/${auth.currentUser.uid}_${data.insurance[0].name}`,
        data.insurance[0],
      )
    }

    if (data.terms !== false && Array.isArray(data.terms) && typeof data.terms[0].name === 'string') {
      termsURL = await uploadFile(
        auth.currentUser.uid,
        `docs/${auth.currentUser.uid}_${data.terms[0].name}`,
        data.terms[0],
      )
    }

    return db
      .collection('trainers')
      .doc(auth.currentUser.uid)
      .set(
        {
          ...data,
          firstname: userData.firstname,
          lastname: userData.lastname,
          profileImg: userData.profileImg,
          insurance: insuranceURL,
          terms: termsURL,
        },
        { merge: true },
      )
      .then(() => {
        setBusiness(data)

        return true
      })
  }

  const updateFavouriteTrainers = (trainerId, action = 'add') => {
    const favTrainers = 'favouriteTrainers' in userData ? userData.favouriteTrainers : []

    if (action === 'add') {
      favTrainers.push(trainerId)
    } else if (action === 'remove') {
      favTrainers.splice(favTrainers.indexOf(trainerId), 1)
    }

    return db
      .collection('users')
      .doc(auth.currentUser.uid)
      .update({ favouriteTrainers: favTrainers })
      .then(() => {
        setUserData({
          ...userData,
          favouriteTrainers: favTrainers,
        })

        return true
      })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(result => {
      if (result) {
        setUser(result)
        getUserFromDatabase(result.uid).then(data => {
          setUserData(data)
          if (data && data.accountType === 'trainer') {
            getBusinessSettings().then(settings => {
              setBusiness(settings)
            })
          }
        })
      } else {
        setUser(null)
        setUserData(null)
        setBusiness(null)
      }
    })

    return () => unsubscribe()
  }, [auth, getBusinessSettings, getUserFromDatabase])

  return {
    user,
    userData,
    business,
    logIn,
    logOut,
    signUp,
    resetPassword,
    confirmPasswordReset,
    changePassword,
    updateUser,
    updateUserSettings,
    verifyResetCode,
    sendVerificationEmail,
    getUserCreditCard,
    addCreditCard,
    saveDefaultSearch,
    uploadProfileImage,
    getBusinessSettings,
    updateBusinessSettings,
    updateFavouriteTrainers,
  }
}

export default useAuthProvider
