/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import React, { useState, useEffect } from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { parseISO } from 'date-fns'
import Header from '../Header/Header'
import useAuth from '../../services/AuthProvider/useAuth'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import * as ROUTES from '../../constants/routes'
import SessionForm from '../SessionForm/SessionForm'

const SessionAdd = ({ location }) => {
  const auth = useAuth()
  const db = useDatabase()
  const history = useHistory()
  const parsedUrl = queryString.parse(location.search)
  const initialDate = parsedUrl.date ? parseISO(parsedUrl.date) : new Date()
  const [existingSessions, setExistingSessions] = useState(false)
  const initialValues = {
    start: initialDate,
    finish: initialDate,
    recurring: false,
    recurringFrequency: '',
    recurringDays: [],
    recurringUntil: initialDate,
    title: '',
    description: '',
    exerciseType: '',
    headerImage: '',
    spaces: 1,
    cost: 0,
    accessible: false,
    status: 'active',
  }
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Session Title is required'),
    start: Yup.date()
      .min(new Date(), 'Start Time must be in the future')
      .test('startOverlap', 'Cannot schedule a session when you already have an existing one at this time.', value => {
        if (existingSessions.length >= 1) {
          const result = existingSessions.filter(session => {
            return value >= session.start.toDate() && value < session.finish.toDate()
          })

          return result.length === 0
        }

        return true
      })
      .required('Start Time is required'),
    finish: Yup.date()
      .test('afterStart', 'Finish Time must occur after Start Time', function (value) {
        return value > this.resolve(Yup.ref('start'))
      })
      .test('finishOverlap', 'Cannot schedule a session when you already have an existing one at this time.', value => {
        if (existingSessions.length >= 1) {
          const result = existingSessions.filter(session => {
            return value >= session.start.toDate() && value < session.finish.toDate()
          })

          return result.length === 0
        }

        return true
      })
      .required('Finish Time is required'),
    recurringFrequency: Yup.string().when('recurring', {
      is: true,
      then: Yup.string().required('Recurring Frequency is required'),
    }),
    recurringDays: Yup.array().when('recurringFrequency', {
      is: 'daily',
      then: Yup.array().required('Recurring Frequency is required'),
    }),
    recurringUntil: Yup.date().test('recurringUntil', 'Please enter a date after the Finish Time.', function (value) {
      if (this.resolve(Yup.ref('recurring')) === true) {
        return value > this.resolve(Yup.ref('finish'))
      }

      return true
    }),
    exerciseType: Yup.string().required('Exercise Type is required'),
    headerImage: Yup.string().required('Session Banner Image is required'),
    spaces: Yup.number().min(1, 'At least one space is required').required('Spaces Available is required'),
  })
  const handleSubmit = data => {
    db.addSession(auth.user.uid, data).then(sessionId => {
      if (data.recurring) {
        history.push(ROUTES.HOME)
      } else {
        history.push(`${ROUTES.SESSION}/${sessionId}`)
      }
    })
  }

  useEffect(() => {
    db.getAllTrainerSessions(auth.user.uid).then(result => {
      setExistingSessions(result)
    })
  }, [db, auth.user])

  return (
    <>
      <Header title="Add Session" showBack />
      <Box bgcolor="background.paper" py={3}>
        <Container maxWidth="sm">
          <Typography variant="body1" color="textPrimary">
            Add a new training session.
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Box pt={1} pb={4}>
          <SessionForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            submitButtonLabel="Add Session"
            existingSessions={existingSessions}
          />
        </Box>
      </Container>
    </>
  )
}

SessionAdd.propTypes = {
  location: PropTypes.any.isRequired,
}

export default SessionAdd
