import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '100%',
    height: props => props.height,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.background.paper,
    backgroundImage: props => `url(${props.src})`,
  },
}))
const ResponsiveImage = ({ src, height, className }) => {
  const classes = useStyles({ src, height })

  return <Box className={classNames(classes.root, className)} />
}

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.any,
}
ResponsiveImage.defaultProps = {
  className: null,
}

export default ResponsiveImage
