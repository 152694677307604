import React from 'react'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

const MaskedTextField = props => {
  const { mask, maskPlaceholder, inputRef, ...other } = props

  return (
    <InputMask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
      maskPlaceholder={maskPlaceholder}
    />
  )
}

MaskedTextField.propTypes = {
  mask: PropTypes.any.isRequired,
  maskPlaceholder: PropTypes.any.isRequired,
  inputRef: PropTypes.func.isRequired,
}

export default MaskedTextField
