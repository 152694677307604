import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import useDbProvider from '../useDbProvider/useDbProvider'

const DatabaseContext = createContext(null)
const DatabaseProvider = props => {
  const { children } = props
  const auth = useDbProvider()

  return <DatabaseContext.Provider value={auth}>{children}</DatabaseContext.Provider>
}

DatabaseProvider.propTypes = {
  children: PropTypes.node,
}
DatabaseProvider.defaultProps = {
  children: {},
}

export default DatabaseProvider

export { DatabaseContext }
