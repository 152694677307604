import React from 'react'
import { FormControl, FormGroup, Box, FormHelperText, InputLabel, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FastField } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import { imageSubset } from '../../services/randomImage/randomImage'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import SlickSlider from '../SlickSlider/SlickSlider'

const useStyles = makeStyles(theme => ({
  radioRoot: {
    width: '100%',
    display: 'block',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  typeWrapper: {
    marginTop: theme.spacing(3),
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    border: props => (props.mode === 'dark' ? `2px solid ${theme.palette.primary.main}` : '2px solid #B5B5B5'),
  },
  imageSelected: {
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  imageWrapper: {
    position: 'relative',
  },
  imageIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
  },
  imageIconUnselected: {
    color: theme.palette.primary.main,
  },
  imageDisabled: {
    borderRadius: theme.shape.borderRadius,
    border: `2px solid #CCCCCC`,
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'rgba(255,255,255,0.7)',
      borderRadius: theme.shape.borderRadius,
    },
  },
}))
const ImageSelectorField = props => {
  const { id, name, label, value, subset, error, touched, setFieldValue, mode, disabled } = props
  const classes = useStyles({ mode })
  const images = imageSubset(subset)
  const imageIndex = images.indexOf(value)

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel shrink error={!!(touched && error)} disabled={disabled}>
        {label}
      </InputLabel>
      <FastField id={id} as={FormGroup} className={classes.typeWrapper} disabled={disabled}>
        <SlickSlider mode="standardSingle" showNav initialSlide={imageIndex === -1 ? 0 : imageIndex} darkMode>
          {images.map(image => (
            <Box key={image}>
              <Radio
                value={image}
                name={name}
                icon={
                  <Box className={classes.imageWrapper} width="100%">
                    <ResponsiveImage
                      width="100%"
                      height={200}
                      src={image}
                      className={disabled ? classes.imageDisabled : classes.image}
                    />
                    <MaterialIcon
                      icon="checkbox-marked-circle"
                      color={disabled ? 'inherit' : 'secondary'}
                      size={3}
                      className={classNames(classes.imageIcon, classes.imageIconUnselected)}
                    />
                  </Box>
                }
                checkedIcon={
                  <Box className={classes.imageWrapper} width="100%">
                    <ResponsiveImage
                      width="100%"
                      height={200}
                      src={image}
                      className={disabled ? classes.imageDisabled : classes.imageSelected}
                    />
                    <MaterialIcon
                      icon="checkbox-marked-circle"
                      color={disabled ? 'disabled' : 'secondary'}
                      size={3}
                      className={classes.imageIcon}
                    />
                  </Box>
                }
                classes={{ root: classes.radioRoot }}
                disableRipple
                checked={value === image}
                disabled={disabled}
                onClick={event => {
                  setFieldValue('headerImage', event.target.value)
                }}
              />
            </Box>
          ))}
        </SlickSlider>
      </FastField>
      <FormHelperText disabled={disabled}>Swipe left/right to choose an image and tap on it to select.</FormHelperText>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

ImageSelectorField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subset: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
}
ImageSelectorField.defaultProps = {
  error: '',
  touched: false,
  subset: '',
  mode: 'light',
  disabled: false,
}

export default ImageSelectorField
