import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import './findFitTheme.css'

const bourbonFontStack = ['BourbonGrotesque', 'Roboto', 'Arial', '-apple-system', 'sans-serif'].join(',')
const robotoFontStack = ['Roboto', 'Arial', '-apple-system', 'sans-serif'].join(',')
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2C3E50',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8BC53F',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#F2F2F2',
      default: '#FFFFFF',
    },
    text: {
      primary: '#828282',
      secondary: '#2C3E50',
      disabled: '#CCCCCC',
      hint: '#CCCCCC',
    },
  },
  typography: {
    fontFamily: robotoFontStack,
    h1: {
      fontSize: '3rem',
      fontFamily: bourbonFontStack,
    },
    h2: {
      fontSize: '2rem',
      fontFamily: bourbonFontStack,
    },
    h3: {
      fontSize: '1.2rem',
      fontFamily: bourbonFontStack,
    },
    h4: {
      fontSize: '1rem',
      fontFamily: bourbonFontStack,
    },
    h5: {
      fontSize: '2rem',
      fontFamily: robotoFontStack,
      fontWeight: 500,
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: bourbonFontStack,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#8BC53F',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: robotoFontStack,
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
})
const themePublic = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: '#2C3E50',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8BC53F',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#1B1F29',
      default: '#2C3E50',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#828282',
    },
  },
  overrides: {
    MuiStepIcon: {
      completed: {
        color: '#8BC53F !important',
      },
    },
    MuiButton: {
      root: {
        fontFamily: bourbonFontStack,
      },
    },
  },
})
const darkTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: '#2C3E50',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8BC53F',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#2C3E50',
      default: '#1B1F29',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#828282',
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#2C3E50',
      },
    },
    MuiButton: {
      root: {
        fontFamily: bourbonFontStack,
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#1B1F29',
        },
      },
    },
  },
})
const findFitTheme = responsiveFontSizes(theme)
const findFitThemePublic = responsiveFontSizes(themePublic)
const findFitThemeDark = responsiveFontSizes(darkTheme)

export { findFitTheme, findFitThemePublic, findFitThemeDark, bourbonFontStack, robotoFontStack }
