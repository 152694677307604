import React, { useState, useMemo, useEffect } from 'react'
import { Grid, TextField, InputAdornment, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLoadScript } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import throttle from 'lodash/throttle'
import parse from 'autosuggest-highlight/parse'
import { Field } from 'formik'
import MaterialIcon from '../MaterialIcon/MaterialIcon'

const gmapLibraries = ['places']
const geocoder = { current: null }
const autocompleteService = { current: null }
const useStyles = makeStyles(theme => ({
  autocompleteIcon: {
    marginRight: theme.spacing(2),
  },
}))
const GoogleMapsAutocompleteField = props => {
  const classes = useStyles()
  const { errors, touched, setFieldValue, id, name, label, defaultValue } = props
  const [locationValue, setLocationValue] = useState(defaultValue)
  const [options, setOptions] = useState([])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: gmapLibraries,
  })
  const handleLocationChange = e => {
    setLocationValue(e.target.value)
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && typeof window.google !== 'undefined') {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }

    if (!geocoder.current && window.google) {
      geocoder.current = new window.google.maps.Geocoder()
    }

    if (!autocompleteService.current) {
      return undefined
    }

    if (!geocoder.current) {
      return undefined
    }

    if (locationValue === '') {
      setOptions([])

      return undefined
    }

    fetch({ input: locationValue }, results => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [locationValue, fetch])

  return (
    isLoaded && (
      <>
        <Autocomplete
          id={id}
          name={name}
          options={options}
          defaultValue={defaultValue}
          getOptionLabel={option =>
            typeof option === 'string' ? option : `${option.terms[0].value}, ${option.terms[1].value}`
          }
          filterOptions={x => x}
          autoComplete
          onChange={(e, value) => {
            geocoder.current.geocode({ placeId: value.place_id }, (responses, status) => {
              if (status === 'OK') {
                setFieldValue('latitude', responses[0].geometry.location.lat())
                setFieldValue('longitude', responses[0].geometry.location.lng())
              }
            })
            setFieldValue(name, value.structured_formatting.main_text)
          }}
          disableClearable
          forcePopupIcon={false}
          renderOption={option => {
            const matches = option.structured_formatting.main_text_matched_substrings
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map(match => [match.offset, match.offset + match.length]),
            )

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <MaterialIcon icon="map-marker" size={3} color="primary" className={classes.autocompleteIcon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textPrimary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              name={name}
              onChange={handleLocationChange}
              error={errors && touched}
              helperText={touched && errors}
              margin="normal"
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <MaterialIcon icon="map-marker" color="secondary" size={3} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {typeof navigator !== 'undefined' && navigator.geolocation && (
                      <IconButton
                        onClick={navigator.geolocation.getCurrentPosition(position => {
                          setFieldValue('latitude', position.coords.latitude)
                          setFieldValue('longitude', position.coords.longitude)
                        })}
                      >
                        <MaterialIcon icon="gps" color="primary" size={2.5} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Field id="latitude" name="latitude" type="hidden" />
        <Field id="longitude" name="longitude" type="hidden" />
      </>
    )
  )
}

GoogleMapsAutocompleteField.propTypes = {
  errors: PropTypes.any,
  touched: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

GoogleMapsAutocompleteField.defaultProps = {
  errors: false,
  touched: false,
  defaultValue: '',
}

export default GoogleMapsAutocompleteField
