import React from 'react'
import {
  Box,
  TextField,
  Checkbox,
  Select,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemText,
  Switch,
  InputLabel,
  InputAdornment,
  MenuItem,
  Button,
  Typography,
  Grid,
  Container,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form, FastField } from 'formik'
import { Alert } from '@material-ui/lab'
import * as Yup from 'yup'
import _ from 'lodash'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import MaterialIcon from '../../../MaterialIcon/MaterialIcon'
import ToggleTileItem from '../../../ToggleTileItem/ToggleTileItem'
import * as ROUTES from '../../../../constants/routes'
import GoogleMapsAutocompleteField from '../../../GoogleMapsAutocompleteField/GoogleMapsAutocompleteField'
import useAuth from '../../../../services/AuthProvider/useAuth'
import {
  DEFAULT_UNITS,
  EXERCISE_TYPES,
  DISTANCE_VALUES,
  SESSION_LENGTH_VALUES,
  SESSION_TYPE_VALUES,
} from '../../../../constants/settings'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  controlLabel: {
    position: 'relative',
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    '&:before': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      pointerEvents: 'none',
    },
    '&:after': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      transform: 'scaleX(0)',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      pointerEvents: 'none',
    },
    '&:hover:after': {
      transform: 'scaleX(1)',
    },
  },
  accessibilityLabel: {
    flexGrow: 1,
    display: 'inline-flex',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  radioRoot: {
    width: '100%',
    display: 'block',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  typeWrapper: {
    marginTop: theme.spacing(3),
  },
}))
const SearchForm = ({ noResults }) => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()
  const preferredUnits =
    auth.userData && 'settings' in auth.userData ? auth.userData.settings.distanceUnits : DEFAULT_UNITS
  let initialValues = {
    exerciseType: '',
    location: '',
    latitude: 0,
    longitude: 0,
    distance: 10,
    sessionLength: [],
    sessionType: [],
    accessible: false,
    saveSearch: false,
    units: preferredUnits,
  }
  const validationSchema = Yup.object().shape({
    exerciseType: Yup.string().required('ExerciseType is required. Please select an option.'),
    location: Yup.string().required('Location is required.'),
    latitude: Yup.number().required('Location is required.'),
    longitude: Yup.number().required('Location is required.'),
    distance: Yup.number().positive().integer().required('Distance is required'),
  })

  if (auth.userData && 'defaultSearch' in auth.userData) {
    initialValues = _.merge(initialValues, auth.userData.defaultSearch)
  }

  if (history.location.search.length > 0 && noResults) {
    window.scrollTo(0, 0)
  }

  return (
    <Container maxWidth="sm">
      <Box pt={1} py={4}>
        {history.location.search.length > 0 && noResults && (
          <Alert severity="warning">We&apos;re sorry. There are no sessions that match your search.</Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, formikBag) => {
            if (data.saveSearch) {
              auth
                .saveDefaultSearch({
                  exerciseType: data.exerciseType,
                  location: data.location,
                  latitude: data.latitude,
                  longitude: data.longitude,
                  distance: data.distance,
                  sessionLength: data.sessionLength,
                  sessionType: data.sessionType,
                  accessible: data.accessible,
                })
                .then(() => {
                  history.push(`${ROUTES.SEARCH}?${queryString.stringify(data, { arrayFormat: 'comma' })}`)
                })
                .catch(error => {
                  formikBag.setFieldError('saveSearch', error.message)
                })
            } else {
              history.push(`${ROUTES.SEARCH}?${queryString.stringify(data, { arrayFormat: 'comma' })}`)
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
            <Form>
              <FormControl fullWidth margin="normal">
                <InputLabel shrink error={!!(touched.exerciseType && errors.exerciseType)}>
                  Exercise Type
                </InputLabel>
                <FastField id="exerciseType" as={RadioGroup} className={classes.typeWrapper}>
                  <Grid container spacing={1}>
                    {EXERCISE_TYPES.map(type => (
                      <Grid item xs={3} key={type.value}>
                        <Radio
                          id={type.value}
                          name="exerciseType"
                          value={type.value}
                          icon={<ToggleTileItem variant="light" icon={type.icon} title={type.label} />}
                          checkedIcon={<ToggleTileItem variant="light" icon={type.icon} title={type.label} checked />}
                          classes={{ root: classes.radioRoot }}
                          disableRipple
                          checked={values.exerciseType.indexOf(type.value) > -1}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FastField>
                {touched.exerciseType && errors.exerciseType && (
                  <FormHelperText error>{errors.exerciseType}</FormHelperText>
                )}
              </FormControl>
              <GoogleMapsAutocompleteField
                id="location"
                name="location"
                label="Location"
                setFieldValue={setFieldValue}
                defaultValue={values.location}
                errors={errors.location}
                touched={touched.location}
              />
              <FastField
                id="distance"
                name="distance"
                label="Distance"
                color="primary"
                as={TextField}
                error={errors.distance && touched.distance}
                helperText={touched.distance && errors.distance}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MaterialIcon icon="distance" color="secondary" size={3} />
                    </InputAdornment>
                  ),
                }}
                select
                margin="normal"
                fullWidth
              >
                {DISTANCE_VALUES.map((distance, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={index} value={distance}>
                    {distance} {preferredUnits}
                  </MenuItem>
                ))}
              </FastField>
              <FormControl fullWidth margin="normal">
                <InputLabel error={!!(touched.sessionLength && errors.sessionLength)}>Session Length</InputLabel>
                <FastField
                  id="sessionLength"
                  name="sessionLength"
                  color="primary"
                  as={Select}
                  multiple
                  error={errors.length && touched.length}
                  renderValue={selected => {
                    const selectedLabels = selected.map(term => {
                      const filteredResult = SESSION_LENGTH_VALUES.filter(item => item.value === term)

                      return filteredResult[0].label
                    })

                    return selectedLabels.join(', ')
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <MaterialIcon icon="clock-start" color="secondary" size={3} />
                    </InputAdornment>
                  }
                >
                  {SESSION_LENGTH_VALUES.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      <Checkbox checked={values.sessionLength.indexOf(type.value) > -1} color="primary" />
                      <ListItemText primary={type.label} />
                    </MenuItem>
                  ))}
                </FastField>
                {touched.sessionLength && errors.sessionLength && (
                  <FormHelperText error>{errors.sessionLength}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel error={!!(touched.sessionType && errors.sessionType)}>Session Type</InputLabel>
                <FastField
                  id="sessionType"
                  name="sessionType"
                  color="primary"
                  as={Select}
                  multiple
                  error={errors.sessionType && touched.sessionType}
                  renderValue={selected => {
                    const selectedLabels = selected.map(term => {
                      const filteredResult = SESSION_TYPE_VALUES.filter(item => item.value === term)

                      return filteredResult[0].label
                    })

                    return selectedLabels.join(', ')
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <MaterialIcon icon="tune" color="secondary" size={3} />
                    </InputAdornment>
                  }
                >
                  {SESSION_TYPE_VALUES.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      <Checkbox checked={values.sessionType.indexOf(type.value) > -1} color="primary" />
                      <ListItemText primary={type.label} />
                    </MenuItem>
                  ))}
                </FastField>
                {touched.sessionType && errors.sessionType && (
                  <FormHelperText error>{errors.sessionType}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="search-accessible-label" shrink>
                  Accessibility
                </InputLabel>
                <FormControlLabel
                  id="accessible"
                  name="accessible"
                  control={
                    <Switch color="secondary" onChange={handleChange} onBlur={handleBlur} checked={values.accessible} />
                  }
                  label={
                    <>
                      <InputAdornment position="start">
                        <MaterialIcon icon="wheelchair" color="secondary" size={3} />
                      </InputAdornment>
                      <Typography variant="body1">Show Accessible Trainers</Typography>
                    </>
                  }
                  labelPlacement="start"
                  classes={{ labelPlacementStart: classes.controlLabel, label: classes.accessibilityLabel }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="search-save-label" shrink>
                  Default Search
                </InputLabel>
                <FormControlLabel
                  id="saveSearch"
                  name="saveSearch"
                  control={
                    <Switch color="secondary" onChange={handleChange} onBlur={handleBlur} checked={values.saveSearch} />
                  }
                  label={
                    <>
                      <InputAdornment position="start">
                        <MaterialIcon icon="save" color="secondary" size={3} />
                      </InputAdornment>
                      <Typography variant="body1">Save filters as Default Search</Typography>
                    </>
                  }
                  labelPlacement="start"
                  classes={{ labelPlacementStart: classes.controlLabel, label: classes.accessibilityLabel }}
                />
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                className={classes.button}
              >
                Search
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}

SearchForm.propTypes = {
  noResults: PropTypes.bool,
}
SearchForm.defaultProps = {
  noResults: false,
}

export default SearchForm
