import * as Yup from 'yup'
import { parse } from 'date-fns'

const creditCardSchema = Yup.object().shape({
  creditCardName: Yup.string().required('Name on Card is required'),
  creditCardNumber: Yup.string()
    .transform((value, originalValue) => {
      return originalValue.replace(/-/g, '').trim()
    })
    .min(16, 'Card Number must be at least 16 digits long')
    .required('Card Number is required'),
  creditCardExpiry: Yup.string()
    .test(
      'validExpiry',
      'Expiry Date is invalid or the Credit Card has expired',
      value =>
        typeof value !== 'undefined' &&
        parse(`01-${value.substr(0, 2)}-${value.substr(-2, 2)}`, 'dd-MM-yy', new Date()) > new Date(),
    )
    .required('Card Expiry is required'),
  creditCardCCV: Yup.number().required('CCV is required'),
})

export default creditCardSchema
