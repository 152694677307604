/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { Avatar, Grid, Divider, Typography, Container, Box, Paper, Link, Fab, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { format, differenceInMinutes } from 'date-fns'
import { useLoadScript, GoogleMap } from '@react-google-maps/api'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import BookingCart from '../BookingCart/BookingCart'
import BookingReceipt from '../BookingReceipt/BookingReceipt'
import { formatAddress, formatCurrency, formatInitials } from '../../services/formatters/formatters'
import Header from '../Header/Header'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'
import SessionAttendees from '../SessionAttendees/SessionAttendees'
import useAuth from '../../services/AuthProvider/useAuth'
import useDatabase from '../../services/DatabaseProvider/useDatabase'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import { getCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import AvatarMarker from '../AvatarMarker/AvatarMarker'
import * as ROUTES from '../../constants/routes'
import Loading from '../Loading/Loading'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  heading: {
    fontFamily: robotoFontStack,
    fontSize: '2rem',
    fontWeight: 600,
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  iconText: {
    verticalAlign: 'top',
    fontWeight: 400,
    whiteSpace: 'pre',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    textAlign: 'center',
  },
  addressPaper: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    zIndex: 10,
    transform: 'translateY(-35%)',
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    border: `4px solid ${theme.palette.common.white}`,
  },
  trainerLink: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    transform: `translateY(-${theme.spacing(5)}px)`,
    zIndex: 2,
  },
  infoWrapper: {
    position: 'relative',
  },
  trainerName: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontFamily: robotoFontStack,
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  number: {
    fontSize: '3rem',
    lineHeight: `${theme.spacing(6)}px`,
    fontFamily: robotoFontStack,
    fontWeight: 600,
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))
const gmapLibraries = ['places']
const SessionPage = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [session, setSession] = useState(false)
  const [trainer, setTrainer] = useState(false)
  const [booking, setBooking] = useState(false)
  const [bookingsList, setBookingsList] = useState([])
  const auth = useAuth()
  const db = useDatabase()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: gmapLibraries,
  })

  useEffect(() => {
    // fetch and set session data
    db.getSession(id).then(result => {
      setSession(result)
      db.getTrainer(result.trainerId).then(data => {
        setTrainer(data)
      })

      if (auth.userData && auth.userData.accountType === 'user') {
        db.getUserSessionBooking(auth.user.uid, id).then(bookingData => {
          setBooking(bookingData)
        })
      }
    })
    if (auth.userData && auth.userData.accountType === 'trainer') {
      db.getSessionBookings(id).then(bookings => {
        const userIds = bookings.map(item => item.userId)

        if (userIds.length > 0) {
          db.getUsersByIds(userIds).then(users => {
            setBookingsList(
              bookings.map(data => {
                return {
                  ...data,
                  user: users.filter(single => single.userId === data.userId)[0],
                }
              }),
            )
          })
        }
      })
    }
  }, [id, auth.userData, auth.user.uid, db])

  return (
    <>
      <Header title="Session" showBack />
      <ResponsiveImage src={session.headerImage || ''} height={200} />
      <Container maxWidth="md">
        <Box py={3} className={classes.infoWrapper}>
          {trainer ? (
            <>
              {auth.userData && auth.userData.accountType === 'user' && (
                <Link
                  component={RouterLink}
                  to={`${ROUTES.TRAINER}/${trainer.id}`}
                  className={classes.trainerLink}
                  underline="none"
                >
                  <Avatar src={trainer.profileImg} className={classes.avatar}>
                    {formatInitials(trainer)}
                  </Avatar>
                  <Typography variant="h4" color="textPrimary" className={classes.trainerName}>
                    {trainer.firstname}
                  </Typography>
                </Link>
              )}
              <Typography variant="h1" color="primary" className={classes.heading} gutterBottom>
                {session.title}
              </Typography>
              <MaterialIcon icon="map-marker" color="secondary" size={3} className={classes.icon} />
              <Typography
                variant="subtitle1"
                color="textPrimary"
                display="inline"
                className={classes.iconText}
                gutterBottom
              >
                {trainer.suburb}, {trainer.stateShort}
              </Typography>
            </>
          ) : (
            <Loading height={252} />
          )}
          <br />
          {session && (
            <>
              <MaterialIcon icon="calendar" color="secondary" size={3} className={classes.icon} />
              <Typography variant="subtitle1" color="textPrimary" display="inline" className={classes.iconText}>
                {format(session.start.toDate(), 'EEEE, d LLLL yyyy')}
              </Typography>
              <br />
              <MaterialIcon icon="clock" color="secondary" size={3} className={classes.icon} />
              <Typography variant="subtitle1" color="textPrimary" display="inline" className={classes.iconText}>
                {format(session.start.toDate(), 'hh:mm a')} - {format(session.finish.toDate(), 'hh:mm a')}
              </Typography>
              {session.accessible && (
                <>
                  <br />
                  <MaterialIcon icon="wheelchair" color="secondary" size={3} className={classes.icon} />
                  <Typography variant="subtitle1" color="textPrimary" display="inline" className={classes.iconText}>
                    Session is accessible
                  </Typography>
                </>
              )}
            </>
          )}
          {session.status === 'cancelled' && (
            <Alert severity="warning" className={classes.alert}>
              This session has been cancelled.
            </Alert>
          )}
          <Divider className={classes.divider} />
          <Typography variant="h3" color="primary" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1" gutterBottom paragraph>
            {session.description}
          </Typography>
        </Box>
      </Container>
      <Box py={3} bgcolor="background.paper">
        {session ? (
          <Container maxWidth="md">
            <Grid container spacing={1}>
              <Grid item xs={3} className={classes.gridItem}>
                <MaterialIcon icon={getExerciseType(session.exerciseType, 'icon')} color="secondary" size={6} />
                <Typography variant="body2" color="primary">
                  {getExerciseType(session.exerciseType, 'label')}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem}>
                <MaterialIcon icon="timer" color="secondary" size={6} />
                <Typography variant="body2" color="primary">
                  {`${differenceInMinutes(session.finish.toDate(), session.start.toDate())} mins`}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem}>
                <Typography variant="h3" color="secondary" className={classes.number}>
                  {session.spaces - session.bookings}
                </Typography>
                <Typography variant="body2" color="primary">
                  spaces left
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem}>
                <Typography variant="h3" color="secondary" className={classes.number}>
                  {formatCurrency(session.cost, trainer.country)}
                </Typography>
                <Typography variant="body2" color="primary">
                  {trainer.country && getCurrency(trainer.country)}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Loading bgColor="background.paper" height={76} />
        )}
      </Box>
      {trainer ? (
        <>
          {isLoaded ? (
            <GoogleMap
              id="session-location"
              mapContainerStyle={{
                width: '100%',
                height: 350,
              }}
              clickableIcons={false}
              zoom={15}
              options={{
                disableDefaultUI: true,
                styles: [
                  { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.school', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
                  { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
                ],
                center: {
                  lat: trainer.latitude,
                  lng: trainer.longitude,
                },
              }}
            >
              <AvatarMarker position={{ latitude: trainer.latitude, longitude: trainer.longitude }} color="primary" />
            </GoogleMap>
          ) : (
            <Loading bgColor="background.paper" height={350} />
          )}
          <Container maxWidth="md">
            <Paper className={classes.addressPaper}>
              <Grid container alignItems="center">
                <Grid item xs={2} sm={1} md={1}>
                  <MaterialIcon icon="map-marker" color="secondary" size={6} />
                </Grid>
                <Grid item xs sm md>
                  <Typography variant="body1" className={classes.iconText} component="span">
                    {formatAddress(
                      {
                        address1: trainer.address1,
                        address2: trainer.address2,
                        suburb: trainer.suburb,
                        state: trainer.stateShort,
                        postcode: trainer.postcode,
                      },
                      false,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={1} md={1}>
                  <Box py={1} textAlign="center">
                    <IconButton
                      href={`https://www.google.com/maps/dir/?api=1&destination=${trainer.address1}+${trainer.address2}+${trainer.suburb}+${trainer.stateShort}+${trainer.postcode}`}
                      target="_blank"
                    >
                      <MaterialIcon icon="directions" color="primary" size={4} />
                    </IconButton>
                    <Typography variant="caption" display="block" align="center">
                      Directions
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            {auth.userData &&
              auth.userData.accountType === 'user' &&
              (booking ? (
                <BookingReceipt status={session.status} start={session.start} booking={booking} trainer={trainer} />
              ) : session.spaces > session.bookings && session.status !== 'cancelled' ? (
                <BookingCart
                  sessionId={id}
                  spacesRemaining={session.spaces - session.bookings}
                  start={session.start.toDate()}
                  finish={session.finish.toDate()}
                  suburb={trainer.suburb}
                  state={trainer.stateShort}
                  country={trainer.country}
                  cost={session.cost}
                />
              ) : (
                <Alert severity="warning" className={classes.alert}>
                  {session.status === 'active'
                    ? 'This session has been booked out.'
                    : 'This session has been cancelled.'}
                </Alert>
              ))}
            {auth.userData && auth.userData.accountType === 'trainer' && session.trainerId === auth.user.uid && (
              <>
                <SessionAttendees attendees={bookingsList} />
              </>
            )}
          </Container>
          {auth.userData && auth.userData.accountType === 'trainer' && session.trainerId === auth.user.uid && (
            <>
              {new Date() < session.finish.toDate() && (
                <Fab
                  color="secondary"
                  aria-label="Edit Session"
                  href={`${ROUTES.EDIT_SESSION}/${id}`}
                  className={classes.fab}
                >
                  <MaterialIcon icon="pencil" color="inherit" size={3} />
                </Fab>
              )}
            </>
          )}
        </>
      ) : (
        <Loading height={500} />
      )}
    </>
  )
}

export default SessionPage
