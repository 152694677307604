import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  Avatar,
  Link,
  Chip,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import MaterialIcon from '../MaterialIcon/MaterialIcon'
import { formatDate, formatInitials } from '../../services/formatters/formatters'
import getExerciseType from '../../services/getExerciseType/getExerciseType'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'
import { getCurrency, formatCurrency } from '../../services/ultimateCountryData/ultimateCountryData'
import { robotoFontStack } from '../../services/findFitTheme/findFitTheme'

const useStyles = makeStyles(theme => ({
  name: {
    verticalAlign: 'top',
    display: 'inline',
    lineHeight: `${theme.spacing(3)}px`,
  },
  subTitle: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'top',
    lineHeight: `${theme.spacing(3)}px`,
  },
  categoryIcon: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
  },
  address: {
    whiteSpace: 'pre',
  },
  media: {
    height: 140,
  },
  avatar: {
    position: 'absolute',
    top: '100%',
    right: theme.spacing(1.5),
    transform: 'translateY(-50%)',
    zIndex: 2,
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `4px solid ${theme.palette.background.paper}`,
  },
  gridItem: {
    textAlign: 'center',
  },
  number: {
    fontSize: '1.5rem',
    fontFamily: robotoFontStack,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
  },
  numberLabel: {
    fontSize: '0.8rem',
    lineHeight: 1.2,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  chipSpecial: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  chipIcon: {
    color: theme.palette.primary.main,
  },
}))
const UserClassTile = props => {
  const classes = useStyles()
  const {
    sessionId,
    title,
    start,
    spaces,
    exerciseType,
    cost,
    trainer,
    className,
    headerImage,
    bookings,
    accessible,
    showChips,
  } = props
  const auth = useAuth()
  const formattedDate = start ? formatDate(start.toDate()) : ''
  const icon = getExerciseType(exerciseType, 'icon')

  return (
    <Link component={RouterLink} to={`${ROUTES.SESSION}/${sessionId}`} underline="none">
      <Card className={className}>
        <CardActionArea>
          <div style={{ position: 'relative' }}>
            <CardMedia title={exerciseType} image={headerImage} className={classes.media} />
            {auth.userData && auth.userData.accountType === 'user' && (
              <Avatar alt={formatInitials(trainer)} className={classes.avatar} src={trainer.profileImg}>
                {formatInitials(trainer)}
              </Avatar>
            )}
          </div>
          <CardContent>
            <MaterialIcon icon={icon} color="secondary" size={3} className={classes.categoryIcon} />
            <Typography variant="h6" color="primary" className={classes.name}>
              {title}
            </Typography>
            <Typography variant="body2" className={classes.address} noWrap>
              {trainer.suburb}, {trainer.stateShort}
            </Typography>
            <Typography variant="body2" color="secondary" gutterBottom>
              {formattedDate}
            </Typography>
            {showChips && (
              <>
                <Divider className={classes.divider} />
                <Chip
                  icon={<MaterialIcon icon="clipboard-list" size={2.5} />}
                  label={`${spaces - bookings}/${spaces} spaces remaining`}
                  classes={{ root: classes.chip, icon: classes.chipIcon }}
                />
                <Chip
                  icon={<MaterialIcon icon="cash" size={2.5} />}
                  label={`${formatCurrency(cost, trainer.country)} ${getCurrency(trainer.country)}`}
                  classes={{ root: classes.chip, icon: classes.chipIcon }}
                />
              </>
            )}
            {accessible && (
              <Chip
                icon={<MaterialIcon icon="wheelchair" size={2.5} />}
                label="Accessible"
                classes={{ root: classes.chipSpecial, icon: classes.chipIcon }}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

UserClassTile.propTypes = {
  sessionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  start: PropTypes.any.isRequired,
  spaces: PropTypes.number.isRequired,
  exerciseType: PropTypes.string.isRequired,
  headerImage: PropTypes.string.isRequired,
  cost: PropTypes.number.isRequired,
  trainer: PropTypes.object.isRequired,
  className: PropTypes.any,
  bookings: PropTypes.number.isRequired,
  showChips: PropTypes.bool,
  accessible: PropTypes.bool,
}
UserClassTile.defaultProps = {
  className: '',
  showChips: false,
  accessible: false,
}

export default UserClassTile
