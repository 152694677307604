const geocodeAddress = address => {
  const geocoder = new window.google.maps.Geocoder()

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (responses, status, errorMessage) => {
      if (status !== 'OK') {
        reject(errorMessage)
      }

      resolve({
        latitude: responses[0].geometry.location.lat(),
        longitude: responses[0].geometry.location.lng(),
      })
    })
  })
}

export default geocodeAddress
