import React, { useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Container,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useLoadScript } from '@react-google-maps/api'
import AccountForm from './components/AccountForm/AccountForm'
import PersonalForm from './components/PersonalForm/PersonalForm'
import PaymentForm from './components/PaymentForm/PaymentForm'
import * as ROUTES from '../../constants/routes'
import useAuth from '../../services/AuthProvider/useAuth'
import { PASSWORD_VALIDATION_MESSAGE } from '../../constants/settings'
import geocodeAddress from '../../services/geocodeAddress/geocodeAddress'
import creditCardSchema from '../../services/creditCardValidation/creditCardValidation'

const useStyles = makeStyles({
  stepper: {
    width: '100%',
  },
  verifyContainer: {
    margin: 0,
  },
  verifyField: {
    textAlign: 'center',
  },
})
const SignUp = () => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const initialValues = {
    accountType: 'user',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    stateShort: '',
    postcode: '',
    country: '',
    latitude: 0,
    longitude: 0,
    showCCForm: false,
    creditCardNumber: '',
    creditCardName: '',
    creditCardExpiry: '',
    creditCardCCV: '',
    issuer: '',
  }
  const signupSteps = [
    { label: 'Account', description: 'To get started we just need a few details.' },
    { label: 'Personal Info', description: 'Fill in this information to personalise your experience.' },
    { label: 'Payment', description: 'Please fill in your payment information. This will save you time later.' },
  ]
  const validationSchemas = [
    Yup.object().shape({
      firstname: Yup.string()
        .matches(/^[a-zA-Z\s'-]+$/, 'First Name is invalid')
        .required('First Name is required'),
      email: Yup.string().email('Value must be a valid Email address').required('Email Address is required'),
      password: Yup.string()
        .min(8, PASSWORD_VALIDATION_MESSAGE)
        .matches(/[a-z]/, PASSWORD_VALIDATION_MESSAGE)
        .matches(/[A-Z]/, PASSWORD_VALIDATION_MESSAGE)
        .matches(/[0-9]/, PASSWORD_VALIDATION_MESSAGE)
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match')
        .required('Password Confirmation is required'),
    }),
    Yup.object().shape({
      firstname: Yup.string()
        .matches(/^[a-zA-Z\s'-]+$/, 'First Name is invalid')
        .required('First Name is required'),
      lastname: Yup.string()
        .matches(/^[a-zA-Z\s'-]+$/, 'Last Name is invalid')
        .required('Last Name is required'),
      phone: Yup.string().required('Mobile Phone is required'),
      address1: Yup.string().required('Address 1 is required'),
      suburb: Yup.string().required('Suburb is required'),
      state: Yup.string().required('State is required'),
      postcode: Yup.string().required('Postcode is required'),
      country: Yup.string().required('Country is required'),
    }),
    creditCardSchema,
  ]
  const history = useHistory()
  const auth = useAuth()
  const mapsScript = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  document.title = `Sign Up - ${process.env.REACT_APP_APP_NAME}`

  return (
    <>
      <Stepper activeStep={step} alternativeLabel className={classes.stepper}>
        {signupSteps.map(stage => (
          <Step key={stage.label}>
            <StepLabel>{stage.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemas[step]}
        onSubmit={async (data, formikBag) => {
          let coords = {}

          switch (step) {
            default:
            case 0:
              auth
                .signUp(data)
                .then(() => {
                  if (data.accountType === 'trainer') {
                    formikBag.setFieldValue('showCCForm', true)
                  }

                  setStep(step + 1)
                })
                .catch(error => {
                  formikBag.setFieldError('email', error.message)
                })
              break
            case 1:
              if (mapsScript.isLoaded) {
                coords = await geocodeAddress(
                  `${data.address1}+${data.address2}+${data.suburb}+${data.stateShort}+${data.country}`,
                )
              }

              auth.updateUser({ ...data, ...coords }).then(() => {
                setStep(step + 1)
              })
              break
            case 2:
              auth.addCreditCard(data).then(() => {
                history.push(ROUTES.HOME)
              })
              break
          }
        }}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched, handleChange, handleBlur }) => (
          <>
            <Box bgcolor="primary.dark" p={3}>
              <Container maxWidth="sm">
                <Typography variant="h3" color="secondary" gutterBottom>
                  {signupSteps[step].label}
                </Typography>
                <Typography variant="body2" color="textPrimary" gutterBottom>
                  {signupSteps[step].description}
                </Typography>
              </Container>
            </Box>
            <Container maxWidth="sm">
              <Box px={1}>
                <Form>
                  {step === 0 && <AccountForm values={values} errors={errors} touched={touched} />}
                  {step === 1 && (
                    <PersonalForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  )}
                  {step === 2 && (
                    <>
                      <FormControl fullWidth margin="normal">
                        {values.accountType === 'user' && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="showCCForm"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.showCCForm}
                              />
                            }
                            label="Add a Credit Card"
                          />
                        )}
                      </FormControl>
                      {values.showCCForm && (
                        <PaymentForm
                          values={values}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      )}
                    </>
                  )}
                </Form>
              </Box>
            </Container>
          </>
        )}
      </Formik>
    </>
  )
}

export default SignUp
