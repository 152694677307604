import regionData from './data.json'

const getCurrency = country => {
  if (!country) {
    return ''
  }

  const selectedCountry = regionData.filter(item => item.countryName === country)[0]

  return selectedCountry.currency
}

const formatCurrency = (value, country = 'Australia', showCurrency = false) => {
  const selectedCountry = regionData.filter(item => item.countryName === country)[0]
  let formattedCurrency = Intl.NumberFormat(`${selectedCountry.language}-${selectedCountry.countryShortCode}`, {
    style: 'currency',
    currency: selectedCountry.currency,
  }).format(value)

  if (formattedCurrency.substr(-3, 3) === '.00') {
    formattedCurrency = formattedCurrency.substr(0, formattedCurrency.length - 3)
  }

  if (showCurrency) {
    formattedCurrency = `${formattedCurrency} ${getCurrency(country)}`
  }

  return formattedCurrency
}

export { formatCurrency, getCurrency }
